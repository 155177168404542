export const labels = [{
  id: {
    attribute: 'id',
    label: 'ID',
    type: 'number',
    editableInDesk: false,
  },
  identification: {
    attribute: 'identification',
    label: 'Referência',
    alt: 'Ref',
    type: 'string',
    editableInDesk: false,
  },
  alerts: {
    attribute: 'hasRadarAlert',
    label: 'Alertas',
    type: 'ico',
    iconClass: 'icon ion-flag tx-warning',
    editableInDesk: false,
  },
  importLicense: {
    attribute: 'importLicense',
    label: 'Licença de Importação',
    type: 'yes_or_no',
    editableInDesk: true,
  },
  duimp: {
    attribute: 'duimp',
    label: 'DUIMP',
    ault: 'DUIMP',
    type: 'yes_or_no',
    editableInDesk: true,
  },
  reminder: {
    attribute: 'reminder',
    label: 'Lembrete',
    type: 'boolean',
    editableInDesk: true,
  },
  note: {
    attribute: 'note',
    label: 'Observação',
    type: 'text',
    editableInDesk: true,
  },
  urgent: {
    attribute: 'urgent',
    label: 'Urgente',
    type: 'yes_or_no',
    editableInDesk: false,
  },
  step: {
    attribute: 'step',
    label: 'Etapa',
    type: 'string',
    editableInDesk: false,
  },
  status: {
    attribute: 'status',
    label: 'Status',
    alt: 'Status',
    type: 'string',
    editableInDesk: true,
  },
  statusDateOfLastModify: {
    attribute: 'statusDateOfLastModify',
    label: 'Última Alteração de Status',
    alt: 'Data Status',
    type: 'string',
    editableInDesk: true,
  },
  // "customerRef": {
  //     "attribute": "customerRef",
  //     "label": "Ref/Pedido Cliente",
  //     "alt": "Ref/Pedido Cliente",
  //     "type": "string",
  //     "editableInDesk": true,
  // },
  customer: {
    attribute: 'customer',
    label: 'Cliente',
    alt: 'Cliente',
    type: 'entity',
    editableInDesk: false,
  },
  exporter: {
    attribute: 'exporter',
    label: 'Exportador',
    alt: 'Exportador',
    type: 'entity',
    editableInDesk: false,
  },
  manufacturer: {
    attribute: 'manufacturer',
    label: 'Fabricante',
    type: 'entity',
    editableInDesk: false,
  },
  customBroker: {
    attribute: 'customBroker',
    label: 'Despachante Aduaneiro',
    alt: 'Despachante',
    type: 'entity',
    editableInDesk: false,
  },
  account: {
    attribute: 'account',
    label: 'Filial (UF)',
    alt: 'UF',
    type: 'entity',
    editableInDesk: false,
  },
  acquirer: {
    attribute: 'acquirer',
    label: 'Adquirente',
    type: 'entity',
    editableInDesk: false,
  },
  importer: {
    attribute: 'importer',
    label: 'Importador',
    type: 'entity',
    editableInDesk: false,
  },
  seller: {
    attribute: 'seller',
    label: 'Vendedor / Seller ',
    type: 'entity',
    editableInDesk: false,
  },
  customerRef: {
    attribute: 'customerRef',
    label: 'Ref Cliente / Pedido',
    alt: 'Pedido',
    type: 'string',
    editableInDesk: true,
  },
  anotherRef: {
    attribute: 'anotherRef',
    label: 'Outra Referência',
    alt: 'Outra Ref',
    type: 'string',
    editableInDesk: true,
  },
  invoice: {
    attribute: 'invoice',
    label: 'Invoice/Fatura',
    alt: 'Invoice',
    type: 'string',
    editableInDesk: true,
  },
  created: {
    attribute: 'created',
    label: 'Abertura',
    type: 'date',
    editableInDesk: true,
  },
  legacyCreated: {
    attribute: 'legacyCreated',
    label: 'Abertura (Antigo)',
    type: 'date',
    editableInDesk: false,
  },
  invoiceDate: {
    attribute: 'invoiceDate',
    alt: 'Data Inv',
    type: 'date',
    editableInDesk: true,
  },
  customBrokerRef: {
    attribute: 'customBrokerRef',
    label: 'Ref Despachante',
    type: 'string',
    editableInDesk: true,
  },
  importerRef: {
    attribute: 'importerRef',
    label: 'Ref Importador',
    type: 'string',
    editableInDesk: true,
  },
  cifValue: {
    attribute: 'cifValue',
    label: 'Valor CIF',
    type: 'decimal',
    editableInDesk: false,
  },
  cifValueCurrency: {
    attribute: 'cifValueCurrency',
    label: 'Moeda Valor CIF',
    type: 'decimal',
    editableInDesk: false,
  },
  applicationOfGoods: {
    attribute: 'applicationOfGoods',
    label: 'Aplicação da Mercadoria',
    type: 'option',
    editableInDesk: false,
  },
  fiscalBenefit: {
    attribute: 'fiscalBenefit',
    label: 'Benefício Fiscal',
    type: '',
    editableInDesk: false,
  },
  fiscalBenefitDescription: {
    attribute: 'fiscalBenefitDescription',
    label: 'Descrição do Benefício Fiscal',
    type: '',
    editableInDesk: false,
  },
  icmsDescription: {
    attribute: 'icmsDescription',
    label: 'Descrição do ICMS',
    type: '',
    editableInDesk: false,
  },
  incoterm: {
    attribute: 'incoterm',
    label: 'Incoterm',
    type: 'string',
    editableInDesk: true,
  },
  wayOfTransport: {
    attribute: 'wayOfTransport',
    label: 'Via de Transporte',
    type: '',
    editableInDesk: false,
  },
  typeOfProcess: {
    attribute: 'typeOfProcess',
    label: 'Processo',
    type: 'string',
    editableInDesk: false,
  },
  typeOfBoarding: {
    attribute: 'typeOfBoarding',
    label: 'Tipo de Embarque',
    alt: 'CTNR',
    type: 'string',
    editableInDesk: false,
  },
  billOfLading: {
    attribute: 'billOfLading',
    label: 'BL/AWB/CRT',
    type: 'string',
    editableInDesk: true,
  },
  vehicle: {
    attribute: 'vehicle',
    label: 'Veículo / Navio',
    type: 'string',
    editableInDesk: true,
  },
  placeOfLoading: {
    attribute: 'placeOfLoading',
    label: 'Local de Embarque',
    type: 'string',
    editableInDesk: true,
  },
  freightForwarder: {
    attribute: 'freightForwarder',
    label: 'Forwarder',
    type: '',
    editableInDesk: false,
  },
  customsEnclosureURFEntrance: {
    attribute: 'customsEnclosureURFEntrance',
    label: 'URF de Entrada',
    alt: 'URF',
    type: 'entity',
    editableInDesk: false,
  },
  customsEnclosure: {
    attribute: 'customsEnclosure',
    label: 'Recinto Alfandegado ',
    alt: 'Recinto',
    type: 'entity',
    editableInDesk: false,
  },
  conveyor: {
    attribute: 'conveyor',
    label: 'Transportadora',
    alt: 'Transportadora',
    type: 'entity',
    editableInDesk: false,
  },
  generalWarehouse: {
    attribute: 'generalWarehouse',
    label: 'Armazém Geral ',
    alt: 'Armazém',
    type: 'entity',
    editableInDesk: false,
  },
  ceMercante: {
    attribute: 'ceMercante',
    label: 'Nº CE-Mercante',
    alt: 'Nº CE',
    type: 'string',
    editableInDesk: true,
  },
  afrmmValue: {
    attribute: 'afrmmValue',
    label: 'Valor da AFRMM',
    alt: 'R$ AFRMM',
    type: '',
    editableInDesk: false,
  },
  afrmmStatus: {
    attribute: 'afrmmStatus',
    label: 'Situação do AFRMM',
    alt: 'Sit AFRMM',
    type: '',
    editableInDesk: false,
  },
  stepSpreadsheet: {
    attribute: 'stepSpreadsheet',
    label: 'Planilha',
    alt: 'Planilha',
    type: 'date',
    editableInDesk: true,
  },
  stepInsurance: {
    attribute: 'stepInsurance',
    label: 'Seguro',
    alt: 'Seguro',
    type: 'date',
    editableInDesk: true,
  },
  stepInsuranceSolved: {
    attribute: 'stepInsuranceSolved',
    label: 'Seguro Checkbox',
    type: 'checkbox_solved',
    editableInDesk: false,
  },
  stepInsuranceValue: {
    attribute: 'stepInsuranceValue',
    label: 'Valor do Seguro',
    type: 'decimal',
    editableInDesk: false,
  },
  stepInsuranceValueResponsability: {
    attribute: 'stepInsuranceValueResponsability',
    label: ' Responsabilidade do Seguro ',
    type: 'string',
    editableInDesk: false,
  },
  stepCashRequest: {
    attribute: 'stepCashRequest',
    label: 'Numerário',
    alt: 'Numerário',
    type: 'date',
    editableInDesk: true,
  },
  stepCashRequestSolved: {
    attribute: 'stepCashRequestSolved',
    label: 'Numerário Checkbox',
    type: 'checkbox_solved',
    editableInDesk: false,
  },
  originalsDocumentsDate: {
    attribute: 'originalsDocumentsDate',
    label: 'Originais',
    alt: 'Originais',
    type: 'date',
    editableInDesk: true,
  },
  originalsDocumentsDateSolved: {
    attribute: 'originalsDocumentsDateSolved',
    label: 'Originais Checkbox',
    type: 'checkbox_solved',
    editableInDesk: false,
  },
  originalsBillOfLadingDate: {
    attribute: 'originalsBillOfLadingDate',
    label: 'Conhecimento',
    type: 'date',
    editableInDesk: true,
  },
  originalsBillOfLadingDateSolved: {
    attribute: 'originalsBillOfLadingDateSolved',
    label: 'Conhecimento  Checkbox',
    type: 'checkbox_solved',
    editableInDesk: false,
  },
  originalsInvoiceDate: {
    attribute: 'originalsInvoiceDate',
    label: 'Orig Fatura/Invoice ',
    type: 'date',
    editableInDesk: true,
  },
  originalsInvoiceDateSolved: {
    attribute: 'originalsInvoiceDateSolved',
    label: 'Fatura / Invoice  Checkbox',
    type: 'checkbox_solved',
    editableInDesk: false,
  },
  originalsPackingListDate: {
    attribute: 'originalsPackingListDate',
    label: 'Packing List ',
    type: 'date',
    editableInDesk: true,
  },
  originalsPackingListDateSolved: {
    attribute: 'originalsPackingListDateSolved',
    label: 'Packing List  Checkbox',
    type: 'checkbox_solved',
    editableInDesk: false,
  },
  conferenceDocumentsSolved: {
    attribute: 'conferenceDocumentsSolved',
    label: 'Conf Originais',
    type: 'checkbox_solved',
    editableInDesk: false,
  },
  conferencePackingListSolved: {
    attribute: 'conferencePackingListSolved',
    label: 'Conf Packing List',
    type: 'checkbox_solved',
    editableInDesk: false,
  },
  conferenceBillOfLadingSolved: {
    attribute: 'conferenceBillOfLadingSolved',
    label: 'Conf Conhecimento',
    type: 'checkbox_solved',
    editableInDesk: false,
  },
  conferenceInvoiceSolved: {
    attribute: 'conferenceInvoiceSolved',
    label: 'Conf Fatura / Invoice',
    type: 'checkbox_solved',
    editableInDesk: false,
  },
  diNumber: {
    attribute: 'diNumber',
    label: 'Número da DI',
    alt: 'Número DI',
    type: 'string',
    editableInDesk: true,
  },
  diRegistryDate: {
    attribute: 'diRegistryDate',
    label: 'Registro DI',
    alt: 'Registro',
    type: 'date',
    editableInDesk: true,
  },
  diTypingDate: {
    attribute: 'diTypingDate',
    label: 'Digitação DI',
    alt: 'Digitação',
    type: 'date',
    editableInDesk: true,
  },
  diResourcefulnessDate: {
    attribute: 'diResourcefulnessDate',
    label: 'Desembaraço',
    alt: 'Desembaraço',
    type: 'date',
    editableInDesk: true,
  },
  diChannel: {
    attribute: 'diChannel',
    label: 'Canal',
    alt: 'Canal',
    type: 'option',
    editableInDesk: false,
  },

  revenuesReleaseBLAWBState: {
    attribute: 'revenuesReleaseBLAWBState',
    label: 'Situação Lib HBL/AWB',
    alt: 'Situação Lib HBL/AWB',
    type: 'string',
    editableInDesk: false,
  },

  revenuesStorageState: {
    attribute: 'revenuesStorageState',
    label: 'Armazenagem',
    alt: 'Armazenagem',
    type: 'string',
    editableInDesk: false,
  },

  revenuesTransportState: {
    attribute: 'revenuesTransportState',
    label: 'Transporte',
    alt: 'Transporte',
    type: 'string',
    editableInDesk: false,
  },
  revenuesOtherExpenses: {
    attribute: 'revenuesOtherExpenses',
    label: 'Outras Despesas',
    type: 'string',
    editableInDesk: false,
  },

  billingNFsDate: {
    attribute: 'billingNFsDate',
    label: 'Notas Fiscais',
    alt: 'NFS',
    type: 'date',
    editableInDesk: true,
  },

  billingNFsDateSolved: {
    attribute: 'billingNFsDateSolved',
    label: 'Notas Fiscais Checkbox',
    type: 'checkbox_solved',
    editableInDesk: false,
  },

  expectedDeliveryTime: {
    attribute: 'expectedDeliveryTime',
    label: 'Prev Prazo Entrega',
    type: 'date',
    editableInDesk: false,
  },

  billingTerm: {
    attribute: 'billingTerm',
    label: 'Prazo Encerramento',
    type: 'string',
    editableInDesk: false,
  },

  billingNFsEmissionDate: {
    attribute: 'billingNFsEmissionDate',
    label: 'NF Emissão',
    type: 'date',
    editableInDesk: true,
  },

  billingNFsEmissionDateSolved: {
    attribute: 'billingNFsEmissionDateSolved',
    label: 'NF Emissão Checkbox',
    type: 'checkbox_solved',
    editableInDesk: false,
  },

  billingBillCustomerDate: {
    attribute: 'billingBillCustomerDate',
    label: 'Faturamento Cliente ',
    type: 'date',
    editableInDesk: true,
  },
  billingBillCustomerDateSolved: {
    attribute: 'billingBillCustomerDateSolved',
    label: 'Faturamento Cliente  Checkbox',
    type: 'checkbox_solved',
    editableInDesk: false,
  },
  billingEmailDate: {
    attribute: 'billingEmailDate',
    label: 'Email',
    type: 'date',
    editableInDesk: true,
  },
  billingEmailDateSolved: {
    attribute: 'billingEmailDateSolved',
    label: 'Email Checkbox',
    type: 'checkbox_solved',
    editableInDesk: false,
  },
  billingSendDate: {
    attribute: 'billingSendDate',
    label: 'Envio',
    type: 'date',
    editableInDesk: true,
  },
  billingCustomBrokerFinishDate: {
    attribute: 'billingCustomBrokerFinishDate',
    label: 'Enc Despachante',
    alt: 'Enc Despachante',
    type: 'date',
    editableInDesk: true,
  },
  billingSendCompanyDescription: {
    attribute: 'billingSendCompanyDescription',
    label: 'Empresa',
    type: 'string',
    editableInDesk: true,
  },
  billingSendTrackingDescription: {
    attribute: 'billingSendTrackingDescription',
    label: 'Tracking',
    type: 'string',
    editableInDesk: true,
  },
  billingDataConferenceDate: {
    attribute: 'billingDataConferenceDate',
    label: 'Conferência de Dados',
    type: 'date',
    editableInDesk: true,
  },
  datesEstimatedGoodsReadinesDate: {
    attribute: 'datesEstimatedGoodsReadinesDate',
    label: 'Prontidão(Prev)',
    alt: 'Pront(P)',
    type: 'date',
    editableInDesk: true,
  },
  datesCargoCollectDate: {
    attribute: 'datesCargoCollectDate',
	label: 'Coleta',
	type: 'date',
	editableInDesk: true,
  },
  datesEstimatedCargoCollectDate: {
    attribute: 'datesEstimatedCargoCollectDate',
	label: 'Coleta Prevista',
	type: 'date',
	editableInDesk: true,
  },
  datesETD: {
    attribute: 'datesETD',
    label: 'ETD',
    type: 'date',
    editableInDesk: true,
  },
  datesETA: {
    attribute: 'datesETA',
    label: 'ETA',
    type: 'date',
    editableInDesk: true,
  },
  datesEstimatedCustomerDeliverDate: {
    attribute: 'datesEstimatedCustomerDeliverDate',
    label: 'Entrega (P)',
    type: 'date',
    editableInDesk: true,
  },
  datesGoodsReadinesDate: {
    attribute: 'datesGoodsReadinesDate',
    label: 'Prontidão',
    alt: 'Prontidão',
    type: 'date',
    editableInDesk: true,
  },
  datesDepartureDate: {
    attribute: 'datesDepartureDate',
    label: 'Embarque',
    alt: 'Embarque',
    type: 'date',
    editableInDesk: true,
  },
  datesArrivalDate: {
    attribute: 'datesArrivalDate',
    label: 'Chegada',
    alt: 'Chegada',
    type: 'date',
    editableInDesk: true,
  },
  datesGoodsPresenceDate: {
    attribute: 'datesGoodsPresenceDate',
    label: 'Presença de Carga',
    type: 'date',
    editableInDesk: true,
  },
  datesInspectionOfTheDIDate: {
    attribute: 'datesInspectionOfTheDIDate',
    label: 'Vistoria da DI',
    type: 'date',
    editableInDesk: true,
  },
  datesGoodsLoadingDate: {
    attribute: 'datesGoodsLoadingDate',
    label: 'Carregamento',
    alt: 'Carreg',
    type: 'date',
    editableInDesk: true,
  },
  datesCustomerDeliverDate: {
    attribute: 'datesCustomerDeliverDate',
    label: 'Entrega',
    alt: 'Entrega',
    type: 'date',
    editableInDesk: true,
  },
  datesInspectionMAPADate: {
    attribute: 'datesInspectionMAPADate',
    label: 'Vistoria MAPA',
    type: 'date',
    editableInDesk: true,
  },
  datesInspectionMAPADateSolved: {
    attribute: 'datesInspectionMAPADateSolved',
    label: 'Vistoria MAPA Checkbox',
    type: 'checkbox_solved',
    editableInDesk: false,
  },
  datesReleaseMAPADate: {
    attribute: 'datesReleaseMAPADate',
    label: 'Liberação MAPA',
    alt: 'Lib.MAPA',
    type: 'date',
    editableInDesk: true,
  },
  datesReleaseMAPADateSolved: {
    attribute: 'datesReleaseMAPADateSolved',
    label: 'Liberação MAPA Checkbox',
    type: 'checkbox_solved',
    editableInDesk: false,
  },
  datesLinkInTheEnclosureDate: {
    attribute: 'datesLinkInTheEnclosureDate',
    label: 'Vínculo no Recinto',
    type: 'date',
    editableInDesk: false,
  },
  datesLinkInTheEnclosureDateSolved: {
    attribute: 'datesLinkInTheEnclosureDateSolved',
    label: 'Vínculo no Recinto Checkbox',
    type: 'checkbox_solved',
    editableInDesk: false,
  },
  datesBillOfLadingReleaseDate: {
    attribute: 'datesBillOfLadingReleaseDate',
    label: 'Liberação de AWB/BL',
    type: 'date',
    editableInDesk: true,
  },
  datesSiscargaState: {
    attribute: 'datesSiscargaState',
    label: 'Siscarga',
    alt: 'Siscarga',
    type: 'date',
    editableInDesk: true,
  },
  datesSiscargaDate: {
    attribute: 'datesSiscargaDate',
    label: 'Data do Siscarga',
    alt: 'Siscarga',
    type: 'date',
    editableInDesk: true,
  },
  datesIsThereDTCDTATransfer: {
    attribute: 'datesIsThereDTCDTATransfer',
    label: 'DTA/DTC?',
    alt: 'DTA/DTC?',
    type: 'yes_or_no',
    editableInDesk: true,
  },
  datesDTCDTA: {
    attribute: 'datesDTCDTA',
    label: 'Data DTA/DTC',
    alt: 'DTA/DTC',
    type: 'date',
    editableInDesk: true,
  },
  datesThereIsDesova: {
    attribute: 'datesThereIsDesova',
    label: 'Desova',
    type: 'date',
    editableInDesk: true,
  },
  datesEnclosureBillingDate: {
    attribute: 'datesEnclosureBillingDate',
    label: 'Faturamento Recinto',
    alt: 'Fat Rec',
    type: 'date',
    editableInDesk: true,
  },
  datesComexDate: {
    attribute: 'datesComexDate',
    label: 'Comex',
    type: 'date',
    editableInDesk: true,
  },
  freetime: {
    attribute: 'freetime',
    label: 'Deadline (Freetime)',
    alt: 'Deadline',
    type: 'string',
    editableInDesk: true,
  },
  'customer.isMinimalComissionPercentual': {
    attribute: 'isMinimalComissionPercentual',
    label: 'Cliente (Comissão Mínima)',
    alt: 'Com. Mínima',
    type: 'yes_or_no',
    editableInDesk: false,
  },
  hasInvoices: {
    attribute: 'hasInvoices',
    label: 'Cadastro Invoice',
    alt: 'Cadastro Invoice',
    type: 'boolean',
    editableInDesk: true,
  },
  documentsSentForExchange: {
    attribute: 'documentsSentForExchange',
    label: 'Docs Enviados p/ Câmbio',
    type: 'date',
    editableInDesk: true,
  },
  statusCustomBroker: {
    attribute: 'statusCustomBroker',
    label: 'Status Despachante',
    type: 'string',
    editableInDesk: false,
  },
  cashRequestDoneDate: {
    attribute: 'cashRequestDoneDate',
    label: 'Pagamento Numerário',
    type: 'date',
    editableInDesk: false,
  },
  cashRequestDone:{
	attribute: 'cashRequestDone',
    label: 'Status Numerário',
    type: 'string',
    editableInDesk: false,
  },
}];

export const dateLabels = () => {
  const dateLabels = [];

  for (let i = 0; i < Object.keys(labels[0]).length; i++) {
    if (Object.values(labels[0])[i].type === 'date') {
      dateLabels.push(Object.values(labels[0])[i]);
    }
  }

  return dateLabels;
};
