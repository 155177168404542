<template>
  <div class="form-layout form-layout-2">
    <div class="report-summary-header mg-b-5 mg-t-0">
      <div>
        <h4 class="tx-inverse mg-b-3 d-block">Logística</h4>
        <p class="mg-b-0">
          <i class="icon ion-information-circled mg-r-3"></i> Informações de Logísticas do Processo de Importação.
        </p>
      </div>
      <div />
    </div>

    <div class="card processes-container">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item">
            <a
              class="nav-link active"
              @click="tab = 'containers'"
              href="#containers"
              data-toggle="tab"
            >
              <i class="fa fa-box mg-r-2"></i> Containers
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              @click="tab = 'cotacao'"
              href="#cotacao"
              data-toggle="tab"
            >
              <i class="fa fa-globe mg-r-2" style="font-size: 16px"></i> Cotação de Frete Internacional
            </a>
          </li>
          <li
            class="nav-item"
            v-if="
              freightQuotationsResponses &&
              freightQuotationsResponses.freightQuotations &&
              freightQuotationsResponses.freightQuotations.length > 0
            "
          >
            <a
              class="nav-link"
              @click="tab = 'cotacao-recebida'"
              href="#cotacao-recebida"
              data-toggle="tab"
            >
              <i class="fa fa-money-check mg-r-2"></i> Cotações Recebidas
            </a>
          </li>
		  <li class="nav-item">
            <a
              class="nav-link"
              @click="tab = 'cotacao-nacional'"
              href="#cotacao-nacional"
              data-toggle="tab"
            >
              <i class="fa fa-truck-moving mg-r-2"></i> Cotação de Frete Nacional
            </a>
          </li>
        </ul>
      </div>

      <!-- card-header -->
      <div class="card-body">
        <div class="tab-content ht-100p">
          <div
            class="tab-pane"
            v-bind:class="{ active: tab === 'containers' }"
            id="containers"
          >
            <containers />
          </div>
          <div
            class="tab-pane"
            v-bind:class="{ active: tab === 'cotacao' }"
            id="cotacao"
          >
            <freight-quote :model="model" />
          </div>
          <div
            class="tab-pane"
            v-if="
              freightQuotationsResponses &&
              freightQuotationsResponses.freightQuotations &&
              freightQuotationsResponses.freightQuotations.length > 0
            "
            v-bind:class="{ active: tab === 'cotacao-recebida' }"
            id="cotacao-recebida"
          >
            <freight-quote-response
              :model="model"
              :freightQuotationsResponses="freightQuotationsResponses"
              @load-quotations="getQuotations"
            />
          </div>
		  <div
            class="tab-pane"
            v-bind:class="{ active: tab === 'cotacao-nacional' }"
            id="cotacao-nacional"
          >
            <freight-quote-national :model="model" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Containers from "./Containers";
import FreightQuote from "./FreightQuote";
import FreightQuoteResponse from "./FreightQuoteResponse";
import FreightQuoteNational from "./FreightQuoteNational";
import Axios from "axios";
import config from "@/config.js";
import downloadjs from "downloadjs";

export default {
  name: "FreightQuoteResponse",
  props: {
    model: {
      required: true,
    },
  },
  components: {
    containers: Containers,
    "freight-quote": FreightQuote,
    "freight-quote-response": FreightQuoteResponse,
	"freight-quote-national": FreightQuoteNational
  },
  data() {
    return {
      tab: "containers",
      openModal: false,
    };
  },
  watch: {
    model() {
      this.getFreightQuotationsResponses(this.model.id);
    },
  },
  methods: {
    ...mapActions("ProcessStore", ["getFreightQuotationsResponses"]),
    getQuotations() {
      this.getFreightQuotationsResponses(this.model.id);
    },
  },
  computed: {
    ...mapState("ProcessStore", ["freightQuotationsResponses"]),
  },
  async mounted() {
    this.getFreightQuotationsResponses(this.model.id);
  },
};
</script>
