<template>
  <div>
    <form class="contact-form" @submit.prevent="submitForm">
      <div class="sp-3 flex ai-c jc-sb">
        <h4>Informações Pessoais</h4>
		<p style="margin-bottom: 0">Os campos marcados com <span class="tx-danger">*</span> são obrigatórios.</p>
      </div>

      <div>
        <v-input
          name="nome"
          label="Nome Completo"
          v-model="contactDetails.name"
		  :class="{'input-danger': getError('name') }"
		  :requiredFlag="true"
        ></v-input>
        <span class="text-danger">
          {{
            fieldErrors.find((err) => err.path == "name") ? fieldErrors.find((err) => err.path == "name").message : ""
          }}
        </span>
      </div>

      <select-wrapper
        v-model="contactDetails.gender"
        :options="[
          {
            label: 'Masculino',
            value: 'm',
          },
          {
            label: 'Feminino',
            value: 'f',
          },
        ]"
        label="Gênero"
      ></select-wrapper>

      <v-input
        type="date"
        name="nascimento"
        label="Data de Nascimento"
        v-model="contactDetails.dateOfBirth"
      ></v-input>

      <div class="sp-3 contact-form">
        <div>
          <v-input
            name="email1"
            label="Email Principal"
            v-model="email1Lowercase"
            hint="Este email será utilizado para o usuário fazer login na plataforma de acordo com o perfil."
			:class="{'input-danger': getError('email1') }"
			:requiredFlag="true"
			@blur="canUseEmail"
          ></v-input>
          <span class="text-danger">
            {{
              fieldErrors.find((err) => err.path == "email1") ? fieldErrors.find((err) => err.path == "email1").message : ""
            }}
          </span>
        </div>

        <v-input
          name="email2"
          label="Email Secundário"
          v-model="email2Lowercase"
          hint="Este é um email secundário, para casos em que o contato tem mais do que um email."
        ></v-input>
      </div>

      <div class="sp-3 contact-form">
        <v-input
          name="fixo"
          label="Telefone Fixo"
          v-model="contactDetails.telephone1"
          :mask="['(##) ####-####', '(##) #####-####']"
        ></v-input>
        <v-input
          name="telephone2"
          label="Celular"
          v-model="contactDetails.telephone2"
          :mask="['(##) ####-####', '(##) #####-####']"
        >
          <template #checkbox>
            <label>
              <input
                type="checkbox"
                name="allowsContactViaExternalApplications"
                v-model="contactDetails.allowsContactViaExternalApplications"
              />
              <span
              >Contato permite ser chamado por meio de apps que usa o número
                dele?</span
              >
            </label>
          </template>
        </v-input>
      </div>

      <div class="sp-3" style="margin-top: 20px">
        <h4>Informações de Contato na Empresa</h4>
      </div>

      <div class="sp-3 contact-form">
        <div>
          <select-wrapper
            v-model="contactDetails.category"
            label="Categoria de Contato"
            :options="[
              { label: 'Principal', value: 'MAIN_CONTACT' },
              { label: 'Próximo', value: 'CONTACT_NEXT' },
              { label: 'Esporádico', value: 'SPORADIC_CONTACT' },
              { label: 'Seguro', value: 'INSURANCE_CONTACT' },
              { label: 'Contabilidade', value: 'ACCOUNTING_CONTACT' },
              { label: 'Inativo', value: 'INACTIVE_CONTACT' },
            ]"
			:class="{'input-danger': getError('category') }"
			:requiredFlag="true"
          ></select-wrapper>
          <span class="text-danger">
            {{
              fieldErrors.find((err) => err.path == "category") ? fieldErrors.find((err) => err.path == "category").message : ""
            }}
          </span>
        </div>

        <v-input
          name="hiringDate"
          label="Data de Contratação"
          v-model="contactDetails.hiringDate"
          type="date"
          hint="Data para saber quando é que o contato faz aniversário de empresa"
        ></v-input>

        <v-checkbox
          name="archived"
          label="Contato Arquivado"
          v-model="contactDetails.archived"
        ></v-checkbox>
      </div>

      <div class="sp-3 contact-form mt-1">
        <v-input
          name="jobRole"
          label="Cargo"
          v-model="contactDetails.jobRole"
        ></v-input>
        <select-wrapper
          v-model="contactDetails.sector"
          label="Setor / Departamento"
          :options="[
            { value: 'GERAL', label: 'Geral' },
            { value: 'ADMINISTRATIVO', label: 'Administrativo' },
            { value: 'EXPORTACAO', label: 'Exportação' },
            { value: 'IMPORTACAO', label: 'Importação' },
            { value: 'VENDAS', label: 'Vendas' },
            { value: 'COMPRAS', label: 'Compras' },
            { value: 'FATURAMENTO', label: 'Faturamento' },
            { value: 'FINANCEIRO', label: 'Financeiro' },
            { value: 'FISCAL', label: 'Fiscal' },
            { value: 'JURIDICO', label: 'Jurídico' },
            { value: 'LOGISTICA', label: 'Logística' },
            { value: 'OPERACIONAL', label: 'Operacional' },
            { value: 'PRODUCAO', label: 'Produção' },
          ]"
        ></select-wrapper>
      </div>
      <div class="sp-3 contact-form">
        <v-checkbox
          name="receivesClousing"
          label="Recebe Encerramento"
          v-model="contactDetails.receivesClousing"
        ></v-checkbox>
        <v-checkbox
          name="receiveNps"
          label="Recebe NPS"
          v-model="contactDetails.receiveNps"
        ></v-checkbox>
      </div>

      <div class="sp-3" style="margin-top: 20px">
        <h4>Informações de Usuário</h4>
      </div>
      <div class="sp-3 contact-form" style="margin-top: -10px">
        <v-checkbox
          name="enabled"
          label="Login Habilitado"
          v-model="contactDetails.enabled"
        ></v-checkbox>

        <div v-if="contactDetails.enabled">
          <a href="#" @click.prevent="openChangePassword" class="crm-button"
          >Alterar Senha</a
          >
        </div>
      </div>

      <div class="sp-3" style="margin-top: 20px">
        <h4>Perfil de Usuário</h4>
        <h5 class="tx-normal tx-uppercase tx-15 mb-3 mt-2 tx-gray-400">
          Perfil de Usuários Externos <span class="tx-danger">*</span>
        </h5>
      </div>

      <div class="sp-3" style="margin-top: -20px;display: grid; grid-template-columns: repeat(3, 1fr); column-gap: 15px">
        <v-radio
          name="role"
          label="Cliente (Ext)"
          radio-value="CUSTOMER"
          v-model="contactDetails.role"
		  :class="{'input-danger': getError('role') }"
        ></v-radio>

        <v-radio
          name="role"
          label="Parceiro (Ext)"
          v-model="contactDetails.role"
          radio-value="PARTNER"
		  :class="{'input-danger': getError('role') }"
        ></v-radio>

        <!-- <v-radio
          name="role"
          label="Despachante (Ext)"
          v-model="contactDetails.role"
          radio-value="CUSTOM_BROKER"
		  :class="{'input-danger': getError('role') }"
        ></v-radio> -->

        <span class="text-danger sp-3">
          {{
            fieldErrors.find((err) => err.path == "role") ? fieldErrors.find((err) => err.path == "role").message : ""
          }}
        </span>
      </div>

      <div class="sp-3" style="margin-top: 20px">
        <h4>Follow UP de Processos</h4>
      </div>

      <div class="sp-3 contact-form" style="margin-top: -10px">
        <v-checkbox
          name="followUP"
          label="Recebe Follow UP"
          v-model="contactDetails.followUP"
          hint="Follow UP Analítico! Quando o Follow UP Analítico estiver habilitado, uma vez na semana (sexta-feira) os contatos irão receber automaticamente um Follow UP de todos os processos em andamento."
        ></v-checkbox>
      </div>

      <div class="sp-3 contact-form" v-if="contactDetails.followUP === true">
        <v-checkbox
          name="followUPSynthetic"
          label="Follow UP Sintético"
          v-model="contactDetails.followUPSynthetic"
        ></v-checkbox>

        <div v-if="contactDetails.followUPSynthetic">
          <select-wrapper
            v-model="contactDetails.followUPSyntheticPeriod"
            :options="[
              {
                label: 'Semanal',
                value: 'SEMANAL',
              },
              {
                label: 'Quinzenal',
                value: 'QUINZENAL',
              },
              {
                label: 'Mensal',
                value: 'MENSAL',
              },
              {
                label: 'Bimestral',
                value: 'BIMESTRAL',
              },
              {
                label: 'Semestral',
                value: 'SEMESTRAL',
              },
            ]"
			:class="{'input-danger': getError('followUPSyntheticPeriod') }"
            label="Período"
			:requiredFlag="true"
          ></select-wrapper>
          <span class="text-danger">
            {{
              fieldErrors.find((err) => err.path == "followUPSyntheticPeriod") ? fieldErrors.find((err) => err.path == "followUPSyntheticPeriod").message : ""
            }}
          </span>
        </div>

        <div v-if="contactDetails.followUPSynthetic">
          <select-wrapper
            v-model="contactDetails.followUPSyntheticWeekday"
            :options="[
              {
                label: 'Segunda',
                value: 'MONDAY',
              },
              {
                label: 'Terça',
                value: 'TUESDAY',
              },
              {
                label: 'Quarta',
                value: 'WEDNESDAY',
              },
              {
                label: 'Quinta',
                value: 'THURSDAY',
              },
              {
                label: 'Sexta',
                value: 'FRIDAY',
              },
              {
                label: 'Sábado',
                value: 'SATURDAY',
              },
              {
                label: 'Domingo',
                value: 'SUNDAY',
              },
            ]"
			:class="{'input-danger': getError('followUPSyntheticWeekday') }"
            label="Dia de Recebimento"
			:requiredFlag="true"
          ></select-wrapper>
          <span class="text-danger">
            {{
              fieldErrors.find((err) => err.path == "followUPSyntheticWeekday") ? fieldErrors.find((err) => err.path == "followUPSyntheticWeekday").message : ""
            }}
          </span>
        </div>
      </div>

      <div class="sp-3 contact-form" v-if="contactDetails.followUP === true">
        <v-checkbox
          name="followUPAnalytical"
          label="Follow UP Analítico"
          v-model="contactDetails.followUPAnalytical"
        ></v-checkbox>

        <select-wrapper
          class="sp-2"
          v-if="contactDetails.followUPAnalytical"
          v-model="contactDetails.followUpEventsSelected"
          :options="followUpEvents"
          :multiple="true"
          label="Tipos"
        ></select-wrapper>
      </div>

      <div class="sp-3" style="margin-top: 20px">
        <h4>Redes Sociais</h4>
      </div>

      <div style="margin-top: -10px">
        <p class="mb-1">Contato permite marcações nas redes sociais?</p>
        <v-switch
          name="allowsTaggingonSocialNetworks"
          :labels="{
            checked: `${contactDetails.name} permite marcações`,
            unchecked: `${contactDetails.name} não permite ser marcado`,
          }"
          v-model="contactDetails.allowsTaggingonSocialNetworks"
        />
      </div>

      <v-input
        name="instagramURL"
        label="Instagram"
        v-model="contactDetails.instagramURL"
      >
        <template #icon>
          <SearchIcon/>
        </template>
      </v-input>

      <v-input
        name="facebookURL"
        label="Facebook"
        v-model="contactDetails.facebookURL"
      >
        <template #icon>
          <SearchIcon/>
        </template>
      </v-input>

      <v-input
        name="twitterURL"
        label="Twitter"
        v-model="contactDetails.twitterURL"
      >
        <template #icon>
          <SearchIcon/>
        </template>
      </v-input>

      <v-input
        name="linkedInURL"
        label="LinkedIn"
        v-model="contactDetails.linkedInURL"
      >
        <template #icon>
          <SearchIcon/>
        </template>
      </v-input>

      <div class="sp-3" style="margin-top: 20px; margin-bottom: -10px">
        <h4>Endereço</h4>
      </div>
      <select-wrapper
        v-model="companySelectedToSetAddress"
        :label="
          contactDetails.companyAddressVinculated
            ? 'Endereço da empresa'
            : 'Vincular endereço da empresa'
        "
        @change="linkAddressOfCompany"
        :options="contactCompanies"
      />

      <v-input
        name="postalCode"
        label="CEP"
        v-model="contactDetails.postalCode"
        :mask="['#####-###']"
        @blur="findCEP"
      ></v-input>

      <v-input
        name="address"
        label="Logradouro"
        v-model="contactDetails.address"
      ></v-input>

      <v-input
        name="addressNumber"
        label="Número"
        v-model="contactDetails.addressNumber"
        :mask="['#######']"
      ></v-input>

      <v-input
        name="complement"
        label="Complemento"
        v-model="contactDetails.complement"
      ></v-input>

      <v-input
        name="district"
        label="Bairro"
        v-model="contactDetails.district"
      ></v-input>

      <v-input
        name="city"
        label="Cidade"
        v-model="contactDetails.city"
      ></v-input>

      <v-input
        name="federalUnity"
        label="Estado"
        v-model="contactDetails.federalUnity"
      ></v-input>

      <div class="sp-3 row justify-content-center mt-4">
        <button type="submit" class="report-button">Atualizar Contato</button>
      </div>
    </form>

    <!-- ------------- ------------- ------------- -->
    <!-- --------- Change Password Modal --------- -->
    <!-- ------------- ------------- ------------- -->
    <v-modal
      title="ALTERAR SENHA"
      description="Defina uma nova senha para o contato"
      :width="400"
      :handler="changePasswordModal"
      @requestClose="closeChangePassword"
    >
      <template #content>
        <div class="columns">
          <div class="span-8">
            <v-input
              name="senha"
              type="password"
              label="Senha"
              v-model="contactDetails.password"
              @input="validatePassWhileWrite($event)"
			  :class="{'input-danger': getError('password') }"
            ></v-input>

            <div class="field-error">
              {{
                fieldErrors.find((err) => err.path == "password")
                  ? fieldErrors.find((err) => err.path == "password").message
                  : ""
              }}
            </div>

            <ul class="pass-validation">
              <li class="flex ai-c gap1">
                <span
                  class="indicator"
                  :class="{
                    success: passValidation.length,
                    error: !passValidation.length,
                  }"
                ></span>
                No mínimo 6 caracteres
              </li>
              <li class="flex ai-c gap1">
                <span
                  class="indicator"
                  :class="{
                    success: passValidation.uppercase,
                    error: !passValidation.uppercase,
                  }"
                ></span>
                Pelo menos 1 letra maiúscula
              </li>
              <li class="flex ai-c gap1">
                <span
                  class="indicator"
                  :class="{
                    success: passValidation.lowercase,
                    error: !passValidation.lowercase,
                  }"
                ></span>
                Pelo menos 1 letra minúscula
              </li>
              <li class="flex ai-c gap1">
                <span
                  class="indicator"
                  :class="{
                    success: passValidation.number,
                    error: !passValidation.number,
                  }"
                ></span>
                Pelo menos 1 número
              </li>
              <li class="flex ai-c gap1">
                <span
                  class="indicator"
                  :class="{
                    success: passValidation.special,
                    error: !passValidation.special,
                  }"
                ></span>
                Pelo menos 1 caracter especial !@#$%^&*()
              </li>
            </ul>
          </div>

          <div class="span-8 mb-2">
            <v-input
              name="confPassword"
              type="password"
              label="Repita a Senha"
              v-model="contactDetails.confPassword"
			  :class="{'input-danger': getError('confPassword') }"
            ></v-input>

            <div class="field-error">
              {{
                fieldErrors.find((err) => err.path == "confPassword")
                  ? fieldErrors.find((err) => err.path == "confPassword")
                    .message
                  : ""
              }}
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="text-center">
          <button
            type="button"
            class="crm-button"
            @click.prevent="updateContactPassword"
            :disabled="Object.values(passValidation).includes(false)"
          >
            Alterar Senha
          </button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<script>
import {SearchIcon} from '@/components/crm/Icons';

import VInput from '@/components/VInput.vue';
import VCheckbox from '@/components/VCheckbox.vue';
import VRadio from '@/components/VRadio.vue';
import SelectWrapper from '@/components/SelectWrapper.vue';
import VSwitch from '@/components/VSwitch.vue';
import VModal from '@/components/VModal.vue';
import * as yup from 'yup';

import config from '@/config.js';
import Axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: 'ContactDetails',
  props: {
    contactDetails: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      fieldErrors: [],
      companySelectedToSetAddress: null,
      followUpEvents: [],
      followUpEventsSelected: [],
      contactCompanies: [],
      changePasswordModal: false,
      passValidation: {
        length: false,
        lowercase: false,
        uppercase: false,
        number: false,
        special: false,
      },
    };
  },
  components: {
    SearchIcon,
    VInput,
    SelectWrapper,
    VCheckbox,
    VRadio,
    VSwitch,
    VModal,
  },
  async created() {
    await this.getFollowUpEventsSelected();
  },
  methods: {
	getError(field) {
      if (this.fieldErrors.length > 0) {
        const item = this.fieldErrors.find((e) => e.path === field || e.type === field);
        if (item && item.message) {
          return this.fieldErrors.find((e) => e.path === field || e.type === field).message;
        }
      } else {
        return null;
      }
    },
	async canUseEmail() {
      try {

        if (this.contactDetails.email1 === '' || this.contactDetails.email1 === null) return;

        const response = await Axios.get(`${config.env.API_DEFAULT.host}/systemUsers/get-by-email?email=${this.contactDetails.email1}`);
		let role = ['CUSTOM_BROKER'];

        if (response.data.data !== null && response.data.data.id !== this.contactDetails.id) {
          	const data = response.data.data;

			if (data.archived == null || role.includes(data.role)) {
				Swal.fire({
					title: 'Verificação de Email',
					html: 'O e-mail <strong>"' + this.contactDetails.email1 + '"</strong> já está vinculado a outro contato e não pode ser gerenciado pelo contato da empresa.',
					type: 'error',
				});
			} else {
				let htmlError = "";
				htmlError = "<div>";
				htmlError += `<h4 class="mb-4 text-left">O e-mail <strong>"${this.contactDetails.email1}"</strong> á está vinculado a outro contato.</h4>`;
				htmlError += '<ul class="list-group">';
				htmlError += `
					<li class="list-group-item flex ai-c jc-sb fgap2">
					<p class="mg-b-0">${data.name} (${data.enabled ? "Habilitado" : "Desabilitado"})</p>
					<a href="/crm/contatos/${data.id}" rel="nofollow noopener noreferrer" class="crm-button view-profile small">Ver Pefil</a>
					</li>
				`;
				htmlError += "</ul>";
				htmlError += "</div>";

				Swal.fire({
					title: 'E-mail já cadastrado!',
					html: htmlError,
					type: 'warning',
				});

				this.contactDetails.email1 = '';
			}
        }
      } catch (error) {

		console.log(error);
    
        if (error.response && error.response.data) {
          Swal.fire({
            title: 'Verificação de Email',
            html: `<p>${error.response.data.message}</p>`,
            type: 'error',
          });
        } else {
          Swal.fire({
            title: 'Verificação de Email',
            html: '<p>Não foi possível verificar se o Email!</p>',
            type: 'error',
          });
        }

        this.contactDetails.email1 = '';
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
    async findCEP() {
      if (this.contactDetails.postalCode) {

		this.$store.commit('setLoading', {
			show: true,
			label: 'Buscando informações de endereço',
			description: 'Aguarde enquanto buscamos informações de endereço a partir do CEP fornecido.',
		});

        const response = await this.$viaCep.buscarCep(
          this.contactDetails.postalCode,
        );

        if (response) {
          if (response.erro) {
			this.$store.commit('setLoading', { show: false });
            Swal.fire(
              {
                title: 'Consultar CEP',
                html: '<p>Não foi possível encontrar este endereço, verifique se o CEP é válido</p>',
                type: 'error',
              },
            );
          } else {
            this.$set(this.contactDetails, 'address', response.logradouro);
            this.$set(this.contactDetails, 'district', response.bairro);
            this.$set(this.contactDetails, 'city', response.localidade);
            this.$set(this.contactDetails, 'complement', response.complemento);
            this.$set(this.contactDetails, 'federalUnity', response.uf);

            this.updateData('address', response.logradouro);
            this.updateData('district', response.bairro);
            this.updateData('city', response.localidade);
            this.updateData('complement', response.complemento);
            this.updateData('federalUnity', response.uf);

			this.$store.commit('setLoading', { show: false });
          }
        } else {
			this.$store.commit('setLoading', { show: false });
		}
      }

      this.companySelectedToSetAddress = null;
    },
    async linkAddressOfCompany() {
      if (
        this.companySelectedToSetAddress !== null
        || this.companySelectedToSetAddress !== ''
      ) {
        Swal.fire({
          title: 'Vincular Endereço da Empresa',
          html: '<p>Tem certeza que deseja vincular o endereço dessa empresa para este contato?</p>',
          type: 'info',
          showConfirmButton: true,
          confirmButtonText: 'Vincular',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.value) {
            await Axios.put(
              `${config.env.API_DEFAULT.host
              }/contactData/linkCompanyAddressToTheContact/${this.companySelectedToSetAddress}/${this.contactDetails.id}`,
            )
              .then((response) => {
                Swal.fire({
                  title: 'Vincular Endereço da Empresa!',
                  text: 'Endereço da empresa vinculado com sucesso!',
                  type: 'success',
                });

                this.$emit('get-contact-details', this.contactDetails.id);
              })
              .catch((error) => {
                Swal.fire({
                  title: 'Vincular Endereço da Empresa!',
                  text: error.response.data.message,
                  type: 'error',
                });
              });
          }
        });
      }
    },
    async submitForm() {
      this.fieldErrors = [];

      const schema = yup.object().shape({
        name: yup
          .string()
          .required('O Nome é obrigatório!')
          .min(3, 'O Nome deve conter no mínimo 3 caracteres!'),
        email1: yup
          .string()
          .email('O Email é inválido!')
          .required('O Email é obrigatório!'),
        role: yup.string().required('O Perfil de Usuário é obrigatório!'),
        category: yup.string().transform(value => (value === null || value === undefined ? '' : value)).required('A Categoria é obrigatória!'),
      });

      const followSchema = yup.object().shape({
        followUPSyntheticPeriod: yup.string().required('O Período é obrigatório quando Follow Up Sintético está selecionado.').typeError('O Período é obrigatório quando Follow Up Sintético está selecionado.'),
        followUPSyntheticWeekday: yup.string().required('O Dia do Recebimento é obrigatório quando Follow Up Sintético está selecionado.').typeError('O Dia do Recebimento é obrigatório quando Follow Up Sintético está selecionado.'),
      });

      Swal.fire({
        title: 'Atualização de Dados',
        html: '<p>Tem certeza que deseja atualizar os dados deste contato?</p>',
        type: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Atualizar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit('setLoading', {
            show: true,
            label: 'Aguarde...',
            description: 'Atualizando os dados do Contato.',
          });

          try {
            await schema.validate(this.contactDetails, {
              abortEarly: false,
            });

            if (this.contactDetails.followUPSynthetic === true) {
              await followSchema.validate(this.contactDetails, {
                abortEarly: false,
              });
            }

            const response = await Axios.put(
              `${config.env.API_DEFAULT.host}/contactData/update/${this.contactDetails.id}`,
              this.contactDetails,
            );

            Swal.fire({
              title: 'Atualização de Dados',
              text: response.data.message,
              type: 'success',
            });
          } catch (e) {
            if (e.name == 'ValidationError') {
				this.fieldErrors = e.inner;
				let html = '<div class="text-left pl-4 pr-4 pt-4"><p>Por favor, preencha os campos obrigatórios abaixo!</p>';
				if (e.errors.length > 0) {
					html += '<ul>';
					e.errors.forEach(error => {
						html += `<li class="mb-2">${error}</li>`
					})
					html += '</ul>';
				}
				html += '</div>';
			
				Swal.fire({
					title: 'Atualização de Dados',
					html: html,
					type: 'error',
					customClass: 'alert-error'
				});
            } else {
              Swal.fire({
                title: 'Atualização de Dados',
                text: e,
                type: 'error',
              });
            }
          } finally {
            this.$store.commit('setLoading', {
              show: false,
            });
          }
        }
      });
    },
    async updateData(field, value, required = false) {
      if (required) {
        if (value === null || value === '' || value === undefined) {
          Swal.fire({
            title: 'Campo Obrigatório!',
            text: 'Este campo não pode ficar em branco!',
            type: 'error',
          });
          return;
        }
      }

      await Axios.patch(
        `${config.env.API_DEFAULT.host
        }/contactData/updateData/${
          this.contactDetails.id}`,
        {field, value},
      ).catch((e) => {
        Swal.fire({
          title: 'Erro Inesperado!',
          text: 'Houve um erro ao atualizar os dados do contato!',
          type: 'error',
        });
      });
    },
    async getContactCompanies() {
      await Axios.get(
        `${config.env.API_DEFAULT.host
        }/contactData/getCompanies/${this.contactDetails.id}`,
      ).then((response) => {
        const companies = response.data.data;

        if (companies.length > 0) {
          companies.forEach((company) => {
            this.contactCompanies.push({
              label: company.name,
              value: company.id,
            });
          });
        }
      });
    },
    async getFollowUpEvents() {
      await Axios.get(
        `${config.env.API_DEFAULT.host}/contactData/getFollowUpEvents/`,
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            this.followUpEvents = response.data.data.map((event) => ({label: event.description, value: event.id}));
          }
        })
        .catch((e) => {
          Swal.fire({
            title: 'Eventos de Processos',
            text: 'Não foi possível carregar o eventos disponível para vincular a este contato',
            type: 'error',
          });
        });
    },
    async getFollowUpEventsSelected() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host
          }/contactData/getFollowUpEventsSelected/${
            this.contactDetails.id}`,
        );
        this.contactDetails.followUpEventsSelected = response.data.data.map(
          (ev) => ev.id,
        );
      } catch (err) {
        Swal.fire({
          title: 'Tipos de Follow Up Analítico',
          text: 'Não foi possível carregar o tipos de follow up analíticos para este contato',
          type: 'error',
        });
      }
    },
    /**
     * Modal Password
     */
    openChangePassword() {
      this.changePasswordModal = true;
    },
    validatePassWhileWrite(value) {
      const validators = {
        length: new RegExp('^.{6,}$'),
        lowercase: new RegExp('[a-z]'),
        uppercase: new RegExp('[A-Z]'),
        number: new RegExp('(?=.*\\d)'),
        special: new RegExp('(?=.*[!@#$%^&*()])'),
      };

      this.passValidation = {
        length: validators.length.test(value),
        lowercase: validators.lowercase.test(value),
        uppercase: validators.uppercase.test(value),
        number: validators.number.test(value),
        special: validators.special.test(value),
      };
    },
    closeChangePassword() {
      this.changePasswordModal = false;
      this.fieldErrors = [];
      this.contactDetails.password = '';
      this.contactDetails.confPassword = '';
      this.passValidation = {
        length: false,
        lowercase: false,
        uppercase: false,
        number: false,
        special: false,
      };
    },
    async updateContactPassword() {
      this.fieldErrors = [];
      const schema = yup.object().shape({
        password: yup
          .string()
          .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()]).{6,}$/, {
            message:
              'A senha deve conter no mínimo 6 caracteres, incluindo letras minúsculas e maiúsculas, numeros e caracteres especiais como !@#$%^&*()',
          })
          .required('Campo obrigatório!'),
        confPassword: yup
          .string()
          .oneOf([yup.ref('password'), null], 'As senhas não são iguais.')
          .required('Campo obrigatório!'),
      });

      try {
        this.$store.commit('setLoading', {
          show: true,
          label: 'Aguarde...',
          description: 'Atualizando a senha do Contato.',
        });

        await schema.validate(
          {
            password: this.contactDetails.password,
            confPassword: this.contactDetails.confPassword,
          },
          {
            abortEarly: false,
          },
        );

        await Promise.all([
          Axios.put(
            `${config.env.API_DEFAULT.host}/contactData/update/${this.contactDetails.id}`,
            this.contactDetails,
          ),
          Axios.post(
            `${config.env.API_DEFAULT.host}/credential/user/${this.contactDetails.id}/reset-password`,
            {
              password: this.contactDetails.password,
              confirmPassword: this.contactDetails.confPassword,
            },
          ),
        ]);

        Swal.fire({
          title: 'Alterar Senha',
          text: 'Senha alterada com sucesso!',
          type: 'success',
        });

        this.closeChangePassword();
      } catch (err) {
        if (err.inner) {
          this.fieldErrors = err.inner;
        } else {
          Swal.fire({
            title: 'Alterar Senha',
            text: err.response.data.message,
            type: 'error',
          });
        }
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
  },
  mounted() {
    // this.companySelectedToSetAddress = this.contactDetails.companyAddressVinculated;
    this.getContactCompanies();
    this.getFollowUpEvents();
  },
  computed: {
	email1Lowercase: {
		get() { return this.contactDetails.email1 ? this.contactDetails.email1.toLowerCase() : this.contactDetails.email1; },
		set(value) { this.contactDetails.email1 = value; }
	},
	email2Lowercase: {
		get() { return this.contactDetails.email2 ? this.contactDetails.email2.toLowerCase() : this.contactDetails.email2; },
		set(value) { this.contactDetails.email2 = value; }
	}
  }
};
</script>

<style scoped>
.pass-validation {
  padding: 1rem 0;
  margin: 0;
}

.pass-validation .indicator {
  width: 12px;
  height: 12px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  margin-right: 0.5rem;
}

.pass-validation .indicator.success {
  background-color: #27ae60;
}

.pass-validation .indicator.error {
  background-color: #c0392b;
}

.report-button {
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 35px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
  font-size: 1rem;
}

.report-button:hover {
  background-color: #1768a7;
}
</style>

<style>
.alert-error {
	width: 500px !important;
}
</style>