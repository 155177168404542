<template>
	<div id="freight-quote">
		<form>
			<div class="flex ai-c jc-sb">
				<label class="section-title mt-0">Envio de E-mail</label>
				<p style="margin-bottom: 0">Os campos marcados com <span class="tx-danger">*</span> são obrigatórios.</p>
			</div>
			<div class="row no-gutters">
				<div class="col-lg-6">
					<div class="form-group bd-b-0-force bd-r-0-force">
						<label class="form-control-label">Origem <span class="tx-danger">*</span></label>
						<input v-model="freightQuoteNationalOrigin" class="form-control" name="freightQuoteNationalOrigin" type="text"/>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group bd-b-0-force">
						<label class="form-control-label">Destino <span class="tx-danger">*</span></label>
						<input v-model="freightQuoteNationalDestination" class="form-control" name="freightQuoteNationalDestination" type="text"/>
					</div>
				</div>
				<div class="col-lg-12">
					<div class="form-group bd-b-0-force">
						<label class="form-control-label">Destinatários - Apenas Transportadoras <span class="tx-danger">*</span></label>
						<div class="input-group-solved">
							<div class="input-group-solved-before" v-if="contactsConveyor.length > 0">
								<i class="fa fa-info op-5"></i>
							</div>
							<div class="input-group-solved-after mg-l-10 wd-100p" v-if="contactsConveyor.length > 0">
								<span v-for="(contact, index) in contactsConveyor" v-bind:key="contact. id">
									<strong>{{ `${contact.company} - ${contact.name}` }}</strong>{{`<${contact.email}> ${contactsConveyor.length === index + 1 ? "" : ","}`}}
								</span>
							</div>
							<span v-if="contactsConveyor.length === 0">Nenhuma transportadora encontrada</span>
						</div>
					</div>
				</div>

				<div class="col-lg-12">
					<div class="form-group">
						<label class="form-control-label">Descrição da Cotação <span class="tx-danger">*</span></label>
						<textarea class="form-control is-valid mg-t-20" name="freightQuoteNationalDescription" placeholder="Informe uma descrição da cotação" v-model="freightQuoteNationalDescription"></textarea>
					</div>
				</div>

				<div class="col-lg-12">
					<div class="row buttons">
						<div class="col-lg-3 col-lg-offset-3">
							<button type="button" class="btn btn-primary btn-block" v-on:click="handleModalPreview(true)">
							 	<i class="fa fa-eye mg-r-5" aria-hidden="true"></i> Pré-Visualizar E-mail
							</button>
						</div>

						<div class="col-lg-3">
							<button type="button" class="btn btn-danger btn-block" v-on:click="sendEmail()">
								<i class="fa fa-paper-plane mg-r-5" aria-hidden="true"></i> Enviar Cotação
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>

		<hr />

		<label class="section-title mg-t-20">Log da Cotação</label>

		<div class="row no-gutters">
			<table class="table table-striped mg-b-0">
				<thead>
					<tr>
						<th class="tx-center">Transportadora / Contato</th>
						<th class="tx-center">Origem</th>
						<th class="tx-center">Destino</th>
						<th class="tx-center">Nome</th>
						<th class="tx-center">E-mail</th>
						<th class="tx-center">Data de Envio</th>
						<th class="tx-center">Enviado Por</th>
						<th class="tx-center">Visualizar</th>
					</tr>
				</thead>
				<tbody v-if="logs.length > 0">
					<tr v-for="(log, index) in logs" :key="log.id">
						<td class="tx-center">{{ `${log.company}` }}</td>
						<td class="tx-center">{{ log.origin }}</td>
						<td class="tx-center">{{ log.destination }}</td>
						<td class="tx-center">{{ log.name ? log.name : '-' }}</td>
						<td class="tx-center">{{ log.email }}</td>

						<td class="tx-center" v-if="log?.createdAt">
							{{ log.createdAt | moment("DD/MM/YYYY") }} às
							{{ log.createdAt | moment("H:mm") }}
						</td>
						<td class="tx-center" v-else>-</td>

						<td class="tx-center">{{ log.createdBy ?? "-" }}</td>
						<td class="tx-center">
							<button class="btn btn-primary btn-icon rounded-circle" v-on:click="selectEmailSended(index)">
								<div><i class="fa fa-eye"></i></div>
							</button>
						</td>
					</tr>
				</tbody>
				<tbody v-else>
					<tr>
						<td colspan="8" class="tx-center">Nennhuma cotação foi enviada até o momento!</td>
					</tr>
				</tbody>
			</table>
			<freight-quote-modal-preview @close="handleModalPreview(false)" v-if="openModalPreview" @sendEmail="sendEmail()" :html="getFinishedLayoutPreview"/>
			<freight-quote-modal-sended @close="handleModalSended(false)" v-if="openModalSended" :html="getFinishedLayoutSended"/>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Axios from "axios";
import config from "@/config.js";

import Swal from "sweetalert2";
import FreightQuoteModalPreview from "./ModalPreview";
import FreightQuoteModalSended from "./ModalSended";

export default {
	name: "freight-quote-national",
	props: {
		model: {
			required: true,
		},
	},
	components: {
		"freight-quote-modal-preview": FreightQuoteModalPreview,
		"freight-quote-modal-sended": FreightQuoteModalSended,
	},
  	data() {
		return {
			openModalPreview: false,
			openModalSended: false,
			freightQuoteNationalDescription: "",
			freightQuoteNationalOrigin: "",
			freightQuoteNationalDestination: "",
			emailSended: "",
			attachment: [],
			contactsConveyor: [],
			logs: [],
			layout: `<!doctype html>
		<html >

		<head>
		<meta charset="utf-8">
		<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
		<meta name="x-apple-disable-message-reformatting">
		<meta http-equiv="X-UA-Compatible" content="IE=edge">
		<title>Prime Internacional</title>

		<style>
		a {
		text-decoration: none;
		outline: none;
		}

		@media (max-width: 449px) {
		.o_col-full {
			max-width: 100% !important;
		}

		.o_col-half {
			max-width: 50% !important;
		}

		.o_hide-lg {
			display: inline-block !important;
			font-size: inherit !important;
			max-height: none !important;
			line-height: inherit !important;
			overflow: visible !important;
			width: auto !important;
			visibility: visible !important;
		}

		.o_hide-xs,
		.o_hide-xs.o_col_i {
			display: none !important;
			font-size: 0 !important;
			max-height: 0 !important;
			width: 0 !important;
			line-height: 0 !important;
			overflow: hidden !important;
			visibility: hidden !important;
			height: 0 !important;
		}

		.o_xs-center {
			text-align: center !important;
		}

		.o_xs-left {
			text-align: left !important;
		}

		.o_xs-right {
			text-align: left !important;
		}

		table.o_xs-left {
			margin-left: 0 !important;
			margin-right: auto !important;
			float: none !important;
		}

		table.o_xs-right {
			margin-left: auto !important;
			margin-right: 0 !important;
			float: none !important;
		}

		table.o_xs-center {
			margin-left: auto !important;
			margin-right: auto !important;
			float: none !important;
		}

		h1.o_heading {
			font-size: 32px !important;
			line-height: 41px !important;
		}

		h2.o_heading {
			font-size: 26px !important;
			line-height: 37px !important;
		}

		h3.o_heading {
			font-size: 20px !important;
			line-height: 30px !important;
		}

		.o_xs-py-md {
			padding-top: 24px !important;
			padding-bottom: 24px !important;
		}

		.o_xs-pt-xs {
			padding-top: 8px !important;
		}

		.o_xs-pb-xs {
			padding-bottom: 8px !important;
		}
		}

		@media screen {
		@font-face {
			font-family: "Roboto";
			font-style: normal;
			font-weight: 400;
			src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format("woff2");
			unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
		}

		@font-face {
			font-family: "Roboto";
			font-style: normal;
			font-weight: 400;
			src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxK.woff2) format("woff2");
			unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
		}

		@font-face {
			font-family: "Roboto";
			font-style: normal;
			font-weight: 700;
			src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2) format("woff2");
			unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
		}

		@font-face {
			font-family: "Roboto";
			font-style: normal;
			font-weight: 700;
			src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format("woff2");
			unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
		}

		.o_sans,
		.o_heading {
			font-family: "Roboto", sans-serif !important;
		}

		.o_heading,
		strong,
		b {
			font-weight: 700 !important;
		}

		a[x-apple-data-detectors] {
			color: inherit !important;
			text-decoration: none !important;
		}
		}
		</style>

		</head>

		<body class="o_body o_bg-light" style="width: 100%;margin: 0px;padding: 0px;-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%;background-color: #dbe5ea;">

		<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
		<tbody>
		<tr>
			<td class="o_hide" align="center" style="display: none;font-size: 0;max-height: 0;width: 0;line-height: 0;overflow: hidden;mso-hide: all;visibility: hidden;">Abertura de Novo Processo na Prime Internacional - Referência: PRI$importProcess.identification - Cliente: $importProcess.customer.name
			</td>
		</tr>
		</tbody>
		</table>

		<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
		<tbody>
		<tr>
			<td class="o_bg-light o_px-xs o_pt-lg o_xs-pt-xs" align="center" style="background-color: #dbe5ea;padding-left: 8px;padding-right: 8px;padding-top: 32px;">

				<table class="o_block-xs" width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation" style="max-width: 600px;margin: 0 auto;">
					<tbody>
					<tr>
						<td class="o_bg-dark o_px o_py-md o_br-t o_sans o_text" align="center"
							style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 16px;line-height: 24px;background-color: #1C3B70;border-radius: 4px 4px 0px 0px;padding-left: 16px;padding-right: 16px;padding-top: 24px;padding-bottom: 24px;">
							<p style="margin-top: 0px;margin-bottom: 0px;"><a class="o_text-white" href="https://example.com/" style="text-decoration: none;outline: none;color: #ffffff;"><img
									src="https://s3-sa-east-1.amazonaws.com/public.prime.com.vc/logo/logo-branca.png" width="136" height="36" alt="Logo Prime Internacional"
									style="max-width: 136px;-ms-interpolation-mode: bicubic;vertical-align: middle;border: 0;line-height: 100%;height: auto;outline: none;text-decoration: none;"></a>
							</p>
						</td>
					</tr>
					</tbody>
				</table>

			</td>
		</tr>
		</tbody>
		</table>

		<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
		<tbody>
		<tr>
			<td class="o_bg-light o_px-xs" align="center" style="background-color: #dbe5ea;padding-left: 8px;padding-right: 8px;">

				<table class="o_block-xs" width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation" style="max-width: 600px;margin: 0 auto;">
					<tbody>
					<tr>
						<td class="o_bg-white o_px-md o_py-xl o_xs-py-md o_sans o_text-md o_text-light" align="center"
							style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 19px;line-height: 28px;background-color: #ffffff;color: #82899a;padding-left: 24px;padding-right: 24px;padding-top: 64px;padding-bottom: 20px;">

							<h2 class="o_heading o_text-dark o_mb-xxs"
								style="font-family: Helvetica, Arial, sans-serif;font-weight: bold;margin-top: 0px;margin-bottom: 4px;color: #242b3d;font-size: 30px;line-height: 39px;">
								Cotação de Frete</h2>
							<p style="margin-top: 0px;margin-bottom: 0px;">Processo PRI{{PROCESSO_NUMERO}}</p>
						</td>
					</tr>
					</tbody>
				</table>

			</td>
		</tr>
		</tbody>
		</table>

		<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
		<tbody>
		<tr>
			<td class="o_bg-light o_px-xs" align="left" style="background-color: #dbe5ea;padding-left: 8px;padding-right: 8px;">
					<table class="o_block-xs" width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation" style="max-width: 600px;margin: 0 auto;">
					<tbody>
					<tr>
						<td class="o_bg-white o_px-md o_py-md o_sans o_text o_text-secondary" align="left"
							style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 16px;line-height: 24px;background-color: #ffffff;color: #424651;padding-left: 24px;padding-right: 24px;padding-top: 24px;">
												<p class="o_mb-xs" style="margin-top: 0px;margin-bottom: 8px;">Olá,</p>
							<p class="o_mb-xs" style="margin-top: 0px;margin-bottom: 8px;">
								Poderiam nos auxiliar com a cotação para frete rodoviário
								<br />
								<br />
								{{CONTEUDO}}
								<br />
								<br />
								<strong>Por favor, verifique os dados abaixo:</strong>
							</p>
						</td>
					</tr>
					</tbody>
				</table>

			</td>
		</tr>
		</tbody>
		</table>

		<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
		<tbody>
		<tr>
			<td class="o_bg-light o_px-xs" align="center" style="background-color: #dbe5ea;padding-left: 8px;padding-right: 8px;">
				<table class="o_block-xs" width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation" style="max-width: 600px;margin: 0 auto;">
					<tbody>
					<tr>
						<td class="o_bg-white o_px-md o_pb-md o_sans o_text o_text-secondary o_left o_xs-center" align="center"
							style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 16px;line-height: 24px;background-color: #ffffff;color: #424651;text-align: left;padding-left: 24px;padding-right: 24px;padding-bottom: 24px;">
							<table width="100%" role="presentation" cellspacing="0" cellpadding="0" border="0">
								<tbody>
								<tr>
									<td class="o_bg-dark o_br o_px o_py" align="left"
										style="    background-color: #ebf5fa;
										color: #424651;border-radius: 4px;padding-left: 16px;padding-right: 16px;padding-top: 16px;padding-bottom: 16px;">
										<p class="o_sans o_text o_text-white"
											style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 16px;line-height: 24px;color: #424651;"><strong>Informações do Processo de Importação</strong></p>
										<table width="100%" role="presentation" cellspacing="0" cellpadding="0" border="0">
											<tbody>
											<tr>
												<td width="60%" class="o_pt-xs" align="left" style="padding-top: 8px;">
													<p class="o_sans o_text-xs o_text-dark_light"
														style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 14px;line-height: 21px;color: #424651;">
														ORIGEM</p>
												</td>
												<td width="40%" class="o_pt-xs" align="right" style="padding-top: 8px;">
													<p class="o_sans o_text-xs o_text-dark_light"
														style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 14px;line-height: 21px;color: #424651;">
														{{ORIGIN}}</p>
												</td>
											</tr>
											<tr>
												<td width="60%" class="o_pt-xs" align="left" style="padding-top: 8px;">
													<p class="o_sans o_text-xs o_text-dark_light" style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 14px;line-height: 21px;color: #424651;">
														DESTINO</p>
												</td>
												<td width="40%" class="o_pt-xs" align="right" style="padding-top: 8px;">
													<p class="o_sans o_text-xs o_text-dark_light" style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 14px;line-height: 21px;color: #424651;">
														{{DESTINATION}}</p>
												</td>
											</tr>
											</tbody>
										</table>
									</td>
								</tr>
								</tbody>
							</table>
						</td>
					</tr>
					</tbody>
				</table>

			</td>
		</tr>
		</tbody>
		</table>

			</td>
		</tr>
		</tbody>
		</table>


		<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
		<tbody>
		<tr>
			<td class="o_bg-light o_px-xs" align="left" style="background-color: #dbe5ea;padding-left: 8px;padding-right: 8px;">

				<table class="o_block-xs" width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation" style="max-width: 600px;margin: 0 auto;">
					<tbody>
					<tr>
						<td class="o_bg-white o_px-md o_py-md o_sans o_text o_text-secondary" align="left"
							style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 16px;line-height: 24px;background-color: #ffffff;color: #424651;padding-left: 24px;padding-right: 24px;padding-top: 24px;padding-bottom: 24px;">
							<p class="o_mb-xs" style="margin-top: 0px;margin-bottom: 20px; font-size: 16px;">
								Qualquer dúvida, nossa equipe está a disposição.
							</p>
							<p class="o_mb-xs" style="margin-top: 0px;margin-bottom: 0px;">
								Atenciosamente,
								<br />
								<strong>Equipe de Importação. </strong>
							</p>
						</td>
					</tr>
					</tbody>
				</table>

			</td>
		</tr>
		</tbody>
		</table>

		<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
		<tbody>
		<tr>
			<td class="o_bg-light o_px-xs" align="center" style="background-color: #dbe5ea;padding-left: 8px;padding-right: 8px;">
				<table class="o_block-xs" width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation" style="max-width: 600px;margin: 0 auto;">
					<tbody>
					<tr>
						<td class="o_bg-white" style="font-size: 24px;line-height: 24px;height: 24px;background-color: #ffffff;">&nbsp; </td>
					</tr>
					</tbody>
				</table>
			</td>
		</tr>
		</tbody>
		</table>

		<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
		<tbody>

		<tr>
			<td class="o_bg-light o_px-xs o_pb-lg o_xs-pb-xs" align="center" style="background-color: #dbe5ea;padding-left: 8px;padding-right: 8px;padding-bottom: 32px;">

				<table class="o_block-xs" width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation" style="max-width: 600px;margin: 0 auto;">
					<tbody>
					<tr>
						<td class="o_bg-dark o_px-md o_py-lg o_br-b o_sans o_text-xs o_text-dark_light" align="center"
							style="font-family: Helvetica, Arial, sans-serif;margin-top: 0px;margin-bottom: 0px;font-size: 14px;line-height: 21px;background-color: #242b3d;color: #a0a3ab;border-radius: 0px 0px 4px 4px;padding-left: 24px;padding-right: 24px;padding-top: 32px;padding-bottom: 32px;">
							<p class="o_mb" style="margin-top: 0px;margin-bottom: 16px;">
								<a class="o_text-dark_light" href="http://www.prime.com.vc" style="text-decoration: none;outline: none;color: #a0a3ab;">
									<img src="https://s3-sa-east-1.amazonaws.com/public.prime.com.vc/logo/icone-branco.png" width="36" alt="Logo Prime Internacional" style="max-width: 36px;-ms-interpolation-mode: bicubic;vertical-align: middle;border: 0;line-height: 100%;height: auto;outline: none;text-decoration: none;">
								</a>
							</p>
							<p class="o_mb" style="margin-top: 0px;margin-bottom: 16px;">
								<strong>Prime Internacional Comércio Exterior</strong>
								<br />
								R. Dr. Pedro Ferreira Nº 333, Centro - Ed. Absolute Business – Sala 1805
								<br />
								Itajaí, Santa Catarina – 88301-030
							</p>
						</td>
					</tr>
					</tbody>
				</table>

				<div class="o_hide-xs" style="font-size: 64px; line-height: 64px; height: 64px;">&nbsp; </div>
			</td>
		</tr>
		</tbody>
		</table>
		</body>

		</html>`,
		};
	},
	watch: {
		model() {
			this.getLogs();
			this.getForwardersContacts();
		},
	},
	methods: {
		handleModalPreview(param) {
			this.openModalPreview = param;
		},
		handleModalSended(param) {
			this.openModalSended = param;
		},
		selectEmailSended(index) {
			this.emailSended = this.logs[index];
			this.openModalSended = true;
		},
    	async getForwardersContacts() {	
			if (this.model.customer) {
				try {
					const response = await Axios.get(`${config.env.API_DEFAULT.host}/companies/${this.model.customer.id}/providers`);
					const providers = response.data._embedded.companies;

					if (providers && providers.length > 0) {
						const contactForwarders = await Promise.all(providers.filter(provider => provider.conveyor).map(async provider => {
							const responseContacts = await Axios.get(`${provider._links.users.href}`);
							return responseContacts.data._embedded.users.map(contact => ({
								companyId: provider.id,
								company: provider.name,
								name: contact.name,
								email: contact.email1,
							}));
						}));

						this.contactsConveyor = contactForwarders.flat();
					} else {
						this.contactsConveyor = [];
					}
				} catch (error) {
					console.error(error);
				}
			}
    	},
		allReplace(retStr, obj) {
			for (const x in obj) {
				retStr = retStr.replace(new RegExp(x, "g"), obj[x]);
			}
			return retStr;
		},
		validateFields() {
			const errors = [];

			if (!this.freightQuoteNationalOrigin) {
				errors.push("Origem não foi preenchido.");
			}

			if (!this.freightQuoteNationalDestination) {
				errors.push("Destino não foi preenchido.");
			}

			if (!this.freightQuoteNationalDescription) {
				errors.push("Descrição não foi preenchido.");
			}

			if (this.contactsConveyor.length === 0) {
				errors.push("O cliente não possui contatos de transportadoras.");
			}

			if (errors.length > 0) {

				let html = '<div class="text-left pl-4 pr-4 pt-4"><p>Por favor, preencha os campos obrigatórios abaixo!</p>';
				html += '<ul>';
				errors.forEach(error => {
					html += `<li class="mb-2">${error}</li>`
				})
				html += '</ul>';
				html += '</div>';
			
				Swal.fire({
					title: 'Cotação de Frete - Nacional',
					html: html,
					type: 'error',
					customClass: 'alert-error'
				});

				return false;
			}

			return true;
		},
		sendEmail() {
			if (!this.validateFields()) {
				return;
			}

			let html = '<div class="text-left pl-4 pr-4 pt-4"><p>Você estará enviando e-mail para:</p>';
			html += '<ul>';
			this.contactsConveyor.forEach((contact)=> {
				html += `<li class="mb-2">${contact.company} - ${contact.name}</li>`
			})
			html += '</ul>';
			html += '</div>';

			Swal.fire({
				title: "Você tem certeza?",
				html: html,
				type: 'warning',
				customClass: 'alert-error',
				showConfirmButton: true,
				confirmButtonText: 'Sim',
				showCancelButton: true,
				cancelButtonText: 'Não',
			}).then(async (willContinue) => {
				if (willContinue.value) {
					try {
						this.$store.commit("setLoading", {
							show: true,
							label: "Aguarde",
							description: "Estamos enviando os e-mails...",
						});

						this.openModalPreview = false;

						const response = await Axios.post(`${config.env.API_DEFAULT.host}/national-freight-quotations`,
							{
								"origin": this.freightQuoteNationalOrigin,
								"destination": this.freightQuoteNationalDestination,
								"description": this.freightQuoteNationalDescription,
								"processId": this.model.id,
								"emails": this.contactsConveyor
							}
						);

						this.freightQuoteNationalOrigin = "";
						this.freightQuoteNationalDestination = "";
						this.freightQuoteNationalDescription = "";

					} catch (err) {
						console.log(err)
						this.$swal(
							"Envio de E-mail",
							"Não foi possível enviar os e-mails.",
							"error"
						);
					} finally {
						this.getLogs();
						this.$store.commit("setLoading", {
							show: false,
							label: "",
							description: "",
						});
					}
				}
			});
		},
		async getLogs() {
			try {
				this.$store.commit("setLoading", { show: true });

				const resultLogs = await Axios.get(`${config.env.API_DEFAULT.host}//national-freight-quotations/process/${this.model.id}`);
				if (resultLogs.data.data.length > 0) {	
					this.logs = resultLogs.data.data;
				}
				
			} catch (e) {
				console.log(e);
			} finally {
				this.$store.commit("setLoading", { show: false });
			}

		},
	},
	computed: {
		getFinishedLayoutPreview() {
			return this.allReplace(this.layout, {
				"{{PROCESSO_NUMERO}}": this.model.identification,
				"{{CONTEUDO}}": this.freightQuoteNationalDescription !== "" ?this.freightQuoteNationalDescription.replaceAll("\n", "<br>") : "Não informado",
				"{{ORIGIN}}": this.freightQuoteNationalOrigin || "Não informado",
				"{{DESTINATION}}": this.freightQuoteNationalDestination || "Não informado",
			});
		},
		getFinishedLayoutSended() {
			return this.allReplace(this.layout, {
				"{{PROCESSO_NUMERO}}": this.model.identification,
				"{{CONTEUDO}}": this.emailSended.description !== "" ? this.emailSended.description.replaceAll("\n", "<br>") : "Não informado",
				"{{ORIGIN}}": this.emailSended.origin || "Não informado",
				"{{DESTINATION}}": this.emailSended.destination || "Não informado",
			});
		},
	},
	async mounted() {
		this.getForwardersContacts();
		this.getLogs();
	},
};
</script>

<style scoped>
	@import "./styles.css";
</style>

<style>
	.alert-error {
		width: 500px !important;
	}
</style>