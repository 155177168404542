import { TRUE } from "sass";

export const labels = {
  'despachantes-aduaneiros': {
    title: 'Listagem de Despachantes Aduaneiros',
    name: 'Despachante Aduaneiro',
    showContacts: true,
    filters: [
      {
        field: 'isCustomBroker',
        value: true,
      },
    ],
    columns: [
      {
        label: 'Habilitado',
        field: 'enabled',
        class: 'text-center',
      },
      {
        label: 'Nome Fantasia / Razão Social',
        field: 'name',
      },
      {
        label: 'CNPJ',
        field: 'federalID',
      },
      {
        label: 'Telefone',
        field: 'telephone1',
      },
      {
        label: 'E-mail',
        field: 'email',
      },
      {
        label: 'Ações',
        field: 'actions',
        class: 'text-right',
      },
    ],
  },
  'transportadoras': {
    title: 'Listagem de Transportadoras',
    name: 'Transportadora',
    showContacts: true,
    filters: [
      {
        field: 'isConveyor',  
        value: true,
      }
    ],
    columns: [
      {
        label: 'Habilitado',
        field: 'enabled',
        class: 'text-center',
      },
      {
        label: 'Nome Fantasia / Razão Social',
        field: 'name',
      },
      {
        label: 'CNPJ',
        field: 'federalID',
      },
      {
        label: 'Telefone',
        field: 'telephone1',
      },
      {
        label: 'E-mail',
        field: 'email',
      },
      {
        label: 'Ações',
        field: 'actions',
        class: 'text-right',
      },
    ],
  },
  'freight-forwarders': {
    title: 'Listagem de Freight Forwarders',
    name: 'Freight Forwarder',
    showContacts: true,
    filters: [
      {
        field: 'isForwarder',  
        value: true,
      }
    ],
    columns: [
      {
        label: 'Habilitado',
        field: 'enabled',
        class: 'text-center',
      },
      {
        label: 'Nome Fantasia / Razão Social',
        field: 'name',
      },
      {
        label: 'CNPJ',
        field: 'federalID',
      },
      {
        label: 'Telefone',
        field: 'telephone1',
      },
      {
        label: 'E-mail',
        field: 'email',
      },
      {
        label: 'Ações',
        field: 'actions',
        class: 'text-right',
      },
    ],
  },
  'comissionados': {
    title: 'Listagem de Comissionados',
    name: 'Comissionado',
    showContacts: false,
    filters: [
      {
        field: 'isCommissioned',  
        value: true,
      }
    ],
    columns: [
      {
        label: 'Habilitado',
        field: 'enabled',
        class: 'text-center',
      },
      {
        label: 'Nome Fantasia / Razão Social',
        field: 'name',
      },
      {
        label: 'CNPJ',
        field: 'federalID',
      },
      {
        label: 'Telefone',
        field: 'telephone1',
      },
      {
        label: 'E-mail',
        field: 'email',
      },
      { 
        label: 'Ações',
        field: 'actions',
        class: 'text-right',
      },
    ],
  },
  'armadores': {
    title: 'Listagem de Armadores',
    name: 'Armador',
    showContacts: false,
    filters: [
      {
        field: 'isShipowner',  
        value: true,
      }
    ],
    ignoreColumns: ['completeName'],
    columns: [
      {
        label: 'Habilitado',
        field: 'enabled',
        class: 'text-center',
      },
      {
        label: 'Nome Fantasia',
        field: 'name',
      },
      {
        label: 'Telefone',
        field: 'telephone1',
      },
      {
        label: 'E-mail',
        field: 'email',
      },
      {
        label: 'Ações',
        field: 'actions',
        class: 'text-right',
      },
    ],
  },
  'exportadores': {
    title: 'Listagem de Exportadores / Fabricantes',
    name: 'Exportador / Fabricantes',
    showContacts: false,
    filters: [
      {
        field: 'isExporter',  
        value: true,
      }
    ],
    ignoreColumns: ['completeName'],
    columns: [  
      {
        label: 'Habilitado',
        field: 'enabled',
        class: 'text-center',
      },  
      {
        label: 'Nome Fantasia',
        field: 'name',
      },
      {
        label: 'Telefone',
        field: 'telephone1',
      },
      {
        label: 'E-mail',
        field: 'email',
      },
      {
        label: 'Ações',
        field: 'actions',
        class: 'text-right',
      },
    ],
  },
  'seguradoras': {
    title: 'Listagem de Seguradoras',
    name: 'Seguradora',
    showContacts: true,
    filters: [
      {
        field: 'isInsuranceCompany',  
        value: true,
      }
    ],
    columns: [
      {
        label: 'Habilitado',
        field: 'enabled',
        class: 'text-center',
      },
      {
        label: 'Nome Fantasia / Razão Social',
        field: 'name',
      },
      {
        label: 'CNPJ',
        field: 'federalID',
      },
      {
        label: 'Telefone',
        field: 'telephone1',
      },
      {
        label: 'E-mail',
        field: 'email',
      },
      {
        label: 'Ações',
        field: 'actions',
        class: 'text-right',
      },
    ],
  },
};