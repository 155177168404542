<template>
	<div class="moviment-terms-container">
		<div class="moviment-terms-main">

			<div class="moviment-terms-title">
				<h2 class="text-dark text-thin mb-0">Listagem de {{ this.modelTitle }}</h2>
				<button class="crm-button" @click.prevent="openModal(null)" :title="`${this.addTitle}`">
					<div class="flex ai-c fgap1">
						<plus-icon :size="20" color="currentColor" /> {{ this.addTitle }}
					</div>
				</button>
			</div>

			<hr class="mb-4" />

			<ConfigTable 
				:tableData="tableData"
				:columns="tableColumns"
				:table-config="tableConfig"
			>
				<template #enabled="{ item }">
					<div class="text-center" v-if="'enabled' in item">
						<span class="config-table-tag text-center" :class="{ success: item.enabled, danger: !item.enabled }">
							{{ item.enabled ? "Sim" : "Não" }}
						</span>
					</div>
				</template>
				<template #code="{ item }">
					<div v-if="'code' in item">
						<p v-if="item.code" class="flex fd-c"> 
							<span class="text-color-primary">{{ item.code }}</span>
						</p>
						<p v-else> - </p>
					</div>
				</template>
				<template #description="{ item }">
					<div v-if="'description' in item">
						<p v-if="item.description" class="flex fd-c"> 
							<span class="text-color-primary">{{ item.description }}</span>
						</p>
						<p v-else> - </p>
					</div>
				</template>

				<!-- Column Actions -->
				<template #actions="{ item }">
					<div class="flex ai-fe jc-fe gap1">
						<a class="edit-button" href="#" @click.prevent="openModal(item.id)">
							<EditIcon :size="16" />
						</a>
						<a class="remove-button" href="#" @click.prevent="deleteModel(item)">
							<TrashIcon :size="16" />
						</a>
					</div>
				</template>
				<!-- Column Actions -->
			</ConfigTable>
		</div>

		<VModal
			:handler="showModal"
			@requestClose="() => closeModal()"
			:title="model?.id ? 'Atualizar' : 'Adicionar'"
			description=""
			:width="1200"
			:height="0"
		>
			<template #content>
				<form @submit.prevent class="moviment-terms-form">
					<div class="span-12 moviment-terms-form">
						<div class="span-12 flex ai-c jc-sb fw-w">
							<h4 class="text-dark mb-0">{{ modalTitleGeneralData }}</h4>
							<p style="margin-bottom: 0">Os campos marcados com <span class="tx-danger">*</span> são obrigatórios.</p>
						</div>

						<div class="span-12">
							<label>Status</label>
							<VSwitch name="enabled" :labels="{ checked: `Habilitado`, unchecked: `Desabilitado`, }" v-model="model.enabled"/>
							<div class="field-error" v-if="getError('enabled')">
								{{ getError("enabled") }}
							</div>
						</div>

						<div class="span-3">
							<VInput type="text" name="code" label="Código" :requiredFlag="true" v-model="model.code" :class="{'input-danger': getError('code') }"></VInput>
							<div class="field-error" v-if="getError('code')">
								{{ getError("code") }}
							</div>
						</div>

						<div class="span-9">
							<VInput type="text" name="description" label="Descrição" :requiredFlag="true" v-model="model.description" :class="{'input-danger': getError('description') }"></VInput>
							<div class="field-error" v-if="getError('description')">
								{{ getError("description") }}
							</div>
						</div>

						<div class="span-12">
							<hr class="mt-0 mb-0">
						</div>

						<div class="span-12 flex ai-c jc-sb fw-w">
							<h4 class="text-dark mb-0">Disponível em <span class="tx-danger">*</span></h4>
						</div>

						<div class="span-12">
							<div class="flex ai-gc gap1 fw-w">
								<VCheckbox name="enabledToIncome" label="Recebimento" v-model="model.enabledToIncome" v-if="showEnabledToIncome"></VCheckbox>
								<VCheckbox name="enabledToExpense" label="Pagamento" v-model="model.enabledToExpense" v-if="showEnabledToExpense"></VCheckbox>
								<VCheckbox name="enabledToEvent" label="Eventos" v-model="model.enabledToEvent" v-if="showEnabledToEvent"></VCheckbox>
								<VCheckbox name="enabledToImport" label="Importação" v-model="model.enabledToImport" v-if="showEnabledToImport"></VCheckbox>
								<VCheckbox name="enabledToExport" label="Exportação" v-model="model.enabledToExport" v-if="showEnabledToExport"></VCheckbox>
								<VCheckbox name="enabledToCRM" label="CRM" v-model="model.enabledToCRM" v-if="showEnabledToCRM"></VCheckbox>
							</div>
							<div class="field-error" v-if="getError('typeRelation')">
								{{ getError("typeRelation") }}
							</div>
						</div>

						<div class="span-12">
							<hr class="mt-0 mb-0">
						</div>

						<div class="span-12 flex ai-c jc-sb fw-w">
							<h4 class="text-dark mb-0">Mapeamento Integração c/ Empresas ( DE / PARA )</h4>
						</div>

						<div class="span-12">
							<table class="table table-striped mg-b-0">
								<thead>
									<tr>
										<th>Código da Empresa</th>
										<th>Código do Item</th>
										<th>Descrição do Item</th>
										<th class="text-center">Ações</th>
									</tr>
								</thead>
								<tbody>
									<tr-inline v-for="(item, index) in mappingFromToIntegration" :key="'moviment-terms-' + index" :row="item" @save="updateFromToIntegration" customClassesActions="moviment-terms">
										<td-inline
											v-model="item.integrationCode"
											type="select"
											selectType="key|value"
											:options="[...companiesWithIntegration]"
											:width="400"
										/>
										<td-inline v-model="item.code" type="string"/>
										<td-inline v-model="item.description" type="string"/>
										<template slot="actions">
											<a href="#" @click.prevent="removeFromToIntegration(item)" class="btn btn-danger btn-icon rounded-circle">
												<div><i class="icon ion-trash-a"></i></div>
											</a>
										</template>
									</tr-inline>

									<tr-inline class="new-item" :isNew="true" :row="newFromToIntegration" @save="createFromToIntegration" customClassesActions="moviment-terms">
										<td-inline
											v-model="newFromToIntegration.integrationCode"
											type="select"
											selectType="key|value"
											:options="[...companiesWithIntegration]"
											:width="400"
										/>
										<td-inline v-model="newFromToIntegration.code" type="string" />
										<td-inline v-model="newFromToIntegration.description" type="string"/>
									</tr-inline>
								</tbody>
							</table>
						</div>
					</div>
				</form>
			</template>

			<template #footer>
				<div class="flex ai-c jc-c fgap2">
					<button class="crm-button red" @click.prevent="() => closeModal()" >
						Cancelar
					</button>
					<button class="crm-button" @click.prevent="onSubmit(true)" >
						Salvar
					</button>
				</div>
			</template>
		</VModal>
  	</div>
</template>

<script>
import ConfigTable from "@/components/Configuration/ConfigTable.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import PlusIcon from "@/components/crm/Icons/Plus.vue";
import EditIcon from "@/components/icons/EditIcon.vue";
import InlineCell from "@/components/InlineCell.vue";
import inlineRow from "@/components/InlineRow.vue";
import VCheckbox from '@/components/VCheckbox.vue';
import VSwitch from "@/components/VSwitch.vue";
import VInput from "@/components/VInput.vue";
import VModal from "@/components/VModal.vue";
import config from "@/config.js";
import Swal from "sweetalert2";
import * as yup from "yup";
import Axios from "axios";

export default {
	name: "movimentTerms-list",
	components : {
		PlusIcon,
		ConfigTable,
		EditIcon,
		TrashIcon,
		VModal,
		VSwitch,
		VInput,
		VCheckbox,
		"tr-inline": inlineRow,
    	"td-inline": InlineCell,
	},
	data() {
		return {
			modelTitle: "",
			addTitle: "",
			modalTitleGeneralData: "",
			modelURl: "",
			showModal: false,
			refreshModal: false,
			formErrors: [],
			companiesWithIntegration: [],
			mappingFromToIntegration: [],
			tableData: new Array(),
			showEnabledToIncome: false,
			showEnabledToExpense: false,
			showEnabledToEvent: false,
			showEnabledToImport: false,
			showEnabledToExport: false,
			showEnabledToCRM: false,
			model: {
				id: null,
				enabled: true,
				code: "",
				description: "",
				enabledToIncome: false,
				enabledToExpense: false,
				enabledToEvent: false,
				enabledToImport: false,
				enabledToExport: false,
				enabledToCRM: false,
			},
			newFromToIntegration: {
				integrationCode: null,
				code: null,
				description: null,
			},
			tableConfig: {
				perPage: 20,
				useSearch: true,
				keysToSearch: ["code", "description"],
				centeredCell: false,
				showRecords: true,
				showPerPage: true,
			},
			tableColumns: [
				{
					label: 'Habilitado',
					field: 'enabled',
					class: 'text-center',
				},
				{
					label: 'Código',
					field: 'code',
					class: 'text-left',
				},
				{
					label: 'Descrição',
					field: 'description',
					class: 'text-left',
				},
				{
					label: 'Ações',
					field: 'actions',
					class: 'text-right',
				},
			],
			requiredFields: [
				{ field: 'integrationCode', label: 'Código de Integração' },
				{ field: 'code', label: 'Código do Item' },
				{ field: 'description', label: 'Descrição do Item' },
			],
		};
	},
	methods: {
		getError(field) {
			if (this.formErrors.length > 0) {
				const item = this.formErrors.find((e) => e.path === field || e.type === field);
				if (item && item.message) {
					return this.formErrors.find((e) => e.path === field || e.type === field).message;
				}
			} else {
				return null;
			}
		},
		async openModal(id = null) {
			if (id) {
				await Axios.get( `${config.env.API_DEFAULT.host}/movimentTerms/${id}`).then((response) => {
					this.model = response.data;
					if (
						this.model.mappingFromToIntegration !== null &&
						this.model.mappingFromToIntegration !== undefined &&
						this.model.mappingFromToIntegration.length > 0
					) {
						this.mappingFromToIntegration = JSON.parse(this.model.mappingFromToIntegration);
					}

					this.showModal = true;
				}).catch((error) => {
					Swal.fire(
						"Movimentações",
						`Ops, houve algum erro: ${error}`,
						"error"
					);
				});
			} else {
				this.showModal = true;
			}
		},
		async closeModal() {
			this.showModal = false;

			this.model.enabled = true;
			this.model.id = null;
			this.model.code = "";
			this.model.description = "";
			this.model.enabledToIncome = false;
			this.model.enabledToExpense = false;
			this.model.enabledToEvent = false;
			this.model.enabledToImport = false;
			this.model.enabledToExport = false;
			this.model.enabledToCRM = false;
			this.mappingFromToIntegration = [];
			
			this.formErrors = [];
			if (this.refreshModal) {
				this.refreshModal = false;
				await this.loadList();
			}
		},
		async loadList() {
			await Axios.get(config.env.API_DEFAULT.host + this.modelURl).then((response) => {
				this.tableData = response.data._embedded.movimentTerms;
			}).catch((error) => {
				swal(
					this.modelTitle,
					" Não foi possível carregar listagem.",
					"error"
				);
			});
		},
		deleteModel(row) {
			swal({
				title: "Você tem certeza?",
				text: `Se você excluir a '${row.code} - ${row.description}', não será possível recuperá-la para uso posterior.`,
				icon: "warning",
				buttons: ["Não", "Sim"],
				dangerMode: true,
			}).then((willContinue) => {
				if (willContinue) {
					Axios.delete(`${config.env.API_DEFAULT.host}/movimentTerms/${row.id}`).then((response) => {
						Swal.fire(
							this.modelTitle,
							`${this.modelTitle} foi removida c/ sucesso!`,
							"success"
						);
						this.loadList();
					}).catch((error) => {
						if (error.response.status === 409) {
							Swal.fire(
								this.modelTitle,
								`Não foi possível excluir a ${this.modelTitle}, porque ela já está em uso por algum lançamento financeiro.`,
								"error"
							);
						} else {
							Swal.fire(this.modelTitle, error.message, "error");
						}
					});
				}
			});
		},
		changeType() {

			const { type } = this.$route.params;

			if (type !== null) {
				switch (type) {
					case "costsCenters":
						this.modelTitle = "Centro de Custos";
						this.addTitle = "Novo Centro de Custos";
						this.modalTitleGeneralData = "Dados Gerais do Centro de Custos"
						this.modelURl = "/movimentTerms/search/findAllByType?type=COST_CENTER";
						this.showEnabledToIncome = true;
						this.showEnabledToExpense = true;
					break;
						case "documents":
						this.modelTitle = "Tipos de Documentos";
						this.addTitle = "Novo Tipo de Documento";
						this.modalTitleGeneralData = "Dados Gerais do Tipos de Documentos"
						this.modelURl = "/movimentTerms/search/findAllByType?type=DOCUMENT";
						this.showEnabledToEvent = true;
						this.showEnabledToImport = true;
						this.showEnabledToExport = true;
						this.showEnabledToCRM = true;
					break;
						case "payments":
						this.modelTitle = "Formas de Pagamentos";
						this.addTitle = "Nova Forma de Pagamento";
						this.modalTitleGeneralData = "Dados Gerais da Forma de Pagamento";
						this.modelURl = "/movimentTerms/search/findAllByType?type=PAYMENT";
						this.showEnabledToIncome = true;
						this.showEnabledToExpense = true;
					break;
						case "categories":
						this.modelTitle = "Categorias de Despesas/Numerário";
						this.addTitle = "Nova Categoria";
						this.modalTitleGeneralData = "Dados Gerais da Categoria de Despesas/Numerário";
						this.modelURl = "/movimentTerms/search/findAllByType?type=CATEGORY";
						this.showEnabledToImport = true;
						this.showEnabledToExport = true;
					break;
				}
			}
		},
		findAllCompaniesWithIntegration() {
			Axios.get( `${config.env.API_DEFAULT.host}/company/findAllCompaniesWithIntegration`).then((response) => {
				const companies = response.data.data;

				if (
					companies !== null &&
					companies !== undefined &&
					companies.length > 0
				) {
				companies.forEach((c) => {
					this.companiesWithIntegration.push({
						key: c.integrationCode,
						value: `(${c.integrationCode}) ${c.name} - ${c.federalID}`,
						federalID: c.federalID,
					});
				});
				}
			}).catch((error) => {
				swal(
					`Não foi possível carregar a listagem de empresas que possuem Código de Integração cadastrado.${error}`,
					"error"
				);
			});
		},
		async createFromToIntegration(data) {

			const errors = this.validateRequiredFields([ data ], this.requiredFields);
			if (errors.length > 0) {
				return this.showErrors(errors);
			}

			this.mappingFromToIntegration.push(data);
			this.newFromToIntegration = {
				integrationCode: "",
				code: "",
				description: "",
			};

			if (this.model?.id) {
				this.onSubmit();
			}
		},
		async updateFromToIntegration() {
			
			const errors = this.validateRequiredFields(this.mappingFromToIntegration, this.requiredFields);
			if (errors.length > 0) {
				return this.showErrors(errors);
			}

			this.newFromToIntegration = {
				integrationCode: "",
				code: "",
				description: "",
			};

			if (this.model?.id) {
				this.onSubmit();
			}
		},
		validateRequiredFields(data, requiredFields) {
			const errors = [];
			data.forEach((item) => {
				requiredFields.forEach((field) => {
					if (!item[field.field] && !errors.includes(`Campo '${field.label}' não preenchido`)) {
						errors.push(`Campo '${field.label}' não preenchido`);
					}
				});
			});
			return errors;
		},
		async showErrors(errors) {
			let html = '<div class="text-left pl-4 pr-4 pt-4"><p>Por favor, preencha os campos obrigatórios abaixo!</p>';
			if (errors.length > 0) {
				html += '<ul>';
				errors.forEach(error => {
					html += `<li class="mb-2">${error}</li>`
				})
				html += '</ul>';
			}
			html += '</div>';
		
			Swal.fire({
				title: 'Adicionar Mapeamento',
				html: html,
				type: 'error',
				customClass: 'alert-error'
			});
		},
		async onSubmit(closeModel = false) {
			try {
				let schema = this.schema();

				if(this.newFromToIntegration.code || this.newFromToIntegration.description || this.newFromToIntegration.integrationCode){

					const errors = this.validateRequiredFields([this.newFromToIntegration], this.requiredFields);
					
					if (errors.length > 0) {
						return this.showErrors(errors);
					}

					this.mappingFromToIntegration.push(this.newFromToIntegration);
					this.newFromToIntegration = {
						integrationCode: "",
						code: "",
						description: "",
					};
				}

				await schema.validate(this.model, {
					abortEarly: false,
				});

				const errors = this.validateRequiredFields(this.mappingFromToIntegration, this.requiredFields);
				if (errors.length > 0) {
					return this.showErrors(errors);
				}
				
				if (this.model?.id) {
					this.updateModel(closeModel);
				} else {
					this.addModel(closeModel);
				}

			} catch (e) {
				 if (e.name && e.name === "ValidationError") {
					this.formErrors = e.inner;
				} else {

					let message = "Erro ao salvar!";
					if (e?.response?.data?.message) {
						message = e.response.data.message;
					}

					Swal.fire({
						title: this.companyName,
						text: message,
						type: "error",
					});
				}
			}

		},
		schema(){
			let schema = yup.object().shape({
				code: yup.string().required("O Código é obrigatório!"),
				description: yup.string().required("O Descrição é obrigatório!"),
			}).test('typeRelation', 'Selecione pelo menos um local disponível', function (values) {
				const { enabledToIncome, enabledToExpense, enabledToEvent, enabledToImport, enabledToExport, enabledToCRM } = values;
				return enabledToIncome || enabledToExpense || enabledToEvent || enabledToImport || enabledToExport || enabledToCRM;
			});
			
			return schema;
		},
		addModel(closeModel = false) {
			const { type } = this.$route.params;

			if (type !== null) {
				switch (type) {
					case "costsCenters":
						this.model.type = "COST_CENTER";
						break;
					case "documents":
						this.model.type = "DOCUMENT";
						break;
					case "payments":
						this.model.type = "PAYMENT";
						break;
					case "categories":
						this.model.type = "CATEGORY";
						break;
				}
			}

			this.manageModel();

			this.$store.commit("setLoading", {
				show: true,
				label: this.mainTitle,
				description: "Atualizando, aguarde...",
			});

			Axios.get( `${config.env.API_DEFAULT.host}/documents/checkValidDocumentCode/${this.model.code}`).then((response) => {
				Axios.post( `${config.env.API_DEFAULT.host}/movimentTerms`, this.model ).then((response) => {

					this.$store.commit("setLoading", {
						show: false,
					});

					if (response.status === 201) {
						Swal.fire(
							this.mainTitle,
							`${response.data.description} foi cadastrada com sucesso.`,
							"success"
						);

						if (closeModel === true) {
							this.refreshModal = true;
							this.closeModal();
						} else {
							this.model = response.data;
						}
						
					} else {
						Swal.fire(
							this.mainTitle,
							`${response.data.description} não foi cadastrada.`,
							"error"
						);
					}

					this.$store.commit("setLoading", {
						show: false,
					});

				}).catch((error) => {
					this.$store.commit("setLoading", {
						show: false,
					});

					Swal.fire({
						title: `Não foi possível cadastrar ${this.model.description}., \n\n${error}`,
						showConfirmButton: false,
						timer: 4000,
						position: "top-right",
						icon: "error",
					});
				});
			}).catch((error) => {
				this.$store.commit("setLoading", {
					show: false,
				});

				Swal.fire(
					`Cadastro de ${this.mainTitle}`,
					error.response.data.message,
					"error"
				);
			});
		},
		updateModel(closeModel = false) {

			this.$store.commit("setLoading", {
				show: true,
				label: this.mainTitle,
				description: "Atualizando, aguarde...",
			});

			this.manageModel();

			Axios.patch(`${config.env.API_DEFAULT.host}/movimentTerms/${this.model.id}`, this.model).then((response) => {
				Swal.fire(
					this.mainTitle,
					`${this.model.description} foi atualizado com sucesso.`,
					"success"
				);

				this.$store.commit("setLoading", {
					show: false,
				});

				if (closeModel === true) {
					this.refreshModal = true;
					this.closeModal();
				}
			}).catch((error) => {
				Swal.fire(
					this.mainTitle,
					` Não foi possível atualizar ${this.model.description}.`,
					"error"
				);

				this.$store.commit("setLoading", {
					show: false,
				});
			});
		},
		manageModel() {
			if (
				this.mappingFromToIntegration !== null &&
				this.mappingFromToIntegration !== undefined &&
				this.mappingFromToIntegration.length > 0
			) {
				this.model.mappingFromToIntegration = JSON.stringify( this.mappingFromToIntegration );
			} else {
				this.model.mappingFromToIntegration = null;
			}
		},
		async removeFromToIntegration(value) {
			Swal.fire({
				title: "Excluir Item",
				html: "Você realmente deseja excluir o item? Se você excluir, precisará adicioná-lo novamente no cadastro.",
				type: "warning",
				showCancelButton: true,
				confirmButtonText: "Sim, excluir!",
				cancelButtonText: "Cancelar",
			}).then((result) => {
				if (result.value) {
					const index = this.mappingFromToIntegration.findIndex((item) => item.code === value.code);

					if (index !== "" && index !== null) {
						this.mappingFromToIntegration.splice(index, 1);

						if (this.model?.id) {
							this.onSubmit();
						}

						Swal.fire(
							"Excluir Item",
							" O item foi excluído com sucesso da lista de mapemamentos.",
							"success"
						);
					} else {
						Swal.fire(
							"Excluir Item",
							" O item não pode ser excluído da lista de mapemamentos.",
							"danger"
						);
					}
				}
			});
		},
	},
	mounted() {
		this.changeType();
		this.loadList();
		this.findAllCompaniesWithIntegration();
	},
};
</script>

<style scoped>
.moviment-terms-container {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	background-color: white;
	height: calc(100vh - 105px);
	overflow: hidden;
}

.moviment-terms-main {
	padding: 1.25rem 0.75rem;
	grid-column: span 12;
	overflow: auto;
}

.moviment-terms-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	flex-wrap: wrap;
	margin-bottom: 1rem;
}

.moviment-terms-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.moviment-terms-form {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 15px;
  align-content: start;
}

.moviment-terms-form .field-error {
  padding: 0.25rem;
  color: var(--danger);
}

.text-color-primary {
  color: var(--header-text-color-hover);
}
</style>

<style>
.alert-error {
	width: 500px !important;
}
</style>