<template>
  <div>
    <div v-if="isOutOfDate()" class="alert alert-warning">
      <div class="row col-md-12 text-dark mb-1">
        <i class="fa fa-exclamation-triangle mr-2" style="font-size: 25px"></i>
        <span style="font-size: 20px">
          <strong> Cadastro desatualizado </strong>
        </span>
      </div>
      <span class="text-dark">
        A última atualização foi feita em
        <strong>
          {{ getLastDateOfUpdate() }}
        </strong>
      </span>
    </div>
    <form @submit.prevent="submitForm" class="contact-form">
      <div class="sp-3 flex ai-c jc-sb">
        <h4>Informações da Empresa</h4>
        <DropdownComponent
          label="Ações"
          contentPosition="right"
          :minWidth="200"
        >
          <template #content>
            <li>
              <a href="#" @click="generateNewPDF">Documento de Instrução</a>
            </li>
          </template>
        </DropdownComponent>
      </div>

      <!-- <div class="sp-3 mt-0 mb-0">
        <p class="mb-2">Tipo de Relacionamento</p>
        <div class="flex fw-w ai-c fgap1">
          <v-checkbox
            v-for="relation in relationTypes"
            :key="relation.label"
            name="relation"
            :label="relation.label"
            v-model="customerDetails[relation.value]"
            small
            :color="'#1B84E7'"
          ></v-checkbox>
        </div>
      </div> -->
      <div>
        <p class="mb-1">Situação do Cliente</p>
        <v-switch
          name="enabled"
          :labels="{
            checked: 'Habilitado',
            unchecked: 'Desabilitado',
          }"
          v-model="customerDetails.enabled"
        />
      </div>

      <div class="sp-2">
        <p class="mb-0">Regime Fiscal</p>
        <div class="flex fw-w ai-c fgap1">
          <v-radio
            name="taxRegime"
            label="Lucro Presumido"
            radio-value="LUCRO_PRESUMIDO"
            v-model="customerDetails.taxRegime"
            small
            :color="'#395B64'"
          ></v-radio>
          <v-radio
            name="taxRegime"
            label="Lucro Real"
            radio-value="LUCRO_REAL"
            :color="'#395B64'"
            v-model="customerDetails.taxRegime"
            small
          ></v-radio>
          <v-radio
            name="taxRegime"
            label="Simples"
            radio-value="SIMPLES"
            :color="'#395B64'"
            v-model="customerDetails.taxRegime"
            small
          ></v-radio>
        </div>
      </div>

      <div>
        <v-input
          name="federalID"
          type="tel"
          :mask="['##.###.###/####-##']"
          label="CNPJ"
          v-model="customerDetails.federalID"
          class="flex1"
          style="min-width: 150px"
        ></v-input>
        <span class="text-danger">
          {{
            fieldErrors.find((err) => err.path == "federalID")
              ? fieldErrors.find((err) => err.path == "federalID").message
              : ""
          }}
        </span>
      </div>

      <div>
        <v-input
          name="completeName"
          type="text"
          label="Razão Social"
          v-model="customerDetails.completeName"
        ></v-input>
        <span class="text-danger">
          {{
            fieldErrors.find((err) => err.path == "completeName")
              ? fieldErrors.find((err) => err.path == "completeName").message
              : ""
          }}
        </span>
      </div>

      <div>
        <v-input
          name="name"
          type="text"
          label="Nome Fantasia"
          v-model="customerDetails.name"
        ></v-input>
        <span class="text-danger">
          {{
            fieldErrors.find((err) => err.path == "name")
              ? fieldErrors.find((err) => err.path == "name").message
              : ""
          }}
        </span>
      </div>

      <v-input
        name="openingDate"
        type="date"
        label="Data de Fundação da Empresa"
        v-model="customerDetails.openingDate"
      ></v-input>

      <div>
        <p class="mb-0">I.E. Isenta?</p>
        <v-switch
          name="stateRegistrationExempt"
          :labels="{
            checked: `${
              customerDetails.name ? customerDetails.name : 'Empresa'
            } não tem IE`,
            unchecked: `Inscrição Estadual: ${customerDetails.stateRegistration}`,
          }"
          v-model="customerDetails.stateRegistrationExempt"
        />
      </div>

      <div>
        <v-input
          v-if="!customerDetails.stateRegistrationExempt"
          name="stateRegistration"
          type="text"
          label="Inscrição Estadual"
          v-model="customerDetails.stateRegistration"
        ></v-input>
      </div>

      <v-input
        name="municipalRegistration"
        type="text"
        label="Inscrição Municipal"
        v-model="customerDetails.municipalRegistration"
      ></v-input>

      <v-input
        name="telephone1"
        type="tel"
        label="Telefone Principal"
        v-model="customerDetails.telephone1"
        :mask="['(##) ####-####', '(##) #####-####']"
      ></v-input>

      <v-input
        name="telephone2"
        type="tel"
        label="Telefone Secundário"
        v-model="customerDetails.telephone2"
        :mask="['(##) ####-####', '(##) #####-####']"
      ></v-input>

      <v-input
        name="email"
        type="text"
        label="E-mail - Quando tiver mais do que um, separar por vírgula"
		v-model="emailLowercase"
      ></v-input>

      <v-input
        name="website"
        type="text"
        label="Website"
        v-model="customerDetails.website"
      ></v-input>

      <select-wrapper
        v-model="customerDetails.origin"
        :options="[
          { label: 'Prospecção', value: 'PROSPECTION' },
          { label: 'Indicação', value: 'RECOMMENDATION' },
        ]"
        label="Origem"
      ></select-wrapper>

      <div class="sp-3 mt-3 bd-t pd-t-10">
        <h4>Endereço</h4>
      </div>

      <v-input
        name="postalCode"
        type="text"
        label="CEP"
        :mask="['#####-###']"
        v-model="customerDetails.postalCode"
        @blur="findCEP"
      ></v-input>

      <v-input
        name="address"
        type="text"
        label="Logradouro"
        v-model="customerDetails.address"
      ></v-input>

      <v-input
        name="addressNumber"
        type="text"
        label="Número"
        v-model="customerDetails.addressNumber"
      ></v-input>

      <v-input
        name="complement"
        type="text"
        label="Complemento"
        v-model="customerDetails.complement"
      ></v-input>

      <v-input
        name="district"
        type="text"
        label="Bairro"
        v-model="customerDetails.district"
      ></v-input>

      <v-input
        name="city"
        type="text"
        label="Cidade"
        v-model="customerDetails.city"
      ></v-input>

      <v-input
        name="federalUnity"
        type="text"
        label="Estado"
        v-model="customerDetails.federalUnity"
      ></v-input>

      <select-wrapper
        v-model="customerDetails.country"
        :options="paises"
        label="País"
      ></select-wrapper>

      <div class="sp-3">
        <v-textarea
          name="federalUnity"
          label="Observações"
          v-model="customerDetails.observation"
        ></v-textarea>
      </div>

      <div class="sp-3 row justify-content-center mt-2">
        <button type="submit" class="report-button">Atualizar Cliente</button>
      </div>
    </form>

    <v-modal
      :handler="openModalDisabledCompany"
      title="Desabilitar contatos da Empresa"
      description=""
      id="modalDisabledCompany"
      :width="700"
    >
      <template #content>
        <div>
          <div class="alert alert-secondary">
            <span class="op-5">
              <i class="fa fa-info-circle mr-2" style="font-size: 20px"></i>
              Caso deseje desabilitar algum contato, basta seleciona-lo na
              listagem abaixo. Lembrando que o vínculo com a empresa não será
              removido.
            </span>
          </div>

          <v-switch
            v-if="contacts.length > 0"
            name="enabled"
            :labels="{
              checked: 'Desabilitar todos',
              unchecked: 'Desabilitar todos',
            }"
            :value="0"
            @input="disableAllContacts"
          />
          <table class="table table-striped mt-3">
            <thead>
              <tr>
                <th class="bg-light">Habilitado</th>
                <th class="bg-light">Nome</th>
                <th class="bg-light">Email</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="contact in contacts">
                <tr v-if="contacts.length > 0" :key="contact.id">
                  <td>
                    <v-switch
                      name="enabled"
                      :labels="{
                        checked: '',
                        unchecked: '',
                      }"
                      :value="1"
                      @input="disableContact($event, contact.id)"
                    />
                  </td>
                  <td>{{ contact.name }}</td>
                  <td>{{ contact.email1 }}</td>
                </tr>
              </template>
              <tr v-if="contacts.length == 0">
                <td colspan="3" class="text-center">
                  Nenhum contato ativo encontrado.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template #footer="{ closeModal }">
        <div class="d-flex align-items-center justify-content-end">
          <button
            type="button"
            class="btn btn-danger ph-6 mr-3"
            @click.prevent="closeModal"
          >
            Cancelar
          </button>
        </div>
      </template>
    </v-modal>
    <documents-instructions
      :customerDetails="customerDetails"
      :handler="isGeneratePdf"
      @beforeDownload="isGeneratePdf = false"
      :language="documentInstructionLanguage"
    ></documents-instructions>
  </div>
</template>

<script>
import VRadio from "@/components/VRadio.vue";
import VInput from "@/components/VInput.vue";
import VTextarea from "@/components/VTextarea.vue";
import VSwitch from "@/components/VSwitch.vue";
import SelectWrapper from "@/components/SelectWrapper.vue";
import VModal from "@/components/VModal.vue";
import moment from "moment";
import DocumentsInstructions from "@/components/crm/customer-details-assets/DocumentsInstructions.vue";
import DropdownComponent from "@/components/DropdownComponent.vue";

import * as importDeclarationLists from "@/lists/import-declaration-lists.js";
import * as yup from "yup";

import config from "@/config.js";
import Axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "CustomerDetails",
  props: {
    customerDetails: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["reloadCustomerDetails"],
  data() {
    return {
      fieldErrors: [],
      documentInstructionLanguage: "pt-BR",
      isGeneratePdf: false,
      customerTeam: {},
      contacts: [],
      openModalDisabledCompany: false,
      codigosPaises: [...importDeclarationLists.codigosPaises],
      lastActivity: null,
      relationTypes: [
        {
          label: "Cliente",
          value: "customer",
        },
        {
          label: "Fornecedor da Empresa",
          value: "provider",
        },
        {
          label: "Exportador / Fabricante",
          value: "exporter",
        },
        {
          label: "Despachante Aduaneiro",
          value: "customBroker",
        },
        {
          label: "Recinto",
          value: "customsEnclosure",
        },
        {
          label: "Transportador",
          value: "conveyor",
        },
        {
          label: "Forwarder",
          value: "forwarder",
        },
        {
          label: "Armador",
          value: "shipowner",
        },
        {
          label: "Comissionado",
          value: "isCommissioned",
        },
      ],
      relations: [],
      teams: [],
    };
  },
  computed: {
    paises() {
      return this.codigosPaises.map((pais) => ({
        label: pais.value,
        value: pais.key,
      }));
    },
	emailLowercase: {
		get() { return this.customerDetails.email?.toLowerCase(); },
		set(value) { this.customerDetails.email = value; }
	},
  },
  components: {
    VInput,
    VSwitch,
    VRadio,
    SelectWrapper,
    VTextarea,
    VModal,
    DocumentsInstructions,
    DropdownComponent,
  },
  filters: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  methods: {
    generateNewPDF() {
      Swal.fire({
        title: "Documento de Instruções",
        html: "Selecione o Idioma em que deseja exportar o documento",
        type: "info",
        input: "select",
        inputOptions: {
          "pt-BR": "Português",
          en: "Inglês",
        },
        inputPlaceholder: "Selecione",
        inputValidator: (value) => {
          this.documentInstructionLanguage = value;
          this.isGeneratePdf = true;
        },
      });
    },
    async submitForm() {
      this.fieldErrors = [];

      const schema = yup.object().shape({
        name: yup
          .string()
          .required("O Nome Fantasia é obrigatório!")
          .min(3, "O Nome Fantasia deve conter no mínimo 3 caracteres!"),
        completeName: yup
          .string()
          .required("O Razão Social é obrigatório!")
          .min(5, "O Razão Social deve conter no mínimo 5 caracteres!"),
        federalID: yup.string().required("O CNPJ é obrigatório!"),
      });

      Swal.fire({
        title: "Atualizar Dados da Empresa",
        html: "<p>Tem certeza que deseja atualizar os dados da empresa?</p>",
        type: "warning",
        showConfirmButton: true,
        confirmButtonText: "Atualizar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit("setLoading", {
            show: true,
            label: "Aguarde...",
            description: "Atualizando os dados da Empresa.",
          });

          try {
            await schema.validate(this.customerDetails, {
              abortEarly: false,
            });

            const response = await Axios.put(
              `${config.env.API_DEFAULT.host}/company/${this.customerDetails.id}`,
              this.customerDetails
            );

            Swal.fire({
              title: "Atualizar Dados da Empresa",
              text: response.data.message,
              type: "success",
            });
          } catch (e) {
            if (e.name == "ValidationError") {
              this.fieldErrors = e.inner;
            } else {
              Swal.fire({
                title: e.response.data.message,
                text: e.response.data.data,
                type: "error",
              });
            }
          } finally {
            this.$store.commit("setLoading", {
              show: false,
            });
          }
        }
      });
    },
    async findCEP() {
      if (this.customerDetails.postalCode) {
        const response = await this.$viaCep.buscarCep(
          this.customerDetails.postalCode
        );
        if (response) {
          if (response.erro) {
            Swal.fire({
              title: "Consultar CEP",
              text: "Não foi possivél encontrar este endereço, verifique se o CEP é válido",
              type: "error",
            });
          } else {
            this.$set(this.customerDetails, "address", response.logradouro);
            this.$set(this.customerDetails, "district", response.bairro);
            this.$set(this.customerDetails, "city", response.localidade);
            this.$set(this.customerDetails, "complement", response.complemento);
            this.$set(this.customerDetails, "federalUnity", response.uf);

            this.updateData("address", response.logradouro);
            this.updateData("district", response.bairro);
            this.updateData("city", response.localidade);
            this.updateData("complement", response.complemento);
            this.updateData("federalUnity", response.uf);
          }
        }
      }
    },
    async getLastActivity() {
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/activities/getLastCustomerActivity/${this.customerDetails.id}`
        );

        this.lastActivity = response.data.data;
        // this.activities = response.data.data;
      } catch (error) {
        Swal.fire({
          title: "Listar Atividades",
          html: `<p>Não foi possível recuperar as atividades do cliente</p> <div>${error}</div>`,
          type: "error",
        });
      }
    },
    isOutOfDate() {
      const now = moment();

      return (
        now.diff(moment(this.customerDetails.modified), "months") >= 6 ||
        (this.lastActivity !== null &&
          now.diff(moment(this.lastActivity.created), "months") >= 3)
      );
    },
    getLastDateOfUpdate() {
      const now = moment();

      return this.lastActivity !== null &&
        moment(this.lastActivity.created).isAfter(
          moment(this.customerDetails.modified)
        ) &&
        now.diff(moment(this.lastActivity.created), "months") >= 3
        ? moment(this.lastActivity).format("DD/MM/YYYY")
        : moment(this.customerDetails.modified).format("DD/MM/YYYY");
    },
    async disableContact(event, contactId) {
      await Axios.put(
        `${config.env.API_DEFAULT.host}/contactData/disableContact/${contactId}`
      )
        .then((response) => {
          this.$toasted.success("Contato desabilitado com sucesso!", {
            position: "top-right",
            duration: 3000,
            singleton: true,
          });

          this.getContacts();
        })
        .catch((error) => {
          Swal.fire({
            title: "Desabilitar contatos da Empresa",
            html: "Houve um erro ao tentar desabilitar o Contato",
            type: "error",
          });
        });
    },
    async disableAllContacts() {
      Swal.fire({
        title: "Desabilitar contatos da Empresa",
        html: "<p>Tem certeza que deseja desabilitar todos os contatos da empresa?</p>",
        type: "warning",
        showConfirmButton: true,
        confirmButtonText: "Desabilitar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          await Axios.put(
            `${config.env.API_DEFAULT.host}/company/disableAllContacts/${this.customerDetails.id}`
          )
            .then((response) => {
              Swal.fire({
                title: "Desabilitar contatos da Empresa",
                text: "Todos os contatos foram desabilitados!",
                type: "success",
              });

              this.openModalDisabledCompany = false;
            })
            .catch((error) => {
              Swal.fire({
                title: "Desabilitar contatos da Empresa",
                html: "Houve um erro ao tentar desabilitar todos os contatos</div>",
                type: "error",
              });
            });
        }
      });
    },
    async updateData(field, value, type = null) {
      this.openModalDisabledCompany = false;

      let val = value;

      if (type === "float") {
        val = parseFloat(String(value).replace(".", "").replace(",", "."));
      }

      if (field === "postalCode") {
        this.findCEP();
      }

      if (field === "openingDate") {
        value += "T00:00:00";
      }

      if (field === "enabled" && !value) {
        this.getContacts();
      }

      await Axios.patch(
        `${config.env.API_DEFAULT.host}/company/updateByField/${this.customerDetails.id}`,
        { field, value: val }
      )
        .then((response) => {
          this.getLastActivity();
          // this.$emit('reloadCustomerDetails');
        })
        .catch((e) => {
          Swal.fire({
            title: "Atualizar Dados da Empresa",
            text: "Houve um erro ao atualizar os dados do contato!",
            type: "error",
          });
        });
    },
    async getContacts() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando contatos vinculadas.",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/company/getActiveContacts/${this.customerDetails.id}`
        );
        this.contacts = response.data.data;

        if (response.data.data.length > 0) {
          this.openModalDisabledCompany = true;
        }
      } catch (error) {
        Swal.fire({
          title: "Listar Contatos Vinculados",
          html: `<p>Não foi possível recuperar os contatos vinculados</p> <div>${error}</div>`,
          type: "error",
        });
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    async getCustomerTeams() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando times do Cliente",
        description: "Aguarde...",
      });

      await Axios.get(
        `${config.env.API_DEFAULT.host}/customers/getCustomerTeam/${this.customerDetails.id}`
      ).then((response) => {
        if (response.data.data) {
          this.customerDetails.team = response.data.data.id;
        } else {
          this.customerDetails.team = null;
        }
      });

      await Axios.get(
        `${config.env.API_DEFAULT.host}/customers/getCustomerTeamOrder/${this.customerDetails.id}`
      ).then((response) => {
        if (response.data.data) {
          this.customerDetails.teamOrder = response.data.data.id;
        } else {
          this.customerDetails.teamOrder = null;
        }
      });

      await Axios.get(
        `${config.env.API_DEFAULT.host}/customers/getCustomerTeamAdvisory/${this.customerDetails.id}`
      ).then((response) => {
        if (response.data.data) {
          this.customerDetails.teamAdvisory = response.data.data.id;
        } else {
          this.customerDetails.teamAdvisory = null;
        }
      });

      if (
        !this.customerDetails.hasTypeAccountAndOrder &&
        !this.customerDetails.hasTypeOrder &&
        !this.customerDetails.hasTypeAdvisory
      ) {
        Swal.fire({
          title: "Dados da Empresa",
          text: "Empresa sem Tipo de Processo e seu respectivo Time Operacional. Verifique a aba comercial antes de editar esta empresa.",
          type: "error",
        });
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
  },
  mounted() {
    this.getCustomerTeams();
    this.getLastActivity();
  },
};
</script>

<style scoped>
.report-button {
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 35px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
  font-size: 1rem;
}

.report-button-success {
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #00a068;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 35px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
  font-size: 1rem;
}

.report-button-success:hover {
  background-color: #00ad70;
}

.report-button:hover {
  background-color: #1768a7;
}

/* #pdf-content-body {
  padding: 2rem;
} */
</style>
