var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-layout form-layout-2"},[_vm._m(0),_c('div',{staticClass:"card processes-container"},[_c('div',{staticClass:"card-header"},[_c('ul',{staticClass:"nav nav-tabs card-header-tabs"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"href":"#containers","data-toggle":"tab"},on:{"click":function($event){_vm.tab = 'containers'}}},[_c('i',{staticClass:"fa fa-box mg-r-2"}),_vm._v(" Containers ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#cotacao","data-toggle":"tab"},on:{"click":function($event){_vm.tab = 'cotacao'}}},[_c('i',{staticClass:"fa fa-globe mg-r-2",staticStyle:{"font-size":"16px"}}),_vm._v(" Cotação de Frete Internacional ")])]),(
              _vm.freightQuotationsResponses &&
              _vm.freightQuotationsResponses.freightQuotations &&
              _vm.freightQuotationsResponses.freightQuotations.length > 0
            )?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#cotacao-recebida","data-toggle":"tab"},on:{"click":function($event){_vm.tab = 'cotacao-recebida'}}},[_c('i',{staticClass:"fa fa-money-check mg-r-2"}),_vm._v(" Cotações Recebidas ")])]):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#cotacao-nacional","data-toggle":"tab"},on:{"click":function($event){_vm.tab = 'cotacao-nacional'}}},[_c('i',{staticClass:"fa fa-truck-moving mg-r-2"}),_vm._v(" Cotação de Frete Nacional ")])])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"tab-content ht-100p"},[_c('div',{staticClass:"tab-pane",class:{ active: _vm.tab === 'containers' },attrs:{"id":"containers"}},[_c('containers')],1),_c('div',{staticClass:"tab-pane",class:{ active: _vm.tab === 'cotacao' },attrs:{"id":"cotacao"}},[_c('freight-quote',{attrs:{"model":_vm.model}})],1),(
              _vm.freightQuotationsResponses &&
              _vm.freightQuotationsResponses.freightQuotations &&
              _vm.freightQuotationsResponses.freightQuotations.length > 0
            )?_c('div',{staticClass:"tab-pane",class:{ active: _vm.tab === 'cotacao-recebida' },attrs:{"id":"cotacao-recebida"}},[_c('freight-quote-response',{attrs:{"model":_vm.model,"freightQuotationsResponses":_vm.freightQuotationsResponses},on:{"load-quotations":_vm.getQuotations}})],1):_vm._e(),_c('div',{staticClass:"tab-pane",class:{ active: _vm.tab === 'cotacao-nacional' },attrs:{"id":"cotacao-nacional"}},[_c('freight-quote-national',{attrs:{"model":_vm.model}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-summary-header mg-b-5 mg-t-0"},[_c('div',[_c('h4',{staticClass:"tx-inverse mg-b-3 d-block"},[_vm._v("Logística")]),_c('p',{staticClass:"mg-b-0"},[_c('i',{staticClass:"icon ion-information-circled mg-r-3"}),_vm._v(" Informações de Logísticas do Processo de Importação. ")])]),_c('div')])
}]

export { render, staticRenderFns }