<template>
  <form @submit.prevent="" class="contact-form">
    <div class="sp-3 light-bg p-3 bd-b">
      <div :class="['flex ai-c jc-sb fw-w fgap2']">
        <div>
          <h4 class="mb-0">FORNECEDORES</h4>
          <p class="mb-0">
            {{ activeProviders.length }} fornecedores cadastrados
          </p>
        </div>
        <div>
          <button
            type="button"
            @click="addDialog = !addDialog"
            class="plus-button"
          >
            <plus-icon color="#ffffff" />
          </button>
        </div>
      </div>

      <transition name="activity" mode="out-in">
        <div class="contact-form mt-4 mb-2" v-if="addDialog">
          <div class="">
            <v-input
              name="providersSearch"
              label="Buscar Fornecedores para vincular à empresa"
              v-model="search"
            >
              <template #icon v-if="searching">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  style="margin: auto; display: block; shape-rendering: auto"
                  width="30px"
                  height="30px"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid"
                >
                  <circle
                    cx="50"
                    cy="50"
                    fill="none"
                    stroke="#0a0a0a"
                    stroke-width="10"
                    r="35"
                    stroke-dasharray="164.93361431346415 56.97787143782138"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      repeatCount="indefinite"
                      dur="1s"
                      values="0 50 50;360 50 50"
                      keyTimes="0;1"
                    ></animateTransform>
                  </circle>
                </svg>
              </template>
            </v-input>
          </div>

          <div class="sp-3" v-if="filteredSearchedProviders.length > 0">
            <div
              class="overflow-table"
              style="max-height: 300px; overflow: auto"
            >
              <table class="small">
                <thead>
                  <tr>
                    <th>Habiliato</th>
                    <th>Nome</th>
                    <th>CNPJ</th>
                    <th>Telefone</th>
                    <th>Celular</th>
                    <th>E-mail</th>
                    <th>Cidade</th>
                    <th>Ações</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="provider in filteredSearchedProviders"
                    :key="provider.id"
                  >
                    <td>{{ provider.enabled ? "Sim" : "Não" }}</td>
                    <td>
                      <p class="mb-0">{{ provider.name }}</p>
                      <p class="mb-0">{{ provider.completeName }}</p>
                    </td>
                    <td>{{ provider.federalID }}</td>
                    <td>{{ provider.telephone1 }}</td>
                    <td>{{ provider.telephone2 }}</td>
                    <td style="max-width: 300px">{{ formatEmails(provider.email)}}</td>
                    <td>
                      <div v-if="provider.city">
                        {{ provider.city }} / {{ provider.federalUnity }}
                      </div>
                    </td>
                    <td>
                      <a
                        href="#"
                        @click="addProvider(provider.id)"
                        class="btn btn-success btn-icon rounded-circle mg-r-5"
                      >
                        <div class="tx-20">
                          <i class="icon ion-person-add tx-20"></i>
                        </div>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div
      class="card-body pd-40 sp-3 text-center"
      v-if="activeProviders.length == 0"
    >
      <img src="@/assets/img/icon1.svg" class="wd-100 mb-4" alt="" />
      <h6 class="tx-md-20 tx-inverse">Nenhum Fornecedor Cadastrado</h6>
      <p>Adicione um fornecedor clicando no botão + que está acima</p>
    </div>

    <div class="ph-2 sp-3" v-else>
      <div class="table-overflow">
        <table>
          <thead>
            <tr>
              <th>Habilitado</th>
              <th>Nome</th>
              <th>CNPJ</th>
              <th>Telefone</th>
              <th>Celular</th>
              <th>E-mail</th>
              <th>Cidade</th>
              <th>Ações</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="provider in activeProviders" :key="provider.id">
              <td>
                <span class="tag success" v-if="provider.enabled">SIM</span>
                <span class="tag success" v-else>SIM</span>
              </td>
              <td>
                <p class="mb-0 text-sm">{{ provider.name }}</p>
                <p class="mb-0">{{ provider.completeName }}</p>
              </td>
              <td>
                {{ provider.federalID }}
              </td>
              <td>
                {{ provider.telephone1 }}
              </td>
              <td>
                {{ provider.telephone2 }}
              </td>
              <td style="max-width: 300px">
                {{ formatEmails(provider.email) }}
              </td>
              <td>
                <div v-if="provider.city">
                  {{ provider.city }} / {{ provider.federalUnity }}
                </div>
              </td>
              <td>
                <!-- <a href="#" class="btn btn-primary btn-icon rounded-circle mg-r-5">
                  <div><i class="icon ion-edit"></i></div>
                </a> -->
                <a
                  href="#"
                  @click.prevent="removeProvider(provider.id)"
                  class="btn btn-danger btn-icon rounded-circle"
                >
                  <div><i class="icon ion-trash-a tx-20"></i></div>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>
</template>

<script>
import config from "@/config.js";
import { PlusIcon } from "@/components/crm/Icons";
import VInput from "@/components/VInput.vue";
import Swal from "sweetalert2";
import Axios from "axios";

export default {
  name: "CustomerProviders",
  props: {
    customerDetails: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    PlusIcon,
    VInput,
  },
  data() {
    return {
      search: "",
      activeProviders: [],
      searchedProviders: [],
      addDialog: false,
      timer: null,
      searching: false,
    };
  },
  async created() {
    await this.getActiveProviders();
  },
  computed: {
    filteredSearchedProviders() {
      return this.searchedProviders.filter((provider) => {
        if (!this.activeProviders.find((pro) => pro.id === provider.id)) {
          return provider;
        }
        return false;
      });
    },
  },
  methods: {
    formatEmails(emails) {
		if(!emails) return '';
      	return emails.replace(/,/g, ', ')
    },
    async getActiveProviders() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando fornecedores.",
      });

      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host}/company/getProviders/${this.customerDetails.id}`
        );
        this.activeProviders = response.data.data;
      } catch (err) {
        Swal.fire({
          title: "Fornecedores Vinculados",
          html: `<p>Não foi possível recuperar os fornecedores vinculados</p> <div>${err}</div>`,
          type: "error",
        });
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    async searchProviders() {
      if (this.search.length > 2) {
        this.searching = true;

        if (this.timer) {
          clearTimeout(this.timer);
        }

        this.timer = setTimeout(async () => {
          try {
            const response = await Axios.get(
              `${config.env.API_DEFAULT.host}/companies/search/findAllByNameContainingIgnoreCase?name=${this.search}`
            );
            this.searchedProviders = response.data._embedded.companies;
          } catch (err) {
            Swal.fire({
              title: "Pesquisar Fornecedor",
              html: `<p>Não foi possível pesquisar os fornecedores.</p> <div>${error}</div>`,
              type: "error",
            });
          }

          this.searching = false;
        }, 800);
      } else {
        clearTimeout(this.timer);
        this.searchedProviders = [];
      }
    },
    async addProvider(providerId) {
      this.$store.commit("setLoading", {
        show: true,
        label: "Vincular Fornecedor",
        description: "Carregando...",
      });

      try {
        await Axios.get(
          `${config.env.API_DEFAULT.host}/company/vinculateCompanyToProvider/${this.customerDetails.id}/${providerId}`
        );
        this.search = "";
        await this.getActiveProviders();

        Swal.fire({
          title: "Vincular Fornecedor",
          html: "<p>Fornecedor vinculado com sucesso</p>",
          type: "success",
        });
      } catch (err) {
        Swal.fire({
          title: "Vincular Fornecedor",
          html: `<p>Não foi possível vincular o fornecedor</p> <div>${err}</div>`,
          type: "error",
        });
      }

      this.$store.commit("setLoading", {
        show: false,
      });
    },
    async removeProvider(providerId) {
      const conf = await Swal.fire({
        title: "Desvincular Fornecedor",
        html: "<p>Tem certeza que deseja desvincular o Fornecedor?</p>",
        type: "info",
        showConfirmButton: true,
        confirmButtonText: "Desvincular",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      });

      if (conf.value) {
        this.$store.commit("setLoading", {
          show: true,
          label: "Desvincular Fornecedor",
          description: "Carregando...",
        });

        try {
          await Axios.get(
            `${config.env.API_DEFAULT.host}/company/unlinkCompanyToProvider/${this.customerDetails.id}/${providerId}`
          );
          await this.getActiveProviders();

          Swal.fire({
            title: "Desvincular Fornecedor",
            html: "<p>Fornecedor desvinculado com sucesso</p>",
            type: "success",
          });
        } catch (err) {
          Swal.fire({
            title: "Desvincular Fornecedor",
            html: `<p>Não foi possível desvincular o fornecedor</p> <div>${err}</div>`,
            type: "error",
          });
        }

        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
  },
  watch: {
    async search() {
      await this.searchProviders();
    },
  },
};
</script>

<style scoped>
.plus-button {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #80b7e2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
}

.plus-button:hover {
  background-color: #1768a7;
}

.activity-submit {
  appearance: none;
  --webkit-appearance: none;
  --moz-appearance: none;
  outline: none;
  --webkit-outline: none;
  --moz-outline: none;
  border: 0;
  background-color: #104375;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 2px;
  color: white;
  padding: 10px 25px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0s;
}

.activity-submit:hover {
  background-color: #1768a7;
}

.activity-enter-active,
.activity-leave-active {
  transition: all 0.5s ease-in-out 0s;
  overflow: hidden;
}

.activity-enter,
.activity-leave-to {
  max-height: 0px;
  overflow: hidden;
}

.activity-enter-to,
.activity-leave {
  transition: all 0.5s ease-in-out 0s;
  max-height: 1000px;
  overflow: hidden;
}

table {
  width: 100%;
}

table tr:not(:last-of-type) td {
  border-bottom: 1px solid #e2e2e2;
}

table tr td,
table tr th {
  padding: 20px 10px;
  color: #343a40;
  text-align: center;
}

table tr th {
  background-color: #8392a51a;
}

table.small tr td,
table.small tr th {
  padding: 10px 5px;
}

.tag {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #e2e2e2;
  font-weight: bold;
}

.tag.success {
  background-color: #c4ffc0;
  color: #0f8906;
}

.tag.danger {
  background-color: #ffc0c0;
  color: #890606;
}
</style>
