<template>
  <tr :class="customClasses">
    <slot></slot>
    <td class="actions" :class="customClassesActions">
      <!-- other action buttons -->
      <slot name="actions"></slot>
      <!-- changes detected button -->
      <a
        href="#"
        v-if="changesDetected"
        @click.prevent="submit()"
        title="Salvar"
        class="btn btn-success btn-icon rounded-circle"
        :class="`${hasActions ? 'mg-l-5' : ''}`"
      >
        <div><i class="fa fa-save"></i></div>
      </a>
    </td>
  </tr>
</template>
<script>
export default {
  props: ["row", "isNew", "afterSaveUpdate", "customClassesActions"],
  data() {
    return {
      originalData: {},
      customClasses: null,
    };
  },
  watch: {
    afterSaveUpdate() {
      if (this.originalData.id === this.afterSaveUpdate.id) {
        this.originalData = this.afterSaveUpdate;
      }
    },
  },
  computed: {
    changesDetected() {
      const str1 = JSON.stringify(Object.freeze(this.originalData));
      const str2 = JSON.stringify(Object.freeze(this.row));
      if (str1 != str2 || this.isNew) {
        return true;
      }
      return false;
    },
    hasActions() {
      return !!this.$slots.actions;
    },
  },
  methods: {
    submit() {
      this.$emit("save", this.row);
      this.originalData = { ...this.row };
    },
  },
  created() {
    this.originalData = { ...this.row };
  },
};
</script>
<style scoped>
tr {
  vertical-align: middle;
}
tr.new-item {
  background: #eee !important;
  border: 1px solid #ddd;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
.btn.btn-primary.save {
  right: 0;
  top: 1px;
  width: 70px;
  height: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 0;
  margin: 0;
}

.moviment-terms,
.container-actions,
.import-actions { 
  text-align: center;
}
</style>