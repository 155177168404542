<template>
  <div>
    <VModal
      :handler="openContactsCompanyModal"
      @requestClose="() => closeListContactsCompanyModal()"
      :title="`Contatos - ${companyName}`"
      description="Gerenciamento e listagem de contatos"
      :width="modalListContactsWidth"
      :height="0"
    >
      <template #content>
        <TableSSR 
          @pageChange="getDataList($event.page, $event.perPage, $event.search)"
          @searchChange="getDataList(1, $event.perPage, $event.search)"
          @perPageChange="getDataList($event.page, $event.perPage, $event.search)"
          :columns="tableColumns" 
          :current-page="tableData?.number + 1 || tableData.number + 1"
          :last-page="tableData.totalPages"
          :tableData="tableData.content"
          :totalRecords="tableData.totalElements"
          :table-config="{
            useSearch: true,
            centeredCell: false,
            showRecords: true,
            paginationTop: false,
            typeSearch: 'query',
            showPerPageTop: false,
            showPerPageBottom: true,
            perPage: tableData?.size ?? 10,
          }"
        >
          <template #buttons>
            <div>
              <button class="crm-button" @click.prevent="openUserModalCreate">
                <div class="flex ai-c fgap1">
                  <plus-icon :size="20" color="currentColor" /> Adicionar Contato
                </div>
              </button>
            </div>
          </template>
          <template #hr>
            <hr>
          </template>
          <template #enabled="{ item }">
            <div class="text-center" v-if="'enabled' in item">
              <span
                class="config-table-tag text-center"
                :class="{ success: item.enabled, danger: !item.enabled }"
              >
                {{ item.enabled ? "Sim" : "Não" }}
              </span>
            </div>
          </template>
          <template #name="{ item }">
            <div v-if="'name' in item">
              <p v-if="item.name"> 
                {{ item.name }}
              </p>
              <p v-else> - </p>
            </div>
          </template>
          <template #email1="{ item }">
            <div v-if="'email1' in item">
              <p v-if="item.email1"> 
                {{ item.email1 }}
              </p>
              <p v-else> - </p>
            </div>
          </template>
          <template #federalID="{ item }">
            <div v-if="'federalID' in item" style="min-width: 130px">
              <p v-if="item.federalID"> 
                {{ item.federalID | formatarCPF }}
              </p>
              <p v-else> - </p>
            </div>
          </template>
          <!-- Column Actions -->
          <template #actions="{ item }">
            <div class="flex ai-fe jc-fe gap1">
              <a class="edit-button" href="#" @click.prevent="getUser(item.id)">
                <EditIcon :size="16" />
              </a>
              <a class="remove-button" href="#" @click.prevent="destroyRelationUser(item.id, item.name)">
                <TrashIcon :size="16" />
              </a>
            </div>
          </template>
          <!-- Column Actions -->
        </TableSSR>
      </template>
    </VModal>

    <VModal
      :handler="openUserModal"
      @requestClose="() => closeUserModal()"
      :title="userData.id ? 'Atualizar Contato' : 'Adicionar Contato'"
      description=""
      :width="1000"
      :height="modalUserHeight"
    >
      <template #content>
        <form @submit.prevent class="registration-form">

          <div class="span-12 registration-form">

            <div class="span-12 flex ai-c jc-sb">
              <h4 class="text-dark mb-0">Dados Gerais</h4>
              <p style="margin-bottom: 0">Os campos marcados com <span class="tx-danger">*</span> são obrigatórios.</p>
            </div>

            <div class="span-12" v-if="fieldShow?.enabled">
              <label>Status</label>
              <v-switch
                name="enabled"
                :labels="{
                  checked: `Habilitado`,
                  unchecked: `Desabilitado`,
                }"
                v-model="userData.enabled"
              />
              <div class="field-error" v-if="getError('enabled')">
                {{ getError("enabled") }}
              </div>
            </div>

            <div class="span-6" v-if="fieldShow?.email1">
              <v-input
                name="email1"
                label="E-mail"
                :requiredFlag="true"
                v-model="userData.email1"
                :class="{ 'input-danger': getError('email1') }"
                @blur="canUseEmail"
              ></v-input>
              <div class="field-error" v-if="getError('email1')">
                {{ getError("email1") }}
              </div>
            </div>

            <div class="span-6" v-if="fieldShow?.name">
              <v-input
                type="text"
                name="name"
                label="Nome Completo"
                :mask="[]"
                :requiredFlag="true"
                v-model="userData.name"
                :class="{'input-danger': getError('name') }"
              ></v-input>
              <div class="field-error" v-if="getError('name')">
                {{ getError("name") }}
              </div>
            </div>

            <div class="span-6" v-if="fieldShow?.federalID">
              <div>
                <v-input
                  type="text"
                  name="federalID"
                  label="CPF"
                  v-model="userData.federalID"
                  :mask="['###.###.###-##']"
                  :class="{'input-danger': getError('federalID') }"
                ></v-input>
                <div class="field-error" v-if="getError('federalID')">
                  {{ getError("federalID") }}
                </div>
              </div>
            </div>

            <div class="span-6" v-if="fieldShow?.gender">
              <select-wrapper 
                v-model="userData.gender" 
                :options="[{ label: 'Masculino',value: 'm', }, { label: 'Feminino',value: 'f' }]" label="Gênero" :class="{'input-danger': getError('gender') }"
                :requiredFlag="true"
              ></select-wrapper>
              <div class="field-error" v-if="getError('gender')">
                {{ getError("gender") }}
              </div>
            </div>
          </div>

          <div class="span-12" v-if="fieldShow?.followUP">
            <hr class="mt-0 mb-0">
          </div>

          <div class="span-12 registration-form" v-if="fieldShow?.followUP">
            <div class="span-12">
              <h4 class="text-dark mb-0">Follow UP de Processos</h4>
            </div>

            <div class="span-12 registration-form">
              <div class="span-3">
                <v-checkbox
                  name="followUP"
                  label="Recebe Follow UP"
                  v-model="userData.followUP"
                  hint="Follow UP Analítico! Quando o Follow UP Analítico estiver habilitado, uma vez na semana (sexta-feira) os contatos irão receber automaticamente um Follow UP de todos os processos em andamento."
                ></v-checkbox>
              </div>
            </div>

            <div class="span-12 registration-form" v-if="userData.followUP === true">
              <div class="span-3">
                <v-checkbox
                  name="followUPSynthetic"
                  label="Follow UP Sintético"
                  v-model="userData.followUPSynthetic"
                ></v-checkbox>
              </div>

              <div class="span-4">
                <div v-if="userData.followUPSynthetic">
                  <select-wrapper
                    v-model="userData.followUPSyntheticPeriod"
                    :options="[
                      {
                        label: 'Semanal',
                        value: 'SEMANAL',
                      },
                      {
                        label: 'Quinzenal',
                        value: 'QUINZENAL',
                      },
                      {
                        label: 'Mensal',
                        value: 'MENSAL',
                      },
                      {
                        label: 'Bimestral',
                        value: 'BIMESTRAL',
                      },
                      {
                        label: 'Semestral',
                        value: 'SEMESTRAL',
                      },
                    ]"
                    label="Período"
                  ></select-wrapper>
                  <div class="field-error" v-if="getError('followUPSyntheticPeriod')">
                    {{ getError("followUPSyntheticPeriod") }}
                  </div>
                </div>
              </div>

              <div class="span-5">
                <div v-if="userData.followUPSynthetic">
                  <select-wrapper
                    v-model="userData.followUPSyntheticWeekday"
                    :options="[
                      {
                        label: 'Segunda',
                        value: 'MONDAY',
                      },
                      {
                        label: 'Terça',
                        value: 'TUESDAY',
                      },
                      {
                        label: 'Quarta',
                        value: 'WEDNESDAY',
                      },
                      {
                        label: 'Quinta',
                        value: 'THURSDAY',
                      },
                      {
                        label: 'Sexta',
                        value: 'FRIDAY',
                      },
                      {
                        label: 'Sábado',
                        value: 'SATURDAY',
                      },
                      {
                        label: 'Domingo',
                        value: 'SUNDAY',
                      },
                    ]"
                    label="Dia de Recebimento"
                  ></select-wrapper>

                  <div class="field-error" v-if="getError('followUPSyntheticWeekday')">
                    {{ getError("followUPSyntheticWeekday") }}
                  </div>
                </div>
              </div>
            </div>

            <div class="span-12 registration-form" v-if="userData.followUP === true">
              <div class="span-3">
                <v-checkbox
                  name="followUPAnalytical"
                  label="Follow UP Analítico"
                  v-model="userData.followUPAnalytical"
                ></v-checkbox>
              </div>

              <div class="span-9">
                <select-wrapper
                  class="sp-2"
                  v-if="userData.followUPAnalytical"
                  v-model="userData.followUPAnalyticalTypes"
                  :options="followUpEvents"
                  :multiple="true"
                  label="Tipos"
                ></select-wrapper>
              </div>
            </div>
          </div>

          <div class="span-12" v-if="fieldShow?.password">
            <hr class="mt-0 mb-0">
          </div>

          <div class="span-12 registration-form" v-if="fieldShow?.password">
            <div class="span-12">
              <h4 class="text-dark mb-0">Dados de Acesso</h4>
            </div>

            <div class="span-12" v-if="fieldShow?.email1">
              <v-input name="emailLogin" label="E-mail para Acesso" v-model="userData.email1" :disabled="true" hint="Infomer o e-mail nos dados gerais"></v-input>
            </div>

            <div class="span-6" v-if="fieldShow?.password">
              <v-input
                :type="typePassword"
                name="password"
                label="Senha"
                :requiredFlag="userData?.id ? false : true"
                autocomplete="off"
                v-model="userData.password"
                :class="{'input-danger': getError('password') }"
                @input="validatePassWhileWrite($event)"
              >

                <template v-slot:icon>
                  <span class="icon-eye" @click="typePassword == 'password' ? typePassword = 'text' : typePassword = 'password'" >
                    <i class="fas fa-eye" v-if="typePassword === 'text'"></i>
                    <i class="fas fa-eye-slash" v-else @click="typePassword == 'password'"></i>
                  </span>
                </template>
              </v-input>
             
              <div class="field-error" v-if="getError('password')">
                {{ getError("password") }}
              </div>
            </div>

            <div class="span-6" v-if="fieldShow?.passwordRepeat">
              <v-input
                :type="typePassword"
                name="passwordRepeat"
                label="Confirmar Senha"
                :requiredFlag="userData?.id ? false : true"
                v-model="userData.passwordRepeat"
                :class="{'input-danger': getError('passwordRepeat') }"
              >
                <template v-slot:icon>
                  <span class="icon-eye" @click="typePassword == 'password' ? typePassword = 'text' : typePassword = 'password'" >
                    <i class="fas fa-eye" v-if="typePassword === 'text'"></i>
                    <i class="fas fa-eye-slash" v-else @click="typePassword == 'password'"></i>
                  </span>
                </template>
              </v-input>
              <div class="field-error" v-if="getError('passwordRepeat')">
                {{ getError("passwordRepeat") }}
              </div>
            </div>
            <div class="span-6">
              <ul class="pass-validation">
                <li class="flex ai-c gap1">
                  <span
                    class="indicator"
                    :class="{
                      success: passValidation.length,
                      error: !passValidation.length,
                    }"
                  ></span>
                  No mínimo 6 caracteres
                </li>
                <li class="flex ai-c gap1">
                  <span
                    class="indicator"
                    :class="{
                      success: passValidation.uppercase,
                      error: !passValidation.uppercase,
                    }"
                  ></span>
                  Pelo menos 1 letra maiúscula
                </li>
                <li class="flex ai-c gap1">
                  <span
                    class="indicator"
                    :class="{
                      success: passValidation.lowercase,
                      error: !passValidation.lowercase,
                    }"
                  ></span>
                  Pelo menos 1 letra minúscula
                </li>
                <li class="flex ai-c gap1">
                  <span
                    class="indicator"
                    :class="{
                      success: passValidation.number,
                      error: !passValidation.number,
                    }"
                  ></span>
                  Pelo menos 1 número
                </li>
                <li class="flex ai-c gap1">
                  <span
                    class="indicator"
                    :class="{
                      success: passValidation.special,
                      error: !passValidation.special,
                    }"
                  ></span>
                  Pelo menos 1 caracter especial !@#$%^&*()
                </li>
              </ul>
            </div>

          </div>
        </form>
      </template>

      <template #footer>
        <div class="flex ai-c jc-c fgap2">
          <button class="crm-button red" @click.prevent="() => closeUserModal()" >
            Cancelar
          </button>
          <button class="crm-button" v-if="userData.id" @click.prevent="saveUser" >
            Salvar
          </button>
          <button class="crm-button" v-else @click.prevent="saveUser">
            Adicionar
          </button>
        </div>
      </template>
    </VModal>
  </div>
</template>

<script>
import SelectWrapper from "@/components/SelectWrapper.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import PlusIcon from "@/components/crm/Icons/Plus.vue";
import EditIcon from "@/components/icons/EditIcon.vue";
import VCheckbox from '@/components/VCheckbox.vue';
import TableSSR from "@/components/TableSSR.vue";
import VSwitch from "@/components/VSwitch.vue";
import VModal from "@/components/VModal.vue";
import VInput from "@/components/VInput.vue";
import * as constants from "@/consts";
import Swal from "sweetalert2";
import config from "@/config";
import * as yup from "yup";
import Axios from "axios";

export default {
  name: 'RegistrationsListContacts',
  components : {
    VModal,
    TableSSR,
    PlusIcon,
    EditIcon,
    VInput,
    VSwitch,
    SelectWrapper,
    TrashIcon,
    VCheckbox,
  },
  props: {
    companyName: {
      type: String,
      default: ''
    },
    listContactsCompanyModal: {
      type: Boolean,
      default: false
    },
    companyID: {
      type: Number,
      default: 0
    },
    company: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tableData: [],
      formErrors: [],
      openUserModal: false,
      refreshModal: false,
      tableColumns: [],
      followUpEvents: [],
      fieldShow: {},
      role: "",
      modalListContactsWidth: 1200,
      saveRelatioCompanyAndUser: false,
      typePassword: "password",
      userData: {
        name: "",
        enabled: true,
        federalID: "",
        email1: "",
        gender: "",
        followUP: false,
        followUPAnalytical: false,
        followUPSynthetic: false,
        followUPAnalyticalTypes: [],
        followUPSyntheticPeriod: "",
        followUPSyntheticWeekday: "",
      },
      passValidation: {
        length: false,
        lowercase: false,
        uppercase: false,
        number: false,
        special: false,
      },
    }
  },
  methods: {
    validatePassWhileWrite(value) {
      const validators = {
        length: new RegExp('^.{6,}$'),
        lowercase: new RegExp('[a-z]'),
        uppercase: new RegExp('[A-Z]'),
        number: new RegExp('(?=.*\\d)'),
        special: new RegExp('(?=.*[!@#$%^&*()])'),
      };

      this.passValidation = {
        length: validators.length.test(value),
        lowercase: validators.lowercase.test(value),
        uppercase: validators.uppercase.test(value),
        number: validators.number.test(value),
        special: validators.special.test(value),
      };
    },
    getError(field) {
      if (this.formErrors.length > 0) {
        const item = this.formErrors.find((e) => e.path === field || e.type === field);
        if (item && item.message) {
          return this.formErrors.find((e) => e.path === field || e.type === field).message;
        }
      } else {
        return null;
      }
    },
    closeListContactsCompanyModal() {
      this.$emit('closeListContactsCompanyModal', false);
    },
    async getDataList(page = 1, perPage = 10, search = "") {
      this.$store.commit("setLoading", {
        show: true,
        label: "Carregando...",
        description: "Recuperando listagem de contatos.",
      });

      await this.getRole();
      
      try {
        const params = {
          page: page - 1,
          search: search.toLowerCase(),
          size: perPage,
          role: this.role,
        };

        const response = await Axios.get(`${config.env.API_DEFAULT.host}/company/${this.companyID}/getUsersByFiltersPaginate`, { params });
        this.tableData = response.data.data;
      } catch (e) {
        console.log(e);
        Swal.fire({
          title: "Cadastros Operacionais",
          text: "Erro ao listar os registros!",
          type: "error",
        });
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async closeUserModal() {
      this.openUserModal = false;
      this.userData = {
        name: "",
        enabled: true,
        federalID: null,
        email1: "",
        gender: "",
        followUP: false,
        followUPAnalytical: false,
        followUPSynthetic: false,
        followUPAnalyticalTypes: [],
        followUPSyntheticPeriod: "",
        followUPSyntheticWeekday: "",
      };

      this.passValidation = {
        length: false,
        lowercase: false,
        uppercase: false,
        number: false,
        special: false,
      };

      this.formErrors = [];

      if (this.refreshModal) {
        this.refreshModal = false;
        await this.getDataList();
      }
    },
    async openUserModalCreate() {
      await this.getFollowUpEvents();
      this.openUserModal = true;
    },
    async getUser(id) {
      this.$store.commit("setLoading", {
        show: true,
        description: "Carregando Dados...",
      });

      try {
        const response = await Axios.get(`${config.env.API_DEFAULT.host}/users/${id}`);
        this.userData = response.data;
        await this.getFollowUpEvents();
        await this.getFollowUpEventsSelected();
        this.openUserModal = true;
      } catch (e) {
        console.log(e.data);
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async saveUser() {
      try {
        this.formErrors = [];
        let response = {};

        this.$store.commit("setLoading", {
          show: true,
          label: this.modelTitle,
          description: "Salvando, aguarde...",
        });

        let schema = null;
        switch (this.company) {
          case 'despachantes-aduaneiros':
             schema = this.schemaCustomBrokers();
            break;
          case 'seguradoras':
            schema = this.schemaInsuranceCompany();
            break;
          case 'freight-forwarders':
          default:
            schema = this.schemaBasic();
            break;
        }

        await schema.validate(this.userData, {
          abortEarly: false,
        });

        if(this.userData.federalID === '') {
          this.userData.federalID = null;
        }

		if(this.userData.role === 'PARTNER') {
			this.userData.enabled = false;
		}

        if (this.userData?.id) {
          response = await Axios.put(`${config.env.API_DEFAULT.host}/systemUsers/update/${this.userData.id}`, {
            ...this.userData,
            role: this.role,
          });
        } else {
          let account = this.$store.state.credentials.data?.account?.id ?? 1;
          response = await Axios.post(`${config.env.API_DEFAULT.host}/systemUsers/create`, {
            ...this.userData,
            role: this.role,
          });
          this.saveRelatioCompanyAndUser = true;
        }

        if (this.saveRelatioCompanyAndUser) {
           await Axios.patch(
            `${config.env.API_DEFAULT.host}/companies/${this.companyID}/users`,
            `${config.env.API_DEFAULT.host}/users/${response.data.data.id}`,
            {
              headers: {
                "Content-Type": "text/uri-list",
              },
            }
          ).catch((error) => {
            Swal.fire({
              title: "Contato",
              text: `Ops, houve algum erro: ${error}`,
              type: "error",
            });
            return;
          });
        }
        Swal.fire({
          title: "Contato",
          text: "Salvo com sucesso!",
          type: "success",
        });

        this.refreshModal = true;
        this.saveRelatioCompanyAndUser = false;
        await this.closeUserModal();
        await this.getDataList();

      } catch (e) {
        console.log(e);
        if (e.name && e.name === "ValidationError") {
          this.formErrors = e.inner;
        } else {
          
          let message = "Erro ao salvar!";
          if (e?.response?.data?.message) {
            message = e.response.data.message;
          }

          Swal.fire({
            title: "Contato",
            text: message,
            type: "error",
          });
        }
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    schemaCustomBrokers() {

      const validateFederalID = (value) => {
        if (value !== '') {
          return yup.string().test(
            'federalID',
            'O CPF deve ter pelo menos 11 caracteres',
            (value) => value.length >= 14
          ).validate(value);
        } else {
          return true;
        }
      };

      let schema = yup.object().shape({
        name: yup.string()
          .required("O Nome Completo é obrigatório!")
          .min(3, "O Nome Completo deve conter no mínimo 3 caracteres!"),
        federalID: yup.string()
          .transform(value => (value === null || value === undefined ? '' : value)) 
          .test(
            'federalID',
            'O CPF deve ter pelo menos 11 caracteres',
            validateFederalID
          ),
        email1: yup.string()
          .required("O E-mail é obrigatório!")
          .email("O E-mail deve ser um e-mail válido!"),
        gender: yup.string().required("O Gênero é obrigatório!"),
        password: yup.string().when('id', {
          is: (id) => !this.userData.id || this.userData.password,
          then: yup.string()
          .required('O Senha é obrigatório!')
          .min(6, 'A senha deve ter pelo menos 6 caracteres')
          .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()]).{6,}$/, {
            message:
              'A senha deve conter no mínimo 6 caracteres, incluindo letras minúsculas e maiúsculas, numeros e caracteres especiais como !@#$%^&*()',
          }),
          otherwise: yup.string(),
        }),
        passwordRepeat: yup.string().when('id', {
          is: (id) => !this.userData.id || this.userData.password,
          then: yup.string().required('O Confirmar Senha é obrigatório!').oneOf([yup.ref('password'), null], 'Senhas não coincidem'),
          otherwise: yup.string(),
        }),
      });
      
      
      return schema;
    },
    schemaBasic() {
      let schema = yup.object().shape({
        name: yup.string()
          .required("O Nome Completo é obrigatório!")
          .min(3, "O Nome Completo deve conter no mínimo 3 caracteres!"),
        email1: yup.string()
          .required("O E-mail é obrigatório!")
          .email("O E-mail deve ser um e-mail válido!"),
      });
      
      return schema;
    },
    schemaInsuranceCompany() {
      let schema = yup.object().shape({
        name: yup.string()
          .required("O Nome Completo é obrigatório!")
          .min(3, "O Nome Completo deve conter no mínimo 3 caracteres!"),
        email1: yup.string()
          .required("O E-mail é obrigatório!")
          .email("O E-mail deve ser um e-mail válido!"),
        followUPSyntheticPeriod: yup.string().transform(value => (value === null || value === undefined ? '' : value)).when('check', {
          is: (check) => this.userData.followUPSynthetic,
          then: yup.string().required('O período é obrigatório quando Follow Up Sintético está selecionado').typeError('O período é obrigatório quando Follow Up Sintético está selecionado'),
          otherwise: yup.string(),
        }),
        followUPSyntheticWeekday: yup.string().transform(value => (value === null || value === undefined ? '' : value)).when('check', {
          is: (check) => this.userData.followUPSynthetic,
          then: yup.string().required('O dia da semana é obrigatório quando Follow Up Sintético está selecionado').typeError('O dia da semana é obrigatório quando Follow Up Sintético está selecionado'),
          otherwise: yup.string(),
        }),
      });

      return schema;
    },
    companyType() {
      switch (this.company) {
        case 'despachantes-aduaneiros':
          this.role = 'CUSTOM_BROKER';
          this.tableColumns = [
            {
              label: 'Habilitado',
              field: 'enabled',
              class: 'text-center',
            },
            {
              label: "Nome",
              field: "name",
            },
            {
              label: "CPF",
              field: "federalID",
            },
            {
              label: "E-mail",
              field: "email1",
            },
            {
              label: 'Ações',
              field: 'actions',
              class: 'text-right',
            },
          ];
          this.fieldShow = {
            enabled: true,
            name: true,
            federalID: true,
            email1: true,
            gender: true,
            password: true,
            passwordRepeat: true
          };
          break;
        case 'freight-forwarders':
          this.role = 'PARTNER';
          this.userData.enabled = false;
          this.userData.federalID = null;
          this.modalListContactsWidth = 1000;
          this.tableColumns = [
            {
              label: "Nome",
              field: "name",
            },
            {
              label: "E-mail",
              field: "email1",
            },
            {
              label: 'Ações',
              field: 'actions',
              class: 'text-right',
            },
          ];
          this.fieldShow = {
            name: true,
            email1: true,
          };
          break;
        case 'seguradoras':
          this.role = 'PARTNER';
          this.userData.enabled = false;
          this.userData.federalID = null;
          this.modalListContactsWidth = 1000;
          this.tableColumns = [
            {
              label: "Nome",
              field: "name",
            },
            {
              label: "E-mail",
              field: "email1",
            },
            {
              label: 'Ações',
              field: 'actions',
              class: 'text-right',
            },
          ];
          this.fieldShow = {
            name: true,
            email1: true,
            followUP: true
          };
          break;
		case 'transportadoras':
          this.role = 'PARTNER';
          this.userData.enabled = false;
          this.userData.federalID = null;
          this.modalListContactsWidth = 1000;
          this.tableColumns = [
            {
              label: "Nome",
              field: "name",
            },
            {
              label: "E-mail",
              field: "email1",
            },
            {
              label: 'Ações',
              field: 'actions',
              class: 'text-right',
            },
          ];
          this.fieldShow = {
            name: true,
            email1: true,
            followUP: false
          };
          break;
        default:
          break;
      }


    },
    getRole(){
      switch (this.company) {
        case 'despachantes-aduaneiros':
          this.role = 'CUSTOM_BROKER';
          break;
        case 'freight-forwarders':
        case 'seguradoras':
        default:
          this.role = 'PARTNER';
          break;
      }
    },
    async destroyRelationUser(id, name) {
      try {
        Swal.fire({
          title: "Excluir Contato",
          html: "<p>Você tem certeza que deseja excluir o contato '" + name + "'?</p>",
          type: "warning",
          showConfirmButton: true,
          confirmButtonText: "EXCLUIR",
          showCancelButton: true,
          cancelButtonText: "CANCELAR",
        }).then(async (result) => {
          if (result.value) {
            this.$store.commit("setLoading", {
              show: true,
              label: "Carregando...",
              description: "Excluindo contato",
            });

            try {
              await Axios.delete(`${config.env.API_DEFAULT.host}/companies/${this.companyID}/users/${id}`);

              Swal.fire(
                "Excluir Contato",
                "Contato excluído com sucesso!",
                "success"
              );

              this.refreshModal = true;
              await this.getDataList();

              this.$store.commit("setLoading", { show: false });
            } catch (e) {
              Swal.fire(
                "Excluir Contato!",
                e.response.data.data,
                "error"
              );

              this.$store.commit("setLoading", { show: false });
            }
          }
        });
      } catch (error) {
        this.$swal(
          "Contato",
          "Não foi possível remove o Contato.",
          "error"
        );
      }
    },
    async canUseEmail() {
      try {

        if (this.userData.email1 === '' || this.userData.email1 === null) return;

        const result = this.tableData.content.find(user => 
          user.email1 === this.userData.email1 && 
          (this.userData?.id ? user.id !== this.userData.id : true)
        );

        if (result) {
          Swal.fire({
            title: 'Verificação de Email',
            html: '<p>O email <strong>"' + this.userData.email1 + '"</strong> ja está cadastrado!</p>',
            type: 'warning',
          });
          this.userData.email1 = ''
          return;
        }

        const response = await Axios.get(`${config.env.API_DEFAULT.host}/systemUsers/get-by-email?email=${this.userData.email1}`);

        if (response.data.data !== null && (this.userData?.id ? response.data.data.id !== this.userData.id : true)) {
          const data = response.data.data;
          if (data.role === this.role) {
            this.userData = response.data.data;
            this.saveRelatioCompanyAndUser = true;

            Swal.fire({
              title: 'Verificação de Email',
              html: `O email <strong>"${this.userData.email1}"</strong> está associado a um contato com a mesma função! </br></br> Os dados do contato foram carregados no formulário abaixo. Clique em "Salvar" para vinculá-lo à <strong>${this.companyName}!</strong>`,
              type: 'warning',
            });
          } else {

            let typeCompany = '';
            switch (this.company) {
              case 'despachantes-aduaneiros':
                typeCompany = 'Despachante Aduaneiro';
                break;
              case 'freight-forwarders':
                typeCompany = 'Freight Forwarders';
                break;
              case 'seguradoras':
                typeCompany = 'Seguradora';
                break;
			  case 'transportadoras':
                typeCompany = 'Transportadora';
                break;
              default:
                break;
            }

            Swal.fire({
              title: 'Verificação de Email',
              html: 'O email <strong>"' + this.userData.email1 + '"</strong> está associado a um contato que não possui a função de <strong>' + typeCompany + '!</strong>',
              type: 'error',
            });

            this.userData.email1 = ''
          }
        }
      } catch (error) {
    
        if (error.response && error.response.data) {
          Swal.fire({
            title: 'Verificação de Email',
            html: `<p>${error.response.data.message}</p>`,
            type: 'error',
          });
        } else {
          Swal.fire({
            title: 'Verificação de Email',
            html: '<p>Não foi possível verificar se o Email!</p>',
            type: 'error',
          });
        }

        this.userData.email1 = '';
      }

      this.$store.commit('setLoading', {
        show: false,
      });
    },
    async getFollowUpEvents() {
      await Axios.get(
        `${config.env.API_DEFAULT.host}/contactData/getFollowUpEvents/`,
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            this.followUpEvents = response.data.data.map((event) => ({label: event.description, value: event.id}));
          }
        })
        .catch((e) => {
          Swal.fire({
            title: 'Eventos de Processos',
            text: 'Não foi possível carregar o eventos disponível para vincular a este contato',
            type: 'error',
          });
        });
    },
    async getFollowUpEventsSelected() {
      if (!this.userData.id) return;
      try {
        const response = await Axios.get(
          `${config.env.API_DEFAULT.host
          }/contactData/getFollowUpEventsSelected/${
            this.userData.id}`,
        );
        this.userData.followUPAnalyticalTypes = response.data.data.map(
          (ev) => ev.id,
        );
      } catch (err) {
        Swal.fire({
          title: 'Tipos de Follow Up Analítico',
          text: 'Não foi possível carregar o tipos de follow up analíticos para este contato',
          type: 'error',
        });
      }
    },
  },
  computed: {
    openContactsCompanyModal() {
      if (this.listContactsCompanyModal) {
        this.getDataList();
      }
      return this.listContactsCompanyModal
    },
    modalUserHeight() {
      if (this.company === 'seguradoras') {
        return this.userData.followUP === true ? 70 : 55;
      } else {
        return 0;
      }
    }
  },
  mounted() {
    this.companyType();
  },
  filters: {
    formatarCPF(cpf) {
      if (!cpf) return ''
      cpf = cpf.replace(/\D+/g, '')
      if (cpf.length === 11) {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
      } else {
        return cpf
      }
    }
  }
}
</script>

<style scoped>
.registration-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.registration-form {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 15px;
  align-content: start;
}

.registration-form .span-4 {
  grid-column: span 4;
}

.registration-form .span-8 {
  grid-column: span 8;
}

.registration-form .span-12 {
  grid-column: span 12;
}

.registration-form .field-error {
  padding: 0.25rem;
  color: var(--danger);
}

.pass-validation {
  padding: 0;
  margin: 0;
}

.pass-validation .indicator {
  width: 12px;
  height: 12px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  margin-right: 0.5rem;
}

.pass-validation .indicator.success {
  background-color: #27ae60;
}

.pass-validation .indicator.error {
  background-color: #c0392b;
}

.icon-eye {
  cursor: pointer; 
  width: 100%; 
  height: 100%; 
  padding: 13px
}
</style>