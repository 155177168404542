<template>
  <td
    @click="action()"
    :class="specialClass"
    :style="[specialStyle]"
    @dblclick="edit"
  >
    <div v-html="label" class="pd-0 mg-0"></div>
  </td>
</template>

<script>
import Axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import config from "@/config.js";
import { mapState } from "vuex";
import Vue from "vue";

import ProcessService from "@/services/ProcessService.js";

export default {
  props: ["column", "data", "fieldInfo", "opennedBy"],
  data() {
    return {
      objects: [
        "customer",
        "exporter",
        "manufacturer",
        "customBroker",
        "account",
        "freightForwarder",
        "conveyor",
        "generalWarehouse",
      ],
      dates: [
        "originalsDocumentsDate",
        "originalsDocumentsDateSolved",
        "originalsBillOfLadingDate",
        "originalsBillOfLadingDateSolved",
        "originalsInvoiceDate",
        "originalsInvoiceDateSolved",
        "originalsPackingListDate",
        "originalsPackingListDateSolved",
        "diRegistryDate",
        "diTypingDate",
        "diResourcefulnessDate",
        "duimpRegistryDate",
        "duimpTypingDate",
        "duimpResourcefulnessDate",
        "billingNFsDate",
        "billingNFsDateSolved",
        "billingNFsEmissionDate",
        "billingNFsEmissionDateSolved",
        "billingBillCustomerDate",
        "billingEmailDate",
        "billingEmailDateSolved",
        "billingSendDate",
        "billingCustomBrokerFinishDate",
        "billingDataConferenceDate",
        "datesEstimatedGoodsReadinesDate",
        "datesETD",
        "datesETA",
        "datesEstimatedCustomerDeliverDate",
        "datesGoodsReadinesDate",
        "datesDepartureDate",
        "datesArrivalDate",
        "datesGoodsPresenceDate",
        "datesInspectionOfTheDIDate",
        "datesGoodsLoadingDate",
        "datesCustomerDeliverDate",
        "datesInspectionMAPADate",
        "datesInspectionMAPADateSolved",
        "datesReleaseMAPADate",
        "datesReleaseMAPADateSolved",
        "datesLinkInTheEnclosureDate",
        "datesLinkInTheEnclosureDateSolved",
        "datesBillOfLadingReleaseDate",
        "datesSiscargaState",
        "datesSiscargaDate",
        "datesIsThereDTCDTATransfer",
        "datesDTCDTA",
        "datesThereIsDesova",
        "datesEnclosureBillingDate",
        "datesComexDate",
        "stepInsurance",
        "stepInsuranceSolved",
        "stepInsuranceValue",
        "stepCashRequest",
        "stepSpreadsheet",
        "created",
        "documentsSentForExchange",
		"cashRequestDoneDate"
      ],
      booleans: [
        "datesIsThereDTCDTATransfer",
        "billingBillCustomerDateSolved",
        "hasInvoices",
      ],
    };
  },
  directives: {},
  computed: {
    ...mapState({
      processes: (state) => state.ProcessStore.processes,
      socket: (state) => state.ProcessStore.socket,
      credentials: (state) => state.credentials,
    }),
    label() {
      const contentDefault = '<i class="icon ion-ios-close tx-white "></i>';

      if (this.column === "hasInvoices") {
        if (this.data.hasInvoices) {
          return `
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="var(--green)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-circle-check"
            >
              <circle cx="12" cy="12" r="10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          `;
        }
        if (!this.data.hasInvoices) {
          return `
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="var(--red)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-circle-x"
            >
              <circle cx="12" cy="12" r="10" />
              <path d="m15 9-6 6" />
              <path d="m9 9 6 6" />
            </svg>
          `;
        }
      }

      if (this.column == "identification") {
        let content = "";

        if (this.opennedBy !== undefined && this.opennedBy !== null) {
          content += `<span class="tx-14 tx-black tx-semibold uppercase" data-title="${this.opennedBy} está editando"><i class="fa fa-users tx-danger mg-t-0 mg-r-5"></i></span>`;
        }

        if (this.data.reminder && this.data.urgent === true) {
          content += '<i class="fa fa-flag tx-primary mg-t-0 mg-r-5"></i>';
          content += `<span class="tx-14 tx-white tx-semibold uppercase" data-title="${
            this.data.reminder
          }">${this.getTypeOfProcessReferenceByProcess(this.data)}</span>`;
        } else if (this.data.reminder) {
          content += '<i class="fa fa-flag tx-primary mg-t-0 mg-r-5"></i>';
          content += `<span class="tx-14 tx-black tx-semibold uppercase" data-title="${
            this.data.reminder
          }">${this.getTypeOfProcessReferenceByProcess(this.data)}</span>`;
        } else if (this.data.urgent === true) {
          content += `<span class="tx-14 tx-semibold uppercase tx-white">${this.getTypeOfProcessReferenceByProcess(
            this.data
          )}</span>`;
        } else {
          content += `<span class="tx-14 tx-black tx-semibold uppercase">${this.getTypeOfProcessReferenceByProcess(
            this.data
          )}</span>`;
        }

        return content;
      }
      if (this.column == "alerts") {
        let content = "";

        // if (this.data.hasWithoutOperationalTeamAlert) {
        //   content = content + `<span class="badge badge-danger p-2" data-title="Cliente sem time operacional de ${this.data.typeOfProcess}">CST</span>`;
        // }

        if (this.data.hasRadarAlert) {
          content +=
            '<span class="tx-16 tx-center mb-1" style="z-index: 1;" data-title="Alerta de Radar"><i class="icon ion-ionic text-dark"></i></span>';
        }

        if (this.data.hasTFAAlert) {
          content +=
            '<span class="tx-16 tx-center tx-danger" style="z-index: 1;" data-title="Ausência do TFA"><i class="icon ion-clipboard text-dark"></i></span> </br>';
        }

        return content;
      }
      if (this.column == "status") {
        if (this.data.urgent === true) {
          return (
            `${'<span class="tx-normal">' + '<span class="tx-white">'}${
              this.data[this.column]
            }</span>` + "</span>"
          );
        }
        if (this.data[this.column] !== null && this.data[this.column] !== "") {
          return `<span class="tx-normal">${this.data[this.column]}</span>`;
        }
      } else if (this.column == "incoterm") {
        if (this.data[this.column] !== null) {
          return `<div class="tx-center">${this.data[this.column]}</div>`;
        }
        return '<div class="tx-center"><i class="tx-14 fa fa-exclamation"></i></div>';
      } else if (this.column == "revenuesReleaseBLAWBState") {
        let revenuesReleaseBLAWBStateElement = "";

        revenuesReleaseBLAWBStateElement += `<span>${
          this.data.revenuesReleaseBLAWBDate
            ? moment(this.data.revenuesReleaseBLAWBDate).format("DD/MM/YY")
            : "-"
        }</span><br/>`;

        if (this.data.revenuesReleaseBLAWBState) {
          revenuesReleaseBLAWBStateElement +=
            '<span class="text-success mt-3">Enviado</span>';
        } else {
          revenuesReleaseBLAWBStateElement +=
            '<span class="text-danger mt-3">Pendente</span>';
        }

        return `<div class="text-center">${revenuesReleaseBLAWBStateElement}</div>`;
      } else if (this.column == "revenuesStorageState") {
        let revenuesStorageStateElement = "";

        revenuesStorageStateElement += `<span>${
          this.data.revenuesStorageDate
            ? moment(this.data.revenuesStorageDate).format("DD/MM/YY")
            : "-"
        }</span><br/>`;

        if (this.data.revenuesStorageState) {
          revenuesStorageStateElement +=
            '<span class="text-success mt-3">Enviado</span>';
        } else {
          revenuesStorageStateElement +=
            '<span class="text-danger mt-3">Pendente</span>';
        }

        return `<div class="text-center">${revenuesStorageStateElement}</div>`;
      } else if (this.column == "revenuesTransportState") {
        let revenuesTransportStateElement = "";

        revenuesTransportStateElement += `<span>${
          this.data.revenuesTransportDate
            ? moment(this.data.revenuesTransportDate).format("DD/MM/YY")
            : "-"
        }</span><br/>`;

        if (this.data.revenuesTransportState) {
          revenuesTransportStateElement +=
            '<span class="text-success mt-3">Enviado</span>';
        } else {
          revenuesTransportStateElement +=
            '<span class="text-danger mt-3">Pendente</span>';
        }

        return `<div class="text-center">${revenuesTransportStateElement}</div>`;
      } else if (this.column == "revenuesOtherExpenses") {
        if (this.data.revenuesOtherExpenses) {
          let revenuesOtherExpensesElement = "";

          revenuesOtherExpensesElement += `<span>${
            this.data.revenuesOtherExpensesDate
              ? moment(this.data.revenuesOtherExpensesDate).format("DD/MM/YY")
              : "-"
          }</span><br/>`;

          if (this.data.revenuesOtherExpensesState) {
            revenuesOtherExpensesElement +=
              '<span class="text-success mt-3">Enviado</span>';
          } else {
            revenuesOtherExpensesElement +=
              '<span class="text-danger mt-3">Pendente</span>';
          }

          return `<div class="text-center">${revenuesOtherExpensesElement}</div>`;
        }
        return '<div class="tx-center">-</di>';
      } else if (this.column == "typeOfBoarding") {
        if (this.data.wayOfTransport === "MARITIMA") {
          return `<div class="tx-center">${this.data[this.column]}</div>`;
        }
        if (this.data.wayOfTransport === "AEREA") {
          return '<div class="tx-center"><i class="tx-18-force fa fa-plane text-danger"></i></div>';
        }
        if (this.data.wayOfTransport === "RODOVIARIA") {
          return '<div class="tx-center"><i class="tx-16-force fa fa-truck tx-teal"></i></div>';
        }
        if (
          this.data[this.column] === null ||
          this.data[this.column] === "NENHUM"
        ) {
          return '<div class="tx-center"><i class="tx-16-force fa fa-exclamation text-danger"></i></div>';
        }

        return `<div class="tx-center">${this.data[this.column]}</div>`;
      } else if (this.column == "diChannel" && this.data.diChannel) {
        if (this.data.diChannel === "VERDE") {
          return `<span class="tx-center tx-normal uppercase" style="text-align: center; background: #00A068; color: white; padding: 4px; border: 1px; border-radius: 5px;">${this.data.diChannel}</span>`;
        }
        if (this.data.diChannel === "AMARELO") {
          return `<span class="tx-center tx-normal uppercase" style="text-align: center;background: #F49917; color: white; padding: 4px; border: 1px; border-radius: 5px;">${this.data.diChannel}</span>`;
        }
        if (this.data.diChannel === "CINZA") {
          return `<span class="tx-center tx-normal uppercase" style="text-align: center;background: #868ba1; color: white; padding: 4px; border: 1px; border-radius: 5px;">${this.data.diChannel}</span>`;
        }
        if (this.data.diChannel === "VERMELHO") {
          return `<span class="tx-center tx-normal uppercase" style="text-align: center;background: #dc3545; color: white; padding: 4px;  border: 1px; border-radius: 5px;">${this.data.diChannel}</span>`;
        }
      } else if (this.column == "stepInsurance") {
        if (
          (this.data.customer !== null &&
            this.data.customerInsuranceWithPrime) ||
          this.data.stepInsuranceValueResponsability === null ||
          this.data.stepInsuranceValueResponsability === "PRIME"
        ) {
          if (
            this.data.totalAmountInsurance !== null &&
            this.data.totalAmountInsurance > 0
          ) {
            return "OK";
          }
          return "N/I";
        }
        return "OK";
      } else if (
        this.column == "stepCashRequest" &&
        this.data.stepCashRequestSolved
      ) {
        return "OK";
      } else if (
        this.column == "billingCustomBrokerFinishDate" &&
        this.data.billingCustomBrokerFinishDateSolved
      ) {
        return "OK";
      } else if (
        this.column == "originalsDocumentsDate" &&
        this.data.originalsDocumentsDateSolved
      ) {
        return "OK";
      } else if (
        this.column == "originalsBillOfLadingDate" &&
        this.data.originalsBillOfLadingDateSolved
      ) {
        return "OK";
      } else if (
        this.column == "billingNFsDate" &&
        this.data.billingNFsDateSolved
      ) {
        return "OK";
      } else if (
        this.column == "billingBillCustomerDate" &&
        this.data.billingBillCustomerDateSolved
      ) {
        return "OK";
      } else if (
        this.column == "billingEmailDate" &&
        this.data.billingEmailDateSolved
      ) {
        return "OK";
      } else if (
        this.column == "billingCustomBrokerFinishDate" &&
        this.data.billingEmailDateSolved
      ) {
        return "OK";
      } else if (
        this.column == "datesInspectionMAPADate" &&
        this.data.datesInspectionMAPADateSolved
      ) {
        return "OK";
      } else if (
        this.column == "datesReleaseMAPADate" &&
        this.data.datesReleaseMAPADateSolved
      ) {
        return "OK";
      } else if (this.column == "datesDTCDTA") {
        if (this.data.datesDTCDTA) {
          return moment(this.data[this.column]) != "Invalid date"
            ? moment(this.data[this.column]).format("DD/MM/YY")
            : "";
        }
        if (this.data.datesIsThereDTCDTATransfer) {
          return "Sim";
        }
        return "Não";
      } else if (this.column == "datesSiscargaState") {
        if (this.data.datesSiscargaState) {
          return "Liberado";
        }
        return "Pendente";
      } else if (this.column == "step") {
        if (typeof this.data.step === "object") {
          return `<span class="tx-normal uppercase">${this.data.step.description}</span>`;
        }
        return `<span class="tx-normal uppercase">${this.data.step}</span>`;
      } else if (this.column === "customsEnclosureURFEntrance") {
        return this.data[this.column] && this.data[this.column].description
          ? this.data[this.column].description
          : "";
      } else if (this.column === "freightForwarder") {
        if (this.data.forwarder === null || this.data.forwarder === undefined) {
          if (this.data.freightForwarder) {
            return this.data.freightForwarder instanceof Object
              ? `<span class="tx-center tx-normal">${this.data.freightForwarder.name}</span>`
              : `<span class="tx-center tx-normal">${this.data.freightForwarder}</span>`;
          }

          return '<span class="tx-center tx-normal"></span>';
        }

        return `<span class="tx-center tx-normal">${this.data.forwarder}</span>`;

        // return `<span class="tx-center tx-normal">${this.data.freightForwarder.name}</span>`;
      } else if (this.data[this.column] && this.dates.includes(this.column)) {
        if (this.data[this.column] === null || this.data[this.column] === "") {
          return contentDefault;
        }
        return moment(this.data[this.column]) != "Invalid date"
          ? moment(this.data[this.column]).format("DD/MM/YY")
          : "";
      } else if (
        this.data.wayOfTransport === "MARITIMA" &&
        this.data.typeOfBoarding === "FCL" &&
        this.column === "freetime"
      ) {
        var deadline = null;

        if (
          this.data.datesArrivalDate !== null &&
          this.data.freetime !== null
        ) {
          deadline = moment(this.data.datesArrivalDate).add(
            parseInt(this.data.freetime) - 1,
            "day"
          );
        } else if (this.data.datesETA !== null && this.data.freetime !== null) {
          deadline = moment(this.data.datesETA).add(
            parseInt(this.data.freetime) - 1,
            "day"
          );
        }

        if (deadline != null) {
          if (this.expiredDeadline(this.data.containers, deadline)) {
            return `<span data-html="true" data-title='Deadline em ${deadline.format(
              "DD/MM/YY"
            )}'>D</span>`;
          }
          // Comentado pois atualmente a regra não faz sentido
          if (this.deadlineFinished(this.data.containers, deadline)) {
            return `<span data-html="true"  data-title='Deadline em ${deadline.format(
              "DD/MM/YY"
            )}'>OK</span>`;
          }
          return `<span data-html="true"  data-title='Freetime de ${
            this.data.freetime
          } dias'>${deadline.format("DD/MM/YY")}</span>`;
        }

        if (typeof this.data.freightForwarder === "object") {
          return `<span class="text-center tx-normal">${this.data.freightForwarder.name}`
            ? this.data.freightForwarder.name
            : `${this.data.freightForwarder.completeName}</span>`;
        }

        return this.data.forwarder
          ? `<span class="tx-center tx-normal">${this.data.forwarder}</span>`
          : "";
      } else if (this.column === "customer") {
        let content = "";
        if (typeof this.data.customer === "object") {
          content =
            `<span class="text-center tx-normal">${this.data.customer.name}`
              ? this.data.customer.name
              : `${this.data.customer.completeName}</span>`;
        }

        if (typeof this.data.customer === "string") {
          content = `<span class="text-center tx-normal">${this.data.customer}</span>`;
        }

        if (this.data.hasWithoutOperationalTeamAlert) {
          content +=
            ' <span class="text-medium text-danger">Sem time operacional</span>';
        }

        return content;
      } else if (this.column === "customsEnclosure") {
        if (this.data.customsEnclosure === null) {
          return '<span class="tx-center tx-normal"></span>';
        }

        if (typeof this.data.customsEnclosure === "object") {
          if (
            this.data.customsEnclosure.alias &&
            this.data.customsEnclosure.alias !== null &&
            this.data.customsEnclosure.alias !== ""
          ) {
            return `<span class="text-center tx-normal">${this.data.customsEnclosure.alias}</span>`;
          }
          return `<span class="text-center tx-normal">${this.data.customsEnclosure.name}`
            ? this.data.customsEnclosure.name
            : `${this.data.customsEnclosure.completeName}</span>`;
        }

        return `<span class="tx-center tx-normal">${this.data.customsEnclosure}</span>`;
      } else if (this.column === "exporter") {
        if (this.data.exporter === null) {
          return '<span class="tx-center tx-normal"></span>';
        }

        if (typeof this.data.exporter === "object") {
          return `<span class="text-center tx-normal">${this.data.exporter.name}`
            ? this.data.exporter.name
            : `${this.data.exporter.completeName}</span>`;
        }

        return `<span class="text-center tx-normal">${this.data.exporter}</span>`;
      } else if (this.column === "manufacturer") {
        if (this.data.manufacturer === null) {
          return '<span class="tx-center tx-normal"></span>';
        }

        if (typeof this.data.manufacturer === "object") {
          return `<span class="text-center tx-normal">${this.data.manufacturer.name}`
            ? this.data.manufacturer.name
            : `${this.data.manufacturer.completeName}</span>`;
        }

        return `<span class="tx-center tx-normal">${this.data.manufacturer}</span>`;
      } else if (this.column === "generalWarehouse") {
        if (this.data.generalWarehouse === null) {
          return '<span class="tx-center tx-normal"></span>';
        }

        if (typeof this.data.generalWarehouse === "object") {
          return `<span class="text-center tx-normal">${this.data.generalWarehouse.name}`
            ? this.data.generalWarehouse.name
            : `${this.data.generalWarehouse.completeName}</span>`;
        }

        return `<span class="tx-center tx-normal">${this.data.generalWarehouse}</span>`;
      } else if (this.column === "conveyor") {
        if (this.data.conveyor === null) {
          return '<span class="tx-center tx-normal"></span>';
        }

        if (typeof this.data.conveyor === "object") {
          if (
            this.data.conveyor.alias &&
            this.data.conveyor.alias !== null &&
            this.data.conveyor.alias !== ""
          ) {
            return `<span class="text-center tx-normal">${this.data.conveyor.alias}</span>`;
          }
          return `<span class="text-center tx-normal">${this.data.conveyor.name}`
            ? this.data.conveyor.name
            : `${this.data.conveyor.completeName}</span>`;
        }

        return `<span class="tx-center tx-normal">${this.data.conveyor}</span>`;
      } else if (this.column === "expectedDeliveryTime") {
        if (this.data.expectedDeliveryTime) {
          const expectedDeliveryTime = moment(this.data.expectedDeliveryTime);
          const now = moment();
          let expectedDeliveryTimeElement = `${expectedDeliveryTime.format(
            "DD/MM/YY"
          )} - ${this.data.expectedDeliveryTimeDays}`;

          if (
            now.format("Y-MM-DD") === expectedDeliveryTime.format("Y-MM-DD")
          ) {
            expectedDeliveryTimeElement = `<div style="text-align: center; color: #cf7105;">${expectedDeliveryTime.format(
              "DD/MM/YY"
            )} <br/> Vence hoje!</div>`;
          } else if (
            moment(expectedDeliveryTime.format("Y-MM-DD")).isBefore(
              moment(now.format("Y-MM-DD"))
            )
          ) {
            expectedDeliveryTimeElement = `<div style="text-align: center;" class="text-danger">${expectedDeliveryTime.format(
              "DD/MM/YY"
            )} <br/> Venceu a <span style="font-size: 13px;"><strong>${
              this.data.expectedDeliveryTimeDays
            }</strong></span> ${
              this.data.expectedDeliveryTimeDays > 1 ? "dias" : "dia"
            }!</div>`;
          } else if (this.data.expectedDeliveryTimeDays <= -2) {
            expectedDeliveryTimeElement = `<div style="text-align: center;">${expectedDeliveryTime.format(
              "DD/MM/YY"
            )} <br/> Vence em <span style="font-size: 13px;"><strong>${Math.abs(
              this.data.expectedDeliveryTimeDays
            )}</strong></span> dias!</div>`;
          } else if (
            this.data.expectedDeliveryTimeDays == 0 ||
            this.data.expectedDeliveryTimeDays == 1 ||
            this.data.expectedDeliveryTimeDays == -1
          ) {
            expectedDeliveryTimeElement = `<div style="text-align: center;">${expectedDeliveryTime.format(
              "DD/MM/YY"
            )} <br/> Vence amanhã</div>`;
          }

          return expectedDeliveryTimeElement;
        }
        return '<div class="text-center">-</div>';
      } else if (this.column === "billingTerm") {
        return `<div class="text-center">${
          this.data.billingTerm ? `${this.data.billingTerm} dias` : "-"
        }</div>`;
      } else if (this.column === "customBroker") {
        if (this.data.customBroker === null) {
          return '<span class="tx-center tx-normal"></span>';
        }

        if (typeof this.data.customBroker === "object") {
          return `<span class="text-center tx-normal">${this.data.customBroker.name}`
            ? this.data.customBroker.name
            : `${this.data.customBroker.completeName}</span>`;
        }

        return `<span class="tx-center tx-normal">${this.data.customBroker}</span>`;
      } else if (this.objects.includes(this.column)) {
        if (this.column === "account") {
          const content =
            this.data[this.column] && this.data[this.column].federalUnity
              ? this.data[this.column].federalUnity
              : "";
          return `<span class="tx-center tx-normal uppercase">${content}</span>`;
        }
        return this.data[this.column] && this.data[this.column].name
          ? this.data[this.column].name
          : "";
      } else if (this.booleans.includes(this.column)) {
        return this.data[this.column] !== null
          ? this.data[this.column]
            ? "Sim"
            : "Não"
          : "N/I";
      } else if (this.data[this.column] && this.dates.includes(this.column)) {
        if (this.data[this.column] === null || this.data[this.column] === "") {
          return contentDefault;
        }
        return moment(this.data[this.column]) != "Invalid date"
          ? moment(this.data[this.column]).format("DD/MM/YY")
          : "";
      } else if (
        this.data.wayOfTransport === "MARITIMA" &&
        this.data.typeOfBoarding === "FCL" &&
        this.column === "freetime"
      ) {
        var deadline = null;

        if (
          this.data.datesArrivalDate !== null &&
          this.data.freetime !== null
        ) {
          deadline = moment(this.data.datesArrivalDate).add(
            parseInt(this.data.freetime) - 1,
            "day"
          );
        } else if (this.data.datesETA !== null && this.data.freetime !== null) {
          deadline = moment(this.data.datesETA).add(
            parseInt(this.data.freetime) - 1,
            "day"
          );
        }

        if (deadline != null) {
          if (this.expiredDeadline(this.data.containers, deadline)) {
            return `<span data-html="true" data-title='Deadline em ${deadline.format(
              "DD/MM/YY"
            )}'>D</span>`;
          }
          if (this.deadlineFinished(this.data.containers, deadline)) {
            return `<span data-html="true"  data-title='Deadline em ${deadline.format(
              "DD/MM/YY"
            )}'>OK</span>`;
          }
          return `<span data-html="true"  data-title='Freetime de ${
            this.data.freetime
          } dias'>${deadline.format("DD/MM/YY")}</span>`;
        }
      } else if (
        this.data.typeOfBoarding !== "FCL" &&
        this.column === "freetime"
      ) {
        return '<span class="tx-center tx-normal uppercase">N/A</span>';
      } else if (this.column == "customer.isMinimalComissionPercentual") {
        if (this.data.isMinimalComissionPercentual == true) {
          return "Sim";
        }
        if (this.data.isMinimalComissionPercentual == false) {
          return "Não";
        }
      } else if (this.column == "duimp") {
        if (this.data.duimp === true || this.data.duimp === "true") {
          return '<div class="tx-center"><i class="tx-16-force fa fa-check tx-success" style="color: #00A068;!important;"></i></div>';
        } else {
          return '<div class="tx-center"><i class="tx-18-force fa fa-times tx-danger" style="color: #dc3545 !important;"></i></div>';
        }
      } else if (this.column == "cashRequestDone") {
        if (this.data.cashRequestDone === true || this.data.cashRequestDone === "true") {
          return `<div class="tx-center"><span class="tx-center tx-normal uppercase" style="text-align: center; background: #00A068; color: white; padding: 4px; border: 1px; border-radius: 5px;">PAGO</span></div>`;
        } else {
          return `<div class="tx-center"><span class="tx-center tx-normal uppercase" style="text-align: center;background: #dc3545; color: white; padding: 4px;  border: 1px; border-radius: 5px;">PENDENTE</span></div>`;
        }
      }

      return this.data[this.column];
    },
    specialClass() {
      let especialClassContent = " valign-middle pd-x-5 pd-y-10 tx-gray-700";

      if (this.column === "identification") {
        especialClassContent += " tx-center ";

        if (this.data.urgent === true)
          especialClassContent += " tx-white bg-danger op-9";
      } else if (this.column === "status") {
        if (this.data.urgent === true)
          especialClassContent += " tx-white bg-danger op-9";
        else especialClassContent += " tx-center ";
      } else if (this.column === "step") {
        especialClassContent += " tx-center ";
      } else if (
        this.column === "stepCashRequest" &&
        this.data.datesETD !== null &&
        (this.data.stepCashRequest === null || !this.data.stepCashRequest) &&
        (this.data.stepCashRequestSolved === null ||
          this.data.stepCashRequestSolved !== true) &&
        this.data.customer !== null &&
        this.data.customer.numberOfDaysForCashNotification !== null
      ) {
        // Numerário do Cliente
        var date = moment(this.data.datesETD);
        var diff = moment.duration(
          date.diff(moment(moment().startOf("day")), "days", true)
        );

        if (diff <= this.data.customer.numberOfDaysForCashNotification) {
          especialClassContent += " bg-green tx-white tx-bold tx-center";
        }
      } else if (
        this.column === "billingCustomBrokerFinishDate" &&
        this.data.datesGoodsLoadingDate !== null &&
        (this.data.billingCustomBrokerFinishDate === null ||
          !this.data.billingCustomBrokerFinishDate) &&
        (this.data.billingCustomBrokerFinishDateSolved === null ||
          this.data.billingCustomBrokerFinishDateSolved !== true)
      ) {
        // Encerramento Despachante

        var date = moment(this.data.datesGoodsLoadingDate);
        var diff = moment.duration(
          date.diff(moment(moment().startOf("day")), "days", true)
        );

        if (diff >= 3)
          especialClassContent += " bg-orange tx-white tx-bold tx-center";
      } else if (this.column === "datesETA" && this.data.datesETA !== null) {
        // Alertas do ETA
        var date = moment(this.data.datesETA);
        var diff = moment.duration(
          date.diff(moment(moment().startOf("day")), "days", true)
        );

        if (diff > 7 && diff <= 14)
          especialClassContent += " bg-yellow tx-white tx-bold tx-center ";
        // 7 e 14 dias
        else if (diff <= 7 && diff > 0)
          especialClassContent += " bg-red tx-white tx-bold tx-center ";
        // 7 dias
        else if (diff == 0)
          especialClassContent += " bg-purple tx-white tx-bold tx-center "; // Hoje
      } else if (
        this.column === "diNumber" &&
        this.data.datesArrivalDate !== null &&
        (this.data.diNumber === null || !this.data.diNumber) &&
        (this.data.diRegistryDate === null || this.data.diRegistryDate !== true)
      ) {
        // Alertas da DI
        var date = moment(this.data.datesArrivalDate);
        var diff = moment.duration(
          date.diff(moment(moment().startOf("day")), "days", true)
        );

        if (diff <= -2)
          especialClassContent += " bg-orange tx-white tx-bold tx-center ";
      } else if (
        this.column === "datesGoodsLoadingDate" &&
        (this.data.diResourcefulnessDate !== null ||
          this.data.diResourcefulnessDate === true) &&
        (this.data.datesGoodsLoadingDate === null ||
          !this.data.datesGoodsLoadingDate)
      ) {
        // Alerta - Carregamento
        var date = moment(this.data.diResourcefulnessDate);
        var diff = moment.duration(
          date.diff(moment(moment().startOf("day")), "days", true)
        );

        if (diff <= -2)
          especialClassContent += " bg-orange tx-white tx-bold tx-center ";
      } else if (
        this.column === "originalsDocumentsDate" &&
        this.data.datesETA !== null &&
        (this.data.originalsDocumentsDate === null ||
          !this.data.originalsDocumentsDate) &&
        (this.data.originalsDocumentsDateSolved === null ||
          !this.data.originalsDocumentsDateSolved)
      ) {
        // Alerta - Documentos
        var date = moment(this.data.datesETA);
        var diff = moment.duration(
          date.diff(moment(moment().startOf("day")), "days", true)
        );

        if (diff <= 14) {
          especialClassContent += " bg-orange tx-white tx-bold tx-center ";
        }
      } else if (
        this.column === "datesCustomerDeliverDate" &&
        this.data.datesGoodsLoadingDate !== null &&
        (this.data.datesCustomerDeliverDate === null ||
          !this.data.datesCustomerDeliverDate)
      ) {
        // Alerta - Entrega
        var date = moment(this.data.datesGoodsLoadingDate);
        var diff = moment.duration(
          date.diff(moment(moment().startOf("day")), "days", true)
        );

        if (diff < 1)
          especialClassContent += " bg-orange tx-white tx-bold tx-center ";
      } else if (
        (this.column === "datesIsThereDTCDTATransfer" ||
          this.column === "datesDTCDTA") &&
        this.data.datesIsThereDTCDTATransfer !== null &&
        this.data.datesIsThereDTCDTATransfer == true &&
        (this.data.datesDTCDTA === null || !this.data.datesDTCDTA)
      ) {
        // Alerta - DTC / DTA
        especialClassContent += " bg-orange tx-white tx-bold tx-center ";
      }

      if (this.objects.includes(this.column)) {
        especialClassContent += " tx-left ";
      } else if (this.dates.includes(this.column)) {
        especialClassContent += " tx-center ";
      } else if (this.booleans.includes(this.column)) {
        especialClassContent += " tx-center ";
      } else if (
        this.data.wayOfTransport === "MARITIMA" &&
        this.data.typeOfBoarding === "FCL" &&
        this.column === "freetime" &&
        this.data.freetime !== null
      ) {
        var date = null;

        if (
          this.data.datesETA !== null &&
          this.data.datesArrivalDate === null
        ) {
          date = moment(this.data.datesETA).add(
            parseInt(this.data.freetime) - 1,
            "day"
          );
        } else if (this.data.datesArrivalDate !== null) {
          date = moment(this.data.datesArrivalDate).add(
            parseInt(this.data.freetime) - 1,
            "day"
          );
        }

        if (date != null) {
          var diff = moment.duration(
            date.diff(moment(moment().startOf("day")), "days", true)
          );
          if (diff >= 0 && diff <= 5) {
            if (this.deadlineFinished(this.data.containers, date)) {
              especialClassContent += " tx-bold ";
            } else {
              especialClassContent += " bg-yellow tx-white tx-bold ";
            }
          } else if (diff < 0) {
            if (this.expiredDeadline(this.data.containers, date)) {
              especialClassContent += " bg-black x-bold";
            } else if (this.deadlineFinished(this.data.containers, date)) {
              especialClassContent += " tx-bold ";
            } else {
              especialClassContent += " bg-red tx-white tx-bold ";
            }
          }
        }

        especialClassContent += " tx-center ";
      } else if (this.column === "customer.isMinimalComissionPercentual") {
        especialClassContent += " tx-center ";
      }

      return especialClassContent;
    },
    specialStyle() {
      if (this.column == "step") {
        if (typeof this.data.step === "object") {
          return {
            backgroundColor: this.data.step.color,
            color: this.data.step.color === "#000000" ? "#000000" : "#FFFFFF",
          };
        }

        return {
          backgroundColor: this.data.stepColor,
          color: this.data.stepTextColor === "#000000" ? "#000000" : "#FFFFFF",
        };
      }
      return undefined;
    },
  },
  methods: {
    getTypeOfProcessReferenceByProcess(process) {
      return ProcessService.getTypeOfProcessReference(process);
    },
    expiredDeadline(containers, deadline) {
      if (containers.length > 0) {
        const filtered = containers.filter((c) => {
          if (c.devolution !== null && deadline !== null) {
            return (
              moment(deadline).format("DD/MM/YY") !==
                moment(c.devolution).format("DD/MM/YY") &&
              moment(deadline).isBefore(moment(c.devolution), "days")
            );
          }
          return false;
        });

        return filtered.length > 0;
      }
      return false;
    },
    deadlineFinished(containers, deadline) {
      if (containers.length > 0 && deadline !== null) {
        const filtered = containers.filter((c) => {
          if (c.devolution !== null) {
            return moment(c.devolution).isSameOrBefore(
              moment(deadline),
              "days"
            );
          }
          return false;
        });

        return filtered.length > 0;
      }
      return false;
    },
    async action() {
      
      if (this.data !== null && this.data.identification !== null && (this.column == "id" || this.column == "identification")) {
        
        try {
          
          this.$store.commit("setLoading", { show: true, label: `Processo de Importação`, description: "Carregando informações." });

          const findProcessById = await this.$store.dispatch("ProcessStore/findProcessById", this.data.id);
          
          if(findProcessById) {
            
            if(!findProcessById.success) {
              Swal.fire(`Visualização de Processo`, `Não foi possível abrir o processo ${this.data.identification} por causa do seguinte erro: ${response.error}`, `error`);
            }

          } else {

            if (!this.opennedBy) {
              this.socket.send(
                JSON.stringify({
                  action: config.env.WEBSOCKET.actions.OPEN_PROC,
                  data: {
                    [this.data.id]: this.credentials.data.person.name,
                  },
                })
              );
            }

          }
          
        } catch (error) {
          Swal.fire(`Visualização de Processo`, `Não foi possível abrir o processo ${this.data.identification} por causa do seguinte erro: ${error}`, `error`);
        }
      }

    },
    async edit() {
      
      if (this.opennedBy !== undefined && this.opennedBy !== null) {
        Swal.fire(
          "Processo Bloqueado!",
          `No momento este processo está sendo editado por <strong>${this.opennedBy}</strong>, aguarde este usuário finalizar para que você possar editar.`,
          "error"
        );
      } else if (this.fieldInfo.editableInDesk) {
        let html = "";
        html = `<label class="tx-left">Atualizando  o campo ${this.fieldInfo.label}:</label>`;

        let data = this.data[this.column];

        if (this.fieldInfo.type === "date") {
          // data = this.$moment(data).format("Y-MM-DD");
          html += `<input id="swal-input1" class="swal2-input" value="${data.split('T')[0]}" type="date">`;
        } else if (this.fieldInfo.type === "checkbox_solved") {
          html += `<input id="swal-input1" class="swal2-input" value="${data}">`;
        } else if (this.fieldInfo.type === "yes_or_no") {
          html += `<select id="swal-input1" class="swal2-input">
                      <option value="true" ${data === 'true' || data === true ? 'selected' : ''}>Sim</option>
                      <option value="false" ${data === 'false' || data === false ? 'selected' : ''}>Não</option>
                    </select>`;
        } else {
          html += `<input id="swal-input1" class="swal2-input" value="${data}">`;
        }

        const { value: formValues } = await Swal.fire({
          title: `Atualização do Processo Nº ${this.data.identification}`,
          html,
          // + '<input id="swal-input2" class="swal2-input">',
          focusConfirm: false,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Salvar",
          preConfirm: () => document.getElementById("swal-input1").value,
        });

        if (formValues) {
          let newValue = formValues;
          const process = this.processes.find(
            (process) => process.id === this.data.id
          );
          let oldValue = this.data[this.column];

          if (this.fieldInfo.type === "date") {
            newValue = `${newValue}T03:00:00`;
          }

          if (process !== null) {
            process[this.column] = newValue;

            Axios.patch(
              `${config.env.API_DEFAULT.host}/importProcesses/${process.id}`,
              {
                [this.column]: newValue,
              }
            )
              .then((response) => {
                if (response.status === 200) {
                  this.socket.send(
                    JSON.stringify({
                      action: config.env.WEBSOCKET.actions.UPDATE_PROC,
                      data: {
                        [process.id]: {
                          [this.column]: newValue,
                        },
                      },
                    })
                  );

                  if (this.fieldInfo.type === "date") {
                    let msgNewValue = this.$moment(newValue).format("DD/MM/Y");
                    let msgOldValue = this.$moment(oldValue).format("DD/MM/Y");

                    Axios.post(config.env.API_DEFAULT.host + '/newImportProcess/' + process.id + '/pull-trigger/' + this.column).then((response2) => {
                      
                        Vue.toasted.success(response2.data.message, { position: "top-right", duration: 6000 });

                    }).catch((errorResponse2) => {
                      
                      if (errorResponse2.response !== undefined) {
                          Vue.toasted.error(errorResponse2.response.data.message, { position: "top-right", duration: 6000 });
                      } else {
                          console.error('Erro -> Pull Trigger', errorResponse2);
                      }

                    });

                    Swal.fire(
                      `Atualização do Processo Nº ${this.data.identification}`,
                      `${this.fieldInfo.label} foi atualizado de <strong>${msgOldValue}</strong> para <strong>${msgNewValue}</strong>.`,
                      "success"
                    );
                  } else if(this.fieldInfo.type === "yes_or_no"){

                    let msgNewValue = newValue === 'true' || newValue === true ? 'Sim' : 'Não';
                    let msgOldValue = oldValue === 'true' || oldValue === true ? 'Sim' : 'Não';

                    Swal.fire(
                      `Atualização do Processo Nº ${this.data.identification}`,
                      `${this.fieldInfo.label} foi atualizado de <strong>${msgOldValue}</strong> para <strong>${msgNewValue}</strong>.`,
                      "success"
                    );

                  } else {


                    Swal.fire(
                      `Atualização do Processo Nº ${this.data.identification}`,
                      `${this.fieldInfo.label} foi atualizado de <strong>${oldValue}</strong> para <strong>${newValue}</strong>.`,
                      "success"
                    );
                  }

                  // try {

                  //     if (this.fieldInfo.type === 'date') {
                  //         newValue = this.$moment(newValue).format("Y-MM-DD") + "T03:00:00";
                  //     }

                  // } catch (error) {
                  //     Swal.fire('Notificação', "Não foi possível notificar os usuário do Gescomex sobre a atualização no campo por causa do seguinte erro: " + error, 'error');
                  // }
                }
              })
              .catch((error) => {
                Swal.fire(
                  "Atualização do Processo Nº ",
                  `Não foi possível atualizar o campo  ${this.fieldInfo.label} de <strong>${oldValue}</strong> para <strong>${formValues}</strong>. <br /><br />${error.response.data.message}`,
                  "error"
                );
              });
          }
        }
      } else {
        Swal.fire(
          `Atualização do Processo Nº ${this.data.identification}`,
          `O campo <strong>${this.fieldInfo.label}</strong> não está habilitado para ser editado.`,
          "warning"
        );
      }
    },
  },
};
</script>

<style>
@import "../../../assets/css/desk.css";
</style>

<style lang="scss" scoped>
td {
  &:hover {
    cursor: default;
    background: #f4f4f4;
  }

  &.btn-dribbble {
    background-color: #800080;
    color: #fff;
  }

  &.btn-red {
    background-color: #dc3545;
    color: #fff;
  }

  &.btn-green {
    background-color: #23bf08;
    color: #fff;
  }

  &.btn-yellow {
    background-color: #f49917;
    color: #fff;
  }

  &.btn-sky {
    background-color: #77c4e2;
    color: #fff;
  }

  &.btn-redlight {
    background-color: #ff5f5f;
    color: #fff;
  }

  &.btn-orange {
    background-color: #ff4500;
    color: #fff;
  }

  &.btn-slategray {
    background-color: #6c757d;
    color: #fff;
  }
}
</style>
