import Axios from "axios";
import config from "@/config.js";
import Swal from "sweetalert2";
import { EventBus } from "@/events/EventBus.js";
import { validatePatchImportProcess } from "@/lists/import-process-fields-validation";
import { handleSocketMessage, handleSocketError } from "@/utils/webSocket";
import { labels } from "@/labels/labels.processes";
import Vue from "vue";

const ProcessStore = {
	namespaced: true,
	state: {
		processes: [],
		processesByIdentification: [],
		allProcesses: [],
		activeProcess: null, //processo sendo editado no momento,
		showProcess: false,
		noClick: false,
		steps: [],
		stateSteps: [],
		stateColumns: null,
		coins: [],
		documents: [],
		documentsTypes: [],
		hasTFADocument: false,
		importFields: [],
		optionsCompanies: [],
		optionsTeams: [],
		optionsExporters: [],
		optionsCustomers: [],
		optionsCustomersBrokers: [],
		optionsForwarders: [],
		optionsShipowners: [],
		optionsUsers: [],
		optionsAccounts: [],
		optionsReceitaFederalUnits: [],
		optionsCustomsEnclosures: [],
		freezedImportProcess: [],
		radarInfo: null,
		freightQuotationsResponses: {
			freightQuotations: [],
		},
		processesFilterIds: "",
		stepControlPanelFilter: 0,
		openedProcesses: {},
		socket: null,
		canConclude: false,
	},

	mutations: {
		SET_NOCLICK(state, payload) {
			state.noClick = payload;
		},
		SET_RADAR_INFO(state, payload) {
			state.radarInfo = payload;
		},
		SET_PROCESSES(state, payload) {
			state.processes = payload;
			// state.processes = Object.freeze(payload)
		},
		SET_STATE_STEPS(state, payload) {
			state.stateSteps = payload;
		},
		SET_STATE_COLUMNS(state, payload) {
			state.stateColumns = payload;
		},
		SET_PROCESSES_BY_INDETIFICATION(state, payload) {
			state.processesByIdentification = Object.freeze(payload);
		},
		SET_COMPANIES(state, payload) {
			state.optionsCompanies = payload;
		},
		SET_TEAMS(state, payload) {
			state.optionsTeams = payload;
		},
		SET_CUSTOMERS(state, payload) {
			state.optionsCustomers = payload;
		},
		SET_CUSTOM_BROKER(state, payload) {
			state.optionsCustomersBrokers = payload;
		},
		SET_EXPORTERS(state, payload) {
			state.optionsExporters = payload;
		},
		SET_FORWARDERS(state, payload) {
			state.optionsForwarders = payload;
		},
		SET_SHIPOWNER(state, payload) {
			state.optionsShipowners = payload;
		},
		SET_USERS(state, payload) {
			state.optionsUsers = payload;
		},
		SET_ACCOUNTS(state, payload) {
			state.optionsAccounts = payload;
		},
		SET_FEDERAL_UNITS(state, payload) {
			state.optionsReceitaFederalUnits = payload;
		},
		SET_CUSTOM_ENCLOSURES(state, payload) {
			state.optionsCustomsEnclosures = payload;
		},
		SET_SINGLE_PROCESS(state, payload) {
			const updatedProcesses = [...state.processes];
			const index = updatedProcesses.findIndex((item) => item.id == payload.id);
			updatedProcesses[index] = payload;
			state.processes = Object.freeze(updatedProcesses);
		},
		SET_UPDATE_FIELD_PROCESS(state, payload) {
			if (state.processes !== null && state.processes.length > 0) {
				const updatedProcesses = [...state.processes];
				const index = updatedProcesses.findIndex(
					(item) => item.id == payload.id
				);
				if (updatedProcesses[index][payload.field] !== payload.value)
					updatedProcesses[index][payload.field] = payload.value;
				state.processes = Object.freeze(updatedProcesses);
			}

			if (
				state.activeProcess !== null &&
				state.activeProcess.id === payload.id
			) {
				if (state.activeProcess[payload.field] !== payload.value)
					state.activeProcess[payload.field] = payload.value;
			}
		},
		SET_STEPS(state, payload) {
			state.steps = payload;
		},
		SET_HAS_TFA_DOCUMENT(state, payload) {
			state.hasTFADocument = payload;
		},
		SET_DOCUMENTS_TYPES(state, payload) {
			state.documentsTypes = payload;
		},
		SET_DOCUMENTS(state, payload) {
			state.documents = payload;
		},
		SET_IMPORT_FIELDS(state, payload) {
			state.importFields = payload;
		},
		SET_FREEZED_IMPORT_PROCESSES(state, payload) {
			state.freezedImportProcess = payload;
		},
		SET_CAN_CONCLUDE(state, payload) {
			state.canConclude = payload;
		},
		SET_ACTIVE(state, payload) {

			if (payload != {} && payload !== null && !payload.revenuesReleaseBLAWBTotalUsd) {
				payload.revenuesReleaseBLAWBTotalUsd = 0;
			}

			if (payload != {} && payload !== null && !payload.revenuesReleaseBLAWBTotalUsdRate) {
				payload.revenuesReleaseBLAWBTotalUsdRate = 0;
			}

			if (
				payload != {} &&
				payload !== null &&
				!payload.revenuesReleaseBLAWBTotalUsdRateReal
			)
				payload.revenuesReleaseBLAWBTotalUsdRateReal = 0;

			if (
				payload != {} &&
				payload !== null &&
				!payload.revenuesReleaseBLAWBTotalOtherCoin
			)
				payload.revenuesReleaseBLAWBTotalOtherCoin = 0;
			if (
				payload != {} &&
				payload !== null &&
				!payload.revenuesReleaseBLAWBTotalOtherCoinRate
			)
				payload.revenuesReleaseBLAWBTotalOtherCoinRate = 0;
			if (
				payload != {} &&
				payload !== null &&
				!payload.revenuesReleaseBLAWBTotalOtherCoinRateReal
			)
				payload.revenuesReleaseBLAWBTotalOtherCoinRateReal = 0;

			if (
				payload != {} &&
				payload !== null &&
				!payload.revenuesReleaseBLAWBTotalRealCoin
			)
				payload.revenuesReleaseBLAWBTotalRealCoin = 0;
			if (
				payload != {} &&
				payload !== null &&
				!payload.revenuesReleaseBLAWBTotalGeneralRealCoin
			)
				payload.revenuerevenuesReleaseBLAWBTotalGeneralRealCoinsReleaseBLAWBTotalRealCoin = 0;

			if (payload != {} && payload !== null && !payload.revenuesRoadValueOfTransportation) {
				payload.revenuesRoadValueOfTransportation = 0;
			}

			if (payload != {} && payload !== null && !payload.revenuesRoadValueOfTransportationAdValorem) {
				payload.revenuesRoadValueOfTransportationAdValorem = 0;
			}

			if (payload != {} && payload !== null && !payload.revenuesRoadValueOfTransportationNF) {
				payload.revenuesRoadValueOfTransportationNF = 0;
			}

			if (payload != {} && payload !== null && !payload.revenuesRoadOtherValues) {
				payload.revenuesRoadOtherValues = 0.00;
			}

			if (payload != {} && payload !== null && !payload.revenuesRoadTotal) {
				payload.revenuesRoadTotal = 0.00;
			}

			if (payload != {} && payload !== null && !payload.stepInsuranceValue) {
				payload.stepInsuranceValue = 0.00;
			}

			if (payload != {} && payload !== null && !payload.invoiceTotal) {
				payload.invoiceTotal = 0.00;
			}

			if (payload != {} && payload !== null && !payload.cifValue) {
				payload.cifValue = 0.00;
			}

			if (payload != {} && payload !== null && !payload.billingOutgoingNFTotalValue) {
				payload.billingOutgoingNFTotalValue = 0.00;
			}

			if (payload != {} && payload !== null && !payload.estimatedStepCashRequestEstimatedValue) {
				payload.estimatedStepCashRequestEstimatedValue = 0.00;
			}

			if (payload != {} && payload !== null && !payload.stepCashRequestEstimatedValue) {
				payload.stepCashRequestEstimatedValue = 0.00;
			}

			state.activeProcess = payload;
			state.showProcess = true;
		},
		HIDE_PROCESS(state) {
			state.showProcess = false;
			state.activeProcess = null;
		},
		NEW_PROCESS(state) {
			state.activeProcess = {
				id: null,
				identification: null,
				step: {
					alias: null
				},
				status: null,
				statusDateOfLastModify: null,
				statusCustomBrokerDateOfLastModify: null,
			};
			state.showProcess = true;
		},
		CLEAR_STATE(state) {
			state.processes = [];
			state.activeProcess = null;
			state.showProcess = false;
			state.steps = [];
		},
		SET_COINS(state, payload) {
			state.coins = payload;
		},
		SET_FILTERED_PROCESSES(state, payload) {

			if (payload.length == 0 && state.allProcesses.length > 0) {
				// se o payload for pra limpar tudo, reseta pra listar todos processos
				state.processes = [...state.allProcesses];
				state.allProcesses = [];
			} else if (payload.length > 0) {
				// se o payload tiver alguma coisa
				if (state.allProcesses.length == 0) {
					state.allProcesses = [...state.processes];
				}
				state.processes = payload;
			}
		},
		SET_FREIGHT_QUOTATIONS_RESPONSES(state, payload) {
			state.freightQuotationsResponses = payload;
		},
		SET_PROCESSES_FILTER_IDS(state, payload) {
			state.processesFilterIds = payload;
		},
	},
	actions: {
		connectWs({ state, dispatch, rootState }) {
			state.socket = new WebSocket(config.env.WEBSOCKET.host);
			state.socket.addEventListener("message", (event) => handleSocketMessage(event, state));
			state.socket.addEventListener("error", (event) => handleSocketError(event, state));
			state.socket.addEventListener("close", () => dispatch("handleSocketClose")); 
			state.socket.onopen = () => {
				const heartbeatInterval = 30 * 1000;
				setInterval(function() {
					if (state.socket.readyState === 1) {
						state.socket.send(
							JSON.stringify({
								action: config.env.WEBSOCKET.actions.OPEN_PROC,
								data: {},		
							})
						);
					}
				}, heartbeatInterval);
			};
		},
		async handleSocketClose({ dispatch }) {
			// console.log('Websocket fechado');
			// console.log('state::', state);
			setTimeout(() => {
				dispatch("connectWs");
			}, 5000);
		},
		enableNoClick({ commit }, flag) {
			commit('SET_NOCLICK', flag);
		},
		setProcessesFilterIds({ commit }, payload) {
			commit('SET_PROCESSES_FILTER_IDS', payload.processesIds);
		},
		async verifyRadarIsChecked({ commit, state, dispatch }, search) {

			commit('setLoading', {
				show: true,
				label: `Checando Radar`,
				description: 'Aguarde...'
			}, { root: true });

			Axios.get(config.env.API_DEFAULT.host + "/process/import/verifyRadarIsChecked/" + state.activeProcess.id).then((result) => {
				let data = result.data.data;
				commit("SET_RADAR_INFO", {
					radarModality: {
						key: data.radarModality,
						modality: data.radarModalityLabel
					},
					balance: data.balance,
					radarModalityIsNotDefined: data.radarModalityIsNotDefined,
					checked: data.radarModality === 'ILIMITED' ? true : data.radarChecked,
				});
				commit('setLoading', { show: false }, { root: true });

				if (!data.radarChecked) {
					let message = "<strong>" + data.customerName + "</strong>" +
						" tem radar na modalidade " +
						"<strong>" + data.radarModalityLabel + "</strong>" +
						" e possui um saldo no valor de " +
						" <strong>USD " + data.balance + "</strong>";

					if (data.radarModalityIsNotDefined) {
						Swal.fire({
							title: "Alerta de Radar",
							html: "O cliente " + data.customerName + " está sem modalidade de radar definida, por conta disso, não será possível editar os dados deste processo. <br/><br/> Selecione uma das modalidade abaixo:",
							type: "warning",
							input: "select",
							inputOptions: {
								'EXPRESS': 'Expresso',
								'LIMITED_50': 'Limitado USD 50k',
								'LIMITED': 'Limitado USD 150k',
								'ILIMITED': 'Ilimitado'
							},
							inputPlaceholder: 'Selecione',
							inputValidator: (value) => {
								return new Promise((resolve) => {
									if (value === null || value === "") {
										dispatch("verifyRadarIsChecked");
									} else {
										Axios.patch(
											config.env.API_DEFAULT.host + "/companies/" + state.activeProcess.customer.id, { salesRadarModality: value }
										).then((response) => {
											commit("SET_ACTIVE", { ...state.activeProcess, customer: response.data });
											commit("SET_SINGLE_PROCESS", {
												...state.activeProcess,
												customer: response.data
											});
											Swal.fire("Alerta de Radar", "A modalidade do radar foi atualizada com sucesso!", "success");
										}).catch((error) => {
											Swal.fire("Alerta de Radar", "Não foi possível alterar a modalidade do radar! " + error, "error");
										});
										resolve();
									}
								})
							}
						})
					} else {
						Swal.fire({
							title: "Alerta de Radar",
							type: "warning",
							html: message,
							showConfirmButton: true,
							showCancelButton: true,
							confirmButtonText: "Marcar como verificado",
							cancelButtonText: "Verificar depois"
						}).then((result) => {
							if (result.value) {
								commit('setLoading', {
									show: true,
									label: `Checando Radar`,
									description: 'aguarde...'
								}, { root: true });
								Axios.get(config.env.API_DEFAULT.host + "/process/import/checkRadar/" + state.activeProcess.id).then((result) => {
									if (result.data.code === 'SUCCESS') {
										EventBus.$emit("Operational.imp.Form.Sidebar.Timeline.load");
										commit('setLoading', { show: false }, { root: true });
										commit("SET_RADAR_INFO", null);
										Swal.fire("Radar Verificado", "Este processo agora está com o Radar Verificado!", "success")
									}

								});
							}
						});
					}
				}
			}).catch((e) => {
				Swal.fire("Alerta de Radar", e.response.data.message, "error");
			}).finally(() => {
				commit('setLoading', { show: false }, { root: true });
			})
		},
		async getCompletedProcesses({ commit }) {
			try {
				commit("SET_FILTERED_PROCESSES", []);
				commit("SET_PROCESSES", response.data.data);
			} catch (error) {
				return {
					success: false,
					error: error,
				};
			}
		},
		setCanConclude({ commit }, payload) {
			commit('SET_CAN_CONCLUDE', payload)
		},
		async getProcesses({ commit, state }, { steps, columns, dateField, startDate, endDate, teamsIds, customsBrokersIds }) {
			if (columns !== null && columns !== '') {
				const queryString = window.location.search;
				const urlParams = new URLSearchParams(queryString);

				const processesIds = urlParams.get('processesIds');

				try {
					var url = config.env.API_DEFAULT.host + `/process/import/desk/search`;
					if (state.stateSteps !== '' || state.stateSteps !== null || state.stateSteps !== undefined || state.stateSteps.length > 0) {
						if (state.stateSteps.includes(6)) {
							steps = steps.filter(step => step !== 6);
						}

						if (urlParams.get('indicatorProcess') !== null && urlParams.get('indicatorProcess')) {
							url =
								config.env.API_DEFAULT.host +
								`/process/import/desk/search?stepsIds=${(state.stateSteps && state.stateSteps != '' && state.stateSteps != undefined) ? state.stateSteps : urlParams.get('stepsIds')}`;
						} else {
							url =
								config.env.API_DEFAULT.host +
								// `/process/import/desk/search?stepsIds=${steps}`;
								`/newImportProcess?steps=${state.stateSteps}&columns=${columns}&dateField=${dateField}&startDate=${startDate}&endDate=${endDate}&teamsIds=${teamsIds}&customsBrokersIds=${customsBrokersIds}`;
						}


						if (processesIds !== null && processesIds !== '' && processesIds !== 'undefined') {
							// alert(this.processesFilterIds);
							url = url + `&processesIds=${processesIds}`;
						}

					}

					const response = await Axios.post(url, {
						from: null,
						to: null,
						field: null,
						steps: [],
					});

					commit("SET_FILTERED_PROCESSES", []);
					commit("SET_PROCESSES", response.data.data);
					return {
						success: true,
						message: response.data.message,
					};
				} catch (error) {
					return {
						success: false,
						error: error,
					};
				}
			}
		},
		async findImportProcessesByKeyword({ commit }, identification) {
			if (
				identification != null &&
				identification != undefined &&
				identification != ""
			) {
				commit(
					"setLoading", {
					show: true,
					label: "Consultando Processos",
					description: "Aguarde enquanto o termo " +
						identification +
						" está sendo pesquisado...",
				}, {
					root: true,
				}
				);

				try {
					const response = await Axios.get(
						config.env.API_DEFAULT.host +
						// `/process/import/search?q=${identification}`
						`/newImportProcess/general-search?search=${identification}`
					);

					if (response.status === 200 || response.status === "200") {
						if (response.data.data !== null && response.data.data.length > 0) {
							commit("SET_PROCESSES", []);
						}

						commit(
							"setLoading", {
							show: false,
						}, {
							root: true,
						}
						);
						return {
							success: true,
							message: "Foram encontrados " +
								response.data.length +
								" processos na pesquisa.",
							data: response.data,
						};
					} else if (response.status === 204 || response.status === "204") {
						commit(
							"setLoading", {
							show: false,
						}, {
							root: true,
						}
						);
						return {
							success: false,
							message: "O Processo de Importação Nº <b>" +
								identification +
								"</b> não foi encontrado.",
						};
					}
				} catch (error) {
					commit(
						"setLoading", {
						show: false,
					}, {
						root: true,
					}
					);
					return {
						success: false,
						error: error,
					};
				}
			}
		},
		async findProcessById({ commit, state, rootState }, id) {
			commit('setLoading', {
				show: true,
				label: 'Consultando Processo',
				description: 'Aguarde enquanto o processo selecionado está sendo consultado...',
			}, { root: true, });

			try {
				const response = await Axios.get(config.env.API_DEFAULT.host + `/process/import/findProcessById/${id}`);

				if (state.activeProcess) {
					state.socket.send(
						JSON.stringify({
							action: config.env.WEBSOCKET.actions.CLOSE_PROC,
							data: {
								[state.activeProcess.id]: rootState.credentials.data.person.name,
							},
						})
					);
				}

				commit('SET_ACTIVE', response.data.data);

				if (state.openedProcesses[response.data.data.id] && state.openedProcesses[response.data.data.id].user != rootState.credentials.data.person.name) {
                    commit('SET_NOCLICK', true);
                } else {
					state.socket.send(
						JSON.stringify({
							action: config.env.WEBSOCKET.actions.OPEN_PROC,
							data: {
								[response.data.data.id]: rootState.credentials.data.person.name,
							},
						})
					);
                }

				return { success: true };
			} catch (error) {
				commit('setLoading', { show: false, label: '', description: '', }, { root: true, });
				return { success: false, error: error };
			}
		},
		async findProcessByIdentification({ commit, state, rootState }, identification) {
			commit(
				"setLoading", {
				show: true,
				label: "Consultando Processo",
				description: "Aguarde enquanto o processo " +
					identification +
					" está sendo consultado...",
			}, {
				root: true,
			}
			);

			try {
				const response = await Axios.get(
					config.env.API_DEFAULT.host +
					`/process/import/findProcessByIdentification/${identification}`
				);

				if (response.status === 200 || response.status === "200") {

					if (response.data.data.length > 1) {
						commit('setLoading', { show: false, label: '', description: '', }, { root: true, });

						const processesOptions = {};

						const typeOfProcess = {
							['CONTA_E_ORDEM']: 'Conta e Ordem',
							['ASSESSORIA']: 'Assessoria',
							['ENCOMENDA']: 'Encomenda'
						};

						response.data.data.forEach(process => {
							processesOptions[process.id] = typeOfProcess[process.typeOfProcess] !== undefined ? typeOfProcess[process.typeOfProcess] : 'Conta e Ordem';
						});

						const { value: processSelected } = await Swal.fire({
							type: 'info',
							title: 'Selecione o processo',
							html: '<p> Encontramos mais de um processo com esta referência. Por favor, escolha o tipo de processo que deseja visualizar: </p>',
							input: 'radio',
							inputOptions: processesOptions,
							confirmButtonText: 'Acessar',
							inputValidator: (value) => {
								if (!value) {
									return 'Escolha um tipo de processo entre as opções acima!'
								}
							}
						})

						if (processSelected) {
							commit("SET_ACTIVE", response.data.data.filter(process => process.id == processSelected)[0]);
							commit("setLoading", {
								show: false,
								label: "",
								description: "",
							}, {
								root: true,
							});
							if (state.openedProcesses[processSelected] && state.openedProcesses[processSelected].user != rootState.credentials.data.person.name) {
								commit('SET_NOCLICK', true);
							}
							return {
								success: true,
								message: "Processo de Importação foi encontrado.",
								data: response.data.data.filter(process => process.id == processSelected)[0],
							};
						}

					} else if (response.data.data.length == 1) {
						commit("SET_ACTIVE", response.data.data[0]);
						commit(
							"setLoading", {
							show: false,
							label: "",
							description: "",
						}, {
							root: true,
						}
						);
						if (state.openedProcesses[response.data.data[0].id] && state.openedProcesses[response.data.data[0].id].user != rootState.credentials.data.person.name) {
							commit('SET_NOCLICK', true);
						}
						return {
							success: true,
							message: "Processo de Importação foi encontrado.",
							data: response.data.data[0],
						};
					} else if (response.status === 204 || response.status === "204") {
						commit(
							"setLoading", {
							show: false,
							label: "",
							description: "",
						}, {
							root: true,
						}
						);
						return {
							success: false,
							message: "O Processo de Importação Nº <b>" +
								identification +
								"</b> não foi encontrado.",
						};
					}

				} else {
					commit("SET_ACTIVE", response.data.data[0]);
					commit(
						"setLoading", {
						show: false,
						label: "",
						description: "",
					}, {
						root: true,
					}
					);
					return {
						success: true,
						message: "Processo de Importação foi encontrado.",
						data: response.data,
					};
				}


			} catch (error) {
				commit(
					"setLoading", {
					show: false,
					label: "",
					description: "",
				}, {
					root: true,
				}
				);
				return {
					success: false,
					error: error,
				};
			}
		},
		setStep({ commit }, payload) {
			commit("SET_STEPS", [payload.step]);
		},
		async getSteps({ commit }) {
			try {
				const response = await Axios.get(
					config.env.API_DEFAULT.host +
					"/processSteps/search/findAllByOrderByPositionAsc"
				);
				if (response.data._embedded.processSteps) {
					let steps = [...response.data._embedded.processSteps].sort((a, b) =>
						a.position && b.position ? a.position.localeCompare(b.position) : -1
					);
					commit("SET_STEPS", steps);
					return {
						success: true,
					};
				} else {
					return {
						success: false,
						error: "Não foi possível baixar os dados dos processos.",
					};
				}
			} catch (error) {
				console.error(error);
				return {
					success: false,
					error,
				};
			}
		},
		async getCompanies({ commit }, search) {
			try {
				const response = await Axios.get(
					config.env.API_DEFAULT.host +
					"/companies/search/findAllByNameContainingIgnoreCase?name=" +
					search
				);
				commit("SET_COMPANIES", response.data._embedded.companies);
				return true;
			} catch (error) {
				return false;
			}
		},
		async getTeams({ commit }, search) {

			try {
				const response = await Axios.get(`${config.env.API_DEFAULT.host}/teams/search/findAllByEnabledAndNameContainingIgnoreCase?enabled=true&name=${search}`);

				commit("SET_TEAMS", response.data._embedded.teams);
				return true;
			} catch (error) {
				return false;
			}
		},
		async getCustomers({ commit }, search) {
			try {
				const response = await Axios.get(
					config.env.API_DEFAULT.host +
					"/companies/search/findAllByIsCustomerAndNameContainingIgnoreCase?isCustomer=true&name=" +
					search
				);
				commit("SET_CUSTOMERS", response.data._embedded.companies.filter(company => company.enabled));
				return true;
			} catch (error) {
				return false;
			}
		},
		async getCustomersBrokers({ commit }, search) {
			try {
				const response = await Axios.get(
					config.env.API_DEFAULT.host +
					"/companies/search/findAllByIsCustomBrokerAndNameContainingIgnoreCase?isCustomBroker=true&name=" +
					search
				);
				commit("SET_CUSTOM_BROKER", response.data._embedded.companies);
				return true;
			} catch (error) {
				return false;
			}
		},
		async getExporters({ commit }, search) {
			try {
				const response = await Axios.get(
					config.env.API_DEFAULT.host +
					"/companies/search/findAllByIsExporterAndNameContainingIgnoreCase?isExporter=true&name=" +
					search
				);
				commit("SET_EXPORTERS", response.data._embedded.companies);
				return true;
			} catch (error) {
				return false;
			}
		},
		async getForwarders({ commit }, search) {
			try {
				const response = await Axios.get(
					config.env.API_DEFAULT.host +
					"/companies/search/findAllByIsForwarderAndNameContainingIgnoreCase?isForwarder=true&name=" +
					search
				);
				commit("SET_FORWARDERS", response.data._embedded.companies);
				return true;
			} catch (error) {
				return false;
			}
		},
		async getShipowners({ commit }, search) {
			try {
				const response = await Axios.get(
					config.env.API_DEFAULT.host +
					"/companies/search/findAllByIsShipownerAndNameContainingIgnoreCase?isShipowner=true&name=" +
					search
				);
				commit("SET_SHIPOWNER", response.data._embedded.companies);
				return true;
			} catch (error) {
				return false;
			}
		},
		async getStateSteps({ commit, state }) {
			return state.stateSteps;
		},
		async getStateColumns({ state }) {
			return state.stateColumns;
		},
		async getImportProcessesByIdentification({ commit }, search) {
			try {
				const response = await Axios.get(
					config.env.API_DEFAULT.host +
					"/importProcesses/search/findAllByIdentificationContaining?identification=" +
					search
				);
				commit(
					"SET_PROCESSES_BY_INDETIFICATION",
					response.data._embedded.importProcesses
				);
				return true;
			} catch (error) {
				return false;
			}
		},
		async getUsers({ commit }, search) {
			try {
				const response = await Axios.get(
					config.env.API_DEFAULT.host +
					"/users/search/findAllByNameContainingIgnoreCase?name=" +
					search
				);
				commit("SET_USERS", response.data._embedded.users);
				return true;
			} catch (error) {
				return false;
			}
		},
		async getAccounts({ commit }, search) {
			try {
				const response = await Axios.get(
					config.env.API_DEFAULT.host +
					"/accounts/search/findAllByNameContainingIgnoreCase?name=" +
					search
				);
				commit("SET_ACCOUNTS", response.data._embedded.accounts);
				return true;
			} catch (error) {
				return false;
			}
		},
		async getReceitaFederalUnits({ commit }, search) {
			try {
				commit(
					"setLoading", {
					show: true,
					label: "Consultando Recinto",
					description: "Aguarde sua requisição está sendo processada...",
				}, {
					root: true,
				}
				);
				const response = await Axios.get(
					config.env.API_DEFAULT.host +
					"/customsEnclosures/search/findAllCustomsEnclosureURFByDescription?description=" +
					search
				);
				commit("SET_FEDERAL_UNITS", response.data._embedded.customsEnclosures);
				commit(
					"setLoading", {
					show: false,
				}, {
					root: true,
				}
				);
				return true;
			} catch (error) {
				commit(
					"setLoading", {
					show: false,
				}, {
					root: true,
				}
				);
				return false;
			}
		},
		async getCustomsEnclosures({ commit }, search) {
			try {
				commit(
					"setLoading", {
					show: true,
					label: "Consultando Recinto",
					description: "Aguarde sua requisição está sendo processada...",
				}, {
					root: true,
				}
				);
				const response = await Axios.get(
					config.env.API_DEFAULT.host +
					"/customsEnclosures/search/findAllCustomsEnclosureByDescription?description=" +
					search
				);
				commit(
					"SET_CUSTOM_ENCLOSURES",
					response.data._embedded.customsEnclosures
				);
				commit(
					"setLoading", {
					show: false,
				}, {
					root: true,
				}
				);
				return response.data._embedded.customsEnclosures;
			} catch (error) {
				commit(
					"setLoading", {
					show: false,
				}, {
					root: true,
				}
				);
				return false;
			}
		},
		async getDocumentsType({ commit }) {
			try {
				const response = await Axios.get(
					config.env.API_DEFAULT.host +
					`/movimentTerms/search/findAllByType?type=DOCUMENT`
				);
				if (response.data._embedded.movimentTerms) {
					let documents = [...response.data._embedded.movimentTerms];
					commit("SET_DOCUMENTS_TYPES", documents);
					return {
						success: true,
					};
				} else {
					return {
						success: false,
						error: "Não foi possível baixar os dados de documentos.",
					};
				}
			} catch (error) {
				return {
					success: false,
					error,
				};
			}
		},
		async getProcessHasTFADocument({ commit }, processID) {
			try {
				const response = await Axios.get(config.env.API_DEFAULT.host + `/process/import/processHasTFADocument/${processID}`);
				if (response.data.code === 'SUCCESS') {
					let hasTFA = response.data.data;
					commit("SET_HAS_TFA_DOCUMENT", hasTFA);
					return {
						success: true,
					};
				} else {
					return {
						success: false,
						error: "Não foi possível verificar se este processo tem TFA! " + response.data.message,
					};
				}
			} catch (error) {
				return {
					success: false,
					error,
				};
			}
		},
		async getDocuments({ commit }, processID) {
			try {
				const response = await Axios.get(config.env.API_DEFAULT.host + `/importProcesses/${processID}/documents`);
				if (response.data._embedded.documents) {
					let documents = [...response.data._embedded.documents];
					commit("SET_DOCUMENTS", documents);
					return {
						success: true,
					};
				} else {
					return {
						success: false,
						error: "Não foi possível consultar os tipos de documentos para os processos.",
					};
				}
			} catch (error) {
				return {
					success: false,
					error,
				};
			}
		},
		async getImportFields({ commit }) {
			try {
				const response = await Axios.get(
					config.env.API_DEFAULT.host + `/process/import/fields`
				);
				if (response.data) {
					let fields = [...response.data.data].sort((a, b) =>
						a.name && b.name ? a.name.localeCompare(b.name) : -1
					);
					commit("SET_IMPORT_FIELDS", fields);
					return {
						success: true,
					};
				} else {
					return {
						success: false,
						error: "Não foi possível consultar os documentos do processo.",
					};
				}
			} catch (error) {
				return {
					success: false,
					error,
				};
			}
		},
		setOnClick({ commit }) {

		},
		async diffObjects({ dispatch, commit }, data) {
			if (!data) return false;

			const oldVal = data.initialModel;
			const newVal = data.model;

			let diff = Object.keys(newVal).reduce((diff, key) => {

				if (key === 'typeOfProcess') {
					return null;
				}

				if (oldVal[key] === newVal[key]) {
					return diff;
				} else {
					if (
						newVal[key] !== null &&
						newVal[key] !== undefined &&
						newVal[key]._links
					) {
						const urlValue = newVal[key]._links.self.href;
						return {
							...diff,
							[key]: urlValue,
						};
					} else {
						return {
							...diff,
							[key]: newVal[key],
						};
					}
				}
			}, {});

			if (diff) {
				if (Object.keys(diff).length !== 0) {
					await dispatch("patchImportProcess", {
						diff,
						newModel: newVal
					});

					if (oldVal.status !== newVal.status) {
						try {
							commit(
								"setLoading", {
								show: true,
								label: "Alteração de Status",
								description: 'Salvando informações, aguarde...',
							}, {
								root: true,
							}
							);

							const response = await Axios.post(`${config.env.API_DEFAULT.host}/history/process/${newVal.id}/save`, {
								description: `Alterou o status de <strong class="text-danger">${oldVal.status}</strong> para <strong class="text-success">${newVal.status}</strong>`,
								isVisibleToClient: false,
								processType: 'IMPORT',
								stepDescription: oldVal.step.description,
								type: 'STATUS_CHANGE',
								priority: 'NORMAL'
							});

							commit("setLoading", { show: false }, { root: true });

						} catch (e) {
							commit("setLoading", { show: false }, { root: true });

							Swal.fire(
								"Alteração de Status",
								"Houve um erro inesperado ao salvar o log de alteração de status!",
								"error"
							);
						}
					}

					// Verificação para enviar os dados para a integração com o forwarder
					if (
						(newVal && newVal.freightForwarder && newVal.freightForwarder.id) ||
						(oldVal && oldVal.step && oldVal.step.id !== newVal.step.id)
					) {
						try {
							commit(
								"setLoading", {
								show: true,
								label: "Integração com Forwarder",
								description: 'Estamos enviando alguns dados do processo para o serviçø de integração com forwarder, aguarde...',
							}, {
								root: true,
							}
							);

							const integrationForwarderResponse = await Axios.post(`${config.env.API_DEFAULT.host}/forwarders-integrations/save-process-by-gescomex/${newVal.id}`);

							commit("setLoading", { show: false }, { root: true });
						} catch (e) {
							commit("setLoading", { show: false }, { root: true });

							Swal.fire(
								"Integração com Forwarder",
								"Houve um erro inesperado ao realizar a integração com os Forwarders, por favor comunique a equipe de TI.",
								"error"
							);
						}
					}

					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		},
		async patchImportProcess({ dispatch, state, commit }, data) {

			if (!validatePatchImportProcess(data)) {
				return false;
			}

			if (data.newModel.step !== undefined && data.newModel.step === null) {
				delete data.newModel.step;
				Swal.fire(
					"Etapa do Processo IMP Nº" + data.newModel.identification,
					"Houve algum problema durante a execução e não será possível alterar a etapa. Aguarde este processamento finalizar e tente novamente.",
					"error"
				);
			}

			commit(
				"setLoading", {
				show: true,
				label: "Salvando processo",
				description: "Aguarde sua requisição está sendo processada...",
			}, {
				root: true,
			}
			);

			try {

				const response = await Axios.patch(config.env.API_DEFAULT.host + "/importProcesses/" + state.activeProcess.id, data.diff);

				if (response) {

					const fieldNameNew = Object.keys(data.diff)[0];

					if (fieldNameNew !== undefined && fieldNameNew !== null && fieldNameNew !== "" && fieldNameNew == 'customer' && response?.data?._links?.customer?.href) {
						await Axios.get(response.data._links.customer.href).then((response) => { data.newModel.customer = response.data; });
					}

					const updateChangesData = {
						...data.newModel,
						statusLastModifiedBy: response.data.statusLastModifiedBy,
						statusDateOfLastModify: response.data.statusDateOfLastModify
					}

					commit("SET_ACTIVE", updateChangesData);
					commit("SET_SINGLE_PROCESS", updateChangesData);
					commit("setLoading", { show: false, }, { root: true, });

					state.socket.send(
						JSON.stringify({
							action: config.env.WEBSOCKET.actions.UPDATE_PROC,
							data: {
								[state.activeProcess.id]: {
									[Object.keys(data.diff)[0]]: data.newModel[Object.keys(data.diff)[0]]
								},
							},
						})
					);

					if (fieldNameNew !== undefined && fieldNameNew !== null && fieldNameNew !== "") {


						// if (fieldNameNew == 'invoiceDate' || fieldNameNew == 'datesDepartureDate' || fieldNameNew == 'datesETD' || fieldNameNew == 'datesEstimatedGoodsReadinesDate') {

						//     try {
						//         const response = await Axios.post(`${config.env.API_DEFAULT.host}/invoices/calculate-installment-due-date/${state.activeProcess.id}`, {});

						//         if (response.status === 200) {
						//             // EventBus.$emit("Operational.imp.Form.Invoices.load");
						//             Vue.toasted.success(response.data.message, { position: "top-right", duration: 6000 });
						//         }
						//     } catch (error) {
						//         if (error.response !== undefined) {
						//             Swal.fire('Atualização de Parcela de Invoice', error.response.data.message, 'error');
						//         } else {
						//             console.error('Erro -> Atualização de Parcela de Invoice', error);
						//         }
						//     }
						// }

						if ((fieldNameNew == 'invoiceTotal' || fieldNameNew == 'cifValueCurrency') && state.activeProcess.cifValueCurrency !== '') {
							commit(
								"setLoading", {
								show: true,
								label: "Calculando numerário",
								description: "Processando cálculo, por favor aguarde...",
							}, {
								root: true,
							}
							);
							try {
								const estimatedStepCashRequestEstimatedValueResponse = await Axios.post(config.env.API_DEFAULT.host + "/process/import/calculate-step-cash-request/" + state.activeProcess.id);
								commit("SET_ACTIVE", estimatedStepCashRequestEstimatedValueResponse.data.data);
								commit("SET_SINGLE_PROCESS", estimatedStepCashRequestEstimatedValueResponse.data.data);

								Vue.toasted.success(estimatedStepCashRequestEstimatedValueResponse.data.message, { position: "top-right", duration: 6000 });
							} catch (e) {
								Swal.fire(
									"Numerário Previsto",
									e.response.data.message,
									"error"
								);
							} finally {
								commit("setLoading", { show: false, }, { root: true, });
							}
						}

						if (state.activeProcess && state.activeProcess.id !== null) {

							try {
								const pullTriggerResponse = await Axios.post(config.env.API_DEFAULT.host + '/newImportProcess/' + state.activeProcess.id + '/pull-trigger/' + fieldNameNew);
								if (pullTriggerResponse) {

									if (pullTriggerResponse.data.data != null) {
										commit("SET_ACTIVE", pullTriggerResponse.data.data);
										commit("SET_SINGLE_PROCESS", pullTriggerResponse.data.data);

										if (fieldNameNew == 'status') {
											let modifiedStatus = {
												'statusLastModifiedBy': pullTriggerResponse.data.data.statusLastModifiedBy,
												'statusDateOfLastModify': pullTriggerResponse.data.data.statusDateOfLastModify
											};
										
											for (let modifiedStatusKey in modifiedStatus) {
												state.socket.send(
													JSON.stringify({
														action: config.env.WEBSOCKET.actions.UPDATE_PROC,
														data: {
															[state.activeProcess.id]: {
																[modifiedStatusKey]: modifiedStatus[modifiedStatusKey]
															},
														},
													})
												);
											}
										}
									}

									Vue.toasted.success(pullTriggerResponse.data.message, { position: "top-right", duration: 6000 });
								}
							} catch (error) {
								if (error.response !== undefined) {
									Vue.toasted.error(e.response.data.message, { position: "top-right", duration: 6000 });
								} else {
									console.error('Erro -> Atualização de Parcela de Invoice', error);
								}
							}

							const response2 = await Axios.get(config.env.API_DEFAULT.host + "/process/import/checkEvent/" + state.activeProcess.id + "/" + fieldNameNew + "/check/true");
							if (response2) {
								let msg = "";
								let message = "";
								let messageItemText = "";

								if (
									response2.data.listOfMessages !== undefined &&
									response2.data.listOfMessages !== null &&
									response2.data.listOfMessages.length > 0
								) {
									message +=
										"<ul class='list-group list-group-striped tx-14 tx-left'>";

									response2.data.listOfMessages.forEach((item) => {
										message +=
											"<li class='list-group-item tx-left'>" +
											"   <p class='mg-b-0 tx-left'>" +
											"       <i class='fa fa-check tx-success mg-r-8'></i> " +
											"       <span class='tx-inverse tx-normal'>" +
											item +
											"       </span>";
										"   </p>" + "</li>";

										messageItemText += item + "\n";
									});

									msg =
										"<p class='mg-b-5 tx-18'>Os eventos abaixo foram encontrados, você deseja dispará-los?</p>" +
										message;
									msg = "<div class='tx-left'>" + msg + "</div>";

									message += "</ul>";
								} else {
									msg = response2.data.message;
								}

								if (response2.data.code === "SUCCESS") {
									Swal.fire(
										"Eventos do Processo " + data.newModel.identification,
										msg,
										"success"
									);

									Swal.fire({
										title: "Eventos do Processo " + data.newModel.identification,
										html: msg,
										showCancelButton: true,
										type: "warning",
										confirmButtonColor: "#3085d6",
										cancelButtonColor: "#d33",
										cancelButtonText: "Não enviar",
										confirmButtonText: "Enviar",
									}).then((result) => {
										if (result.value) {
											commit(
												"setLoading", {
												show: true,
												label: "Enviando os eventos abaixo",
												description: messageItemText,
											}, {
												root: true,
											}
											);

											Axios.get(
												config.env.API_DEFAULT.host +
												"/process/import/checkEvent/" +
												state.activeProcess.id +
												"/" +
												fieldNameNew +
												"/check/false"
											)
												.then((response) => {
													commit(
														"setLoading", {
														show: false,
													}, {
														root: true,
													}
													);
													if (
														response.data.data[0] !== undefined &&
														response.data.data[0].code === "SUCCESS"
													) {
														if (
															response.data.data[0].actions !== undefined &&
															response.data.data[0].actions !== null &&
															response.data.data[0].actions.CHANGE_STEP !==
															undefined &&
															response.data.data[0].actions.CHANGE_STEP !== null
														) {
															var step =
																response.data.data[0].actions.CHANGE_STEP;
															var process = data.newModel;
															process.step = step;

															const newData = {
																diff: {
																	step: config.env.API_DEFAULT.host +
																		"/processSteps/" +
																		step.id,
																},
																newModel: {
																	...process,
																	step: {
																		...step,
																	},
																},
															};

															dispatch("patchImportProcess", newData);

															Swal.fire({
																type: "success",
																title: "Eventos do Processo " +
																	data.newModel.identification,
																html: "O Follow UP foi enviado com sucesso e a etapa do processo foi alterada para <strong>" +
																	step.description +
																	"</strong>.",
																showConfirmButton: true,
																timer: 5000,
															});
														} else {
															Swal.fire({
																type: "success",
																title: "Eventos do Processo " +
																	data.newModel.identification,
																html: response.data.data[0].message,
																showConfirmButton: true,
																timer: 5000,
															});
														}

														EventBus.$emit("Operational.imp.Form.Docs.load");
														EventBus.$emit(
															"Operational.imp.Form.Sidebar.Timeline.load"
														);
													} else {
														let message = `
                              <p>Processamento do evento:</p>
                              <p>${response.data.data[0].message}</p>
                            `;

														if (response.data.data[0].code === 'ERROR' && response.data.data[0].data) {
															message += `<ul class="list-group">`;
															for (let i = 0; i < response.data.data[0].data.length; i++) {
																message += `<li class="list-group-item">${labels[0][response.data.data[0].data[i]].label}</li>`
															}
															message += `<ul>`;
														}
														Swal.fire(
															"Eventos do Processo",
															message,
															"warning"
														);
													}
												})
												.catch((error) => {
													console.error(error);
													commit(
														"setLoading", {
														show: false,
													}, {
														root: true,
													}
													);
													Swal.fire(
														"Eventos do Processo",
														"Houve algum problema ao tentar processar o evento: \n <br />" +
														error.response.data.message,
														"error"
													);
												});
										}
									});
								} else if (response2.data.code === "ERROR") {
									Swal.fire(
										"Eventos do Processo " + data.newModel.identification,
										msg,
										"error"
									);
								} 
							}
						}


					}

					return true;
				} else {
					return false;
				}
			} catch (err) {
				commit(
					"setLoading", {
					show: false,
				}, {
					root: true,
				}
				);
				return false;
			}
		},
		async postImportProcess({ state, dispatch, commit, rootState }, data) {
			try {
				let model = {
					...data.model,
					account: config.env.API_DEFAULT.host + "/accounts/1"
				}

				if (model.customer !== undefined && model.customer !== null) {
					model.stepInsuranceValueResponsability = model.customer.insuranceWithPrime ? 'PRIME' : 'CLIENTE';
				}

				const response = await Axios.post(config.env.API_DEFAULT.host + "/newImportProcess/create", model);

				if (response) {

					if (state.activeProcess) {
						state.socket.send(
							JSON.stringify({
								action: config.env.WEBSOCKET.actions.CLOSE_PROC,
								data: {
									[state.activeProcess.id]: rootState.credentials.data.person.name,
								},
							})
						);
					}

					commit("SET_ACTIVE", response.data.data);
					commit("SET_SINGLE_PROCESS", response.data.data);

					state.socket.send(
						JSON.stringify({
							action: config.env.WEBSOCKET.actions.OPEN_PROC,
							data: {
								[response.data.data.id]: rootState.credentials.data.person.name,
							},
						})
					);

					return true;
				} else {
					return false;
				}
			} catch (err) {
				Swal.fire(
					"Não foi possível salvar o processo!",
					err.response.data.message,
					"error"
				);

				return false;
			}
		},
		async sendFollowUP({ dispatch, commit }, data) {
			try {
				const response = await Axios.get(
					config.env.API_DEFAULT.host +
					"/process/import/sendFollowUP/" +
					data.id
				);

				if (response.data.code === "SUCCESS") {
					Swal.fire(
						"Follow UP - " + data.identification,
						response.data.data.message,
						"success"
					);

					return true;
				} else if (response.data.code === "ERROR") {
					Swal.fire(
						"Follow UP - " + data.identification,
						response.data.message,
						"error"
					);

					return false;
				} else {
					Swal.fire(
						"Follow UP - " + data.identification,
						response.data.message,
						"error"
					);

					return false;
				}
			} catch (err) {
				if (err.response !== null && err.response !== undefined) {
					Swal.fire(
						"Problema no Follow UP",
						err.response.data.message,
						"error"
					);
				} else {
					console.error(err);
				}

				return false;
			}
		},
		async notifyCustomBroker({ dispatch, commit }, data) {
			try {
				const response = await Axios.get(config.env.API_DEFAULT.host + '/process/import/notifyCustomBroker/' + data.id);

				return response;
			} catch (error) {
				Swal.fire('Notificar Despachante', error.response.data.message, 'error');

				return false;
			}
		},
		async getFreightQuotationsResponses({ commit }, id) {
			try {

				if (!id) {
					return false;
				}

				const response = await Axios.get(
					config.env.API_DEFAULT.host + "/freightQuotation/importProcess/" + id
				);

				if (response.data.code === 'SUCCESS') {
					commit("SET_FREIGHT_QUOTATIONS_RESPONSES", response.data.data);
					return true;
				} else {
					Swal.fire("Problema nas Cotações Recebidas", response.data.message, "error");
				}

			} catch (error) {
				return false;
			}
		},
		async deleteProcessImportDeclaration({ dispatch, commit }, data) {
			try {

				if (data.showLoader) {
					commit("setLoading", {
						show: true,
						label: 'Declaração de Importação',
						description: 'Removendo a DI deste Processos de Importação...'
					}, { root: true, });
				}

				if (data.process.importDeclaration !== null) {
					let adicoes = data.process.importDeclaration.additions;

					if (adicoes.length > 0) {
						for (let i = 0; i < adicoes.length; i++) {
							try {
								await Axios.delete(config.env.API_DEFAULT.host + '/processImportDeclarationAdditions/' + adicoes[i].id);
							} catch (error) {
								commit("setLoading", { show: false, }, { root: true, });
								// Swal.fire("Declaração de Importação", "Não foi possível remover adição " + adicoes[i].numeroAdicao + " da DI no Processo Importação.", "error");
							}
						}
					}

					try {
						const deleteResponse = Axios.delete(config.env.API_DEFAULT.host + "/processImportDeclarations/" + data.process.importDeclaration.id);
						if (deleteResponse) {
							if (data.showAlert) {
								Swal.fire("Declaração de Importação", "Eita... Deu tudo certo! Você conseguiu remover a DI deste processo. Agora agarra nas mãos de Deus e torce pra tu não ter feito uma cagada : ) ", "success");
							}
						}

						if (data.showLoader) {
							commit("setLoading", { show: false, }, { root: true, });
						}

						return true;
					} catch (error) {
						if (data.showAlert) {
							commit("setLoading", { show: false, }, { root: true, });
							Swal.fire("Declaração de Importação", "Não foi possível remover o Processo Importação.", "error");
						}
					}

				} else {
					if (data.showAlert)
						Swal.fire("Declaração de Importação", "Este Processo de Importação ainda não tem uma DI cadastrada.", "success");
				}

			} catch (error) {
				commit("setLoading", { show: false, }, { root: true, });
			}

			if (data.showLoader) {
				commit("setLoading", { show: false, }, { root: true, });
			}
			return false;

		},
	},
};

export default ProcessStore;