<template>
  <div>
    <div class="table-s-s-r-header">
      <div class="pagination-left modify" v-if="tableConfig.showPerPageTop">
        <div>
          <label class="mb-0" for="perPage">Registros por página:</label>
          <select name="perPage" id="perPage" v-model="tableConfig.perPage" @change="perPage">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
      <div class="table-s-s-r-search" v-if="tableConfig.useSearch">
        <div>
          <div class="search-icon">
            <search-icon />
          </div>
          <!-- @keyup.enter="searchList" -->
          <input type="search" name="search" id="search" v-model="search" @input="inputSearch" placeholder="Pesquisar" /> 
           <div class="search-clear" v-if="search" @click="clearSearch">
            <clear-icon :size="24" color="#104375"/>
          </div>
        </div>
      </div>
      <div class="pagination-right modify" v-if="tableConfig.paginationTop">
        <div v-if="tableConfig.showRecords">Registros: {{ totalRecords }}</div>
        <div>
          <a href="#" @click.prevent="prevPage">
            <arrow-left-icon />
          </a>
        </div>
        <div>
          <input type="text" v-mask="'####'" name="page" id="page" :value="currentPage" disabled />
        </div>
        <div>
          <a href="#" @click.prevent="nextPage">
            <arrow-right-icon />
          </a>
        </div>
      </div>

      <slot name="buttons"></slot>
    </div>

    <slot name="hr"></slot>

    <table class="table-s-s-r" :class="{ centered: tableConfig.centeredCell }">
      <thead>
        <tr>
          <th v-for="item in columns" :key="item.label" :class="item.class">
            {{ item.label }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="item in filteredRecords" :key="item.label">
          <td v-for="col in columns" :key="col.field">
            <slot
              :name="col.field"
              :item="item"
              v-if="$scopedSlots[col.field]"
            ></slot>
            <template v-else>
              {{ traverse(item, col.field) }}
            </template>
          </td>
        </tr>
        <tr v-if="filteredRecords.length === 0">
          <td class="empty-text" :colspan="columns.length">{{ emptyMessage }}</td>
        </tr>
      </tbody>
    </table>

    <div
      class="table-s-s-r-pagination"
      :class="{ centered: !tableConfig.showPerPageBottom && !tableConfig.showRecords }"
    >
      <div class="pagination-left" v-if="tableConfig.showPerPageBottom || tableConfig.showRecords" >
        <div v-if="tableConfig.showPerPageBottom">
          <label class="mb-0" for="perPage">Registros por página:</label>
          <select name="perPage" id="perPage" v-model="tableConfig.perPage" @change="perPage">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>

        <div v-if="tableConfig.showRecords">{{ totalRecords }} registros</div>
      </div>

      <div class="pagination-right">
        <div>
          <a href="#" @click.prevent="prevPage">
            <arrow-left-icon />
          </a>
        </div>
        <div>
          <input type="text" v-mask="'####'" name="page" id="page" :value="currentPage" disabled />
        </div>
        <div>
          <a href="#" @click.prevent="nextPage">
            <arrow-right-icon />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import ArrowLeftIcon from "@/components/icons/ArrowLeftIcon.vue";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue";
import ClearIcon from '@/components/icons/ClearIcon.vue';
import { set } from 'vue';

export default {
  name: "TableSSR",
  components: {
    ArrowRightIcon,
    ArrowLeftIcon,
    SearchIcon,
    ClearIcon
  },
  directives: { mask },
  props: {
    tableConfig: {
      type: Object,
      default: () => ({
        useSearch: true,
        typeSearch: "list",
        centeredCell: false,
        showRecords: true,
        paginationTop: true,
        showPerPageTop: true,
        showPerPageBottom: true,
        perPage: 10,
      }),
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    lastPage: {
      type: Number,
      default: 1,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    emptyMessage: {
      type: String,
      default: "Nenhum registro encontrado",
    },
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    searchList() {
      if(this.tableConfig.typeSearch !== "list"){
        this.$emit("searchChange", { page: this.currentPage, search: this.search, perPage: this.tableConfig.perPage  });
      }
    },
    traverse(obj, keys) {
      return keys.split(".").reduce((cur, key) => cur[key], obj);
    },
    nextPage() {
      if (this.currentPage + 1 <= this.lastPage) {
        this.$emit("pageChange", { page: this.currentPage + 1, search: this.search, perPage: this.tableConfig.perPage  });
      }
    },
    prevPage() {
      if (this.currentPage - 1 > 0) {
        this.$emit("pageChange", { page: this.currentPage - 1, search: this.search, perPage: this.tableConfig.perPage  });
      }
    },
    perPage() {
      this.$emit("perPageChange", { page: this.currentPage, search: this.search, perPage: this.tableConfig.perPage });
    },
    searchInObjects(objects, searchString) {
		const results = [];

		const regExp = new RegExp(searchString, "gi");

		objects.forEach((obj) => {
			Object.entries(obj).forEach(([key, value]) => {
				if (regExp.test(String(value))) {
					results.push(obj);
				}
			});
		});

		return results;
    },

    inputSearch(){
      if (this.tableConfig.typeSearch !== "list") {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$emit("searchChange", { page: this.currentPage, search: this.search, perPage: this.tableConfig.perPage  });
        }, 800);
      }
    },
    clearSearch(){
      if (this.tableConfig.typeSearch !== "list") {
        this.search = '';
        this.$emit("searchChange", { page: this.currentPage, search: this.search, perPage: this.tableConfig.perPage });
      }
    }
  },
  computed: {
    filteredRecords() {
      if (this.search !== "" && this.tableConfig.typeSearch === "list") {
        const records = this.searchInObjects(this.tableData, this.search);
        return records;
      }

      return this.tableData;
    },
  },
};
</script>

<style>
.table-s-s-r {
  width: 100%;
}

.table-s-s-r p,
.table-s-s-r-pagination p {
  margin-bottom: 0;
}

.table-s-s-r-pagination,
.table-s-s-r-header {
  color: black;
  font-weight: bold;
}

.table-s-s-r thead tr th:first-of-type,
.table-s-s-r tbody tr td:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table-s-s-r thead tr th:last-of-type,
.table-s-s-r tbody tr td:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table-s-s-r thead tr th {
  background-color: var(--shortcut-round-bg);
  color: black;
}

.table-s-s-r tbody tr td {
  padding: 0.5rem 0.75rem;
}

.table-s-s-r thead tr th {
  padding: 1rem 0.75rem;
}

.table-s-s-r.centered thead tr th,
.table-s-s-r.centered tbody tr td {
  text-align: center;
}

.table-s-s-r tbody tr:nth-of-type(even) td {
  background-color: #f5f6f7;
}

.table-s-s-r-pagination {
  margin-top: 1rem;
  background-color: var(--shortcut-round-bg);
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border-radius: 10px;
}

.table-s-s-r-pagination.centered {
  justify-content: center;
}

.pagination-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.pagination-right a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 35px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--primary-bg-header);
  color: white;
}

.pagination-right input {
  width: 50px;
  height: 35px;
  background: white;
  border: 0;
  border-radius: 5px;
  text-align: center;
}

.pagination-right.modify {
 padding-right: 0.75rem;
}

.pagination-left.modify {
 padding-left: 0.75rem;
}
.pagination-left.modify select {
  width: 63px; 
}
.table-s-s-r-header .pagination-right input {
  border: 1px solid #e2e2e2;
}

.pagination-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.table-s-s-r-header select,
.pagination-left select {
  width: 60px;
  height: 35px;
  color: black;
  border: 0;
  padding: 0 0.5rem;
  border-radius: 5px;
  margin-left: 10px;
}

.table-s-s-r-header select {
  border: 1px solid #e2e2e2;
}

.table-s-s-r-search {
  max-width: 300px;
  position: relative;
  flex: 1 1 auto;
}

.table-s-s-r-search input {
  display: block;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  padding: 0 1rem 0 40px;
  border: 1px solid #e2e2e2;
}

.table-s-s-r-search .search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-bg-header);

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 40px;
}

.table-s-s-r-search .search-clear {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-bg-header);
  cursor: pointer;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
}

.table-s-s-r-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.table-s-s-r-tag {
  display: inline-block;
  padding: 0.1rem 0.5rem;
  border-radius: 5px;
  font-weight: 400;
  color: white;
  font-size: 0.8rem;
}

.table-s-s-r-tag.primary {
  background-color: var(--primary-bg-header);
}

.table-s-s-r-tag.success {
  background-color: #00a068;
}

.table-s-s-r-tag.danger {
  background-color: #dc3545;
}
.empty-text {
  padding-top: 1.5rem !important;
  text-align: center;
}
</style>
