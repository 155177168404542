var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-dialog mg-0"},[_c('div',{staticClass:"modal-content bd-0"},[_c('div',{staticClass:"modal-body pd-10"},[_c('div',{staticClass:"light-bg p-2 bd-b"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('button',{staticClass:"report-button small float-left",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();_vm.filterOpen = !_vm.filterOpen}}},[_c('span',{staticClass:"mr-2"},[_vm._v(" Filtros ")]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512","width":"16px","height":"16px"}},[_c('path',{attrs:{"fill":"currentColor","d":"M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"}})])])]),_c('div',{staticClass:"col-8 pd-5-force pd-r-20-force tx-right tx-light"},[(_vm.contactsList.length > 0 && _vm.contactsList.length > 1)?_c('span',{staticClass:"tx-uppercase tx-13"},[_vm._v(" Exibindo "+_vm._s(_vm.contactsList.length)+" contatos ")]):(_vm.contactsList.length > 0 && _vm.contactsList.length == 1)?_c('span',{staticClass:"tx-uppercase tx-13"},[_vm._v(" Apenas "+_vm._s(_vm.contactsList.length)+" contato foi encontrado ")]):_vm._e()])]),(_vm.filterOpen)?_c('div',[_c('div',{staticClass:"mt-3 mb-4 bd-t pd-t-30"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('select-wrapper',{attrs:{"name":"enabled","label":"Situação do Contato","clearable":true,"value":_vm.crmContactsFilters.archived,"options":[
                      {
                        label: 'Selecione uma opção',
                        value: null,
                      },
                      {
                        label: 'Arquivado',
                        value: true,
                      },
                      {
                        label: 'Habilitado',
                        value: 'false',
                      },
                    ]},on:{"input":function($event){return _vm.inputChange($event, 'archived')}}})],1)]),_c('div',{staticClass:"row mg-t-20"},[_c('div',{staticClass:"col-12"},[_c('select-wrapper',{attrs:{"name":"enabled","label":"Situação do Login / Acesso","clearable":true,"value":_vm.crmContactsFilters.enabled,"options":[
                      {
                        label: 'Habilitado',
                        value: true,
                      },
                      {
                        label: 'Desabilitado',
                        value: 'false',
                      },
                    ]},on:{"input":function($event){return _vm.inputChange($event, 'enabled')}}})],1)]),_c('div',{staticClass:"row mg-t-20"},[_c('div',{staticClass:"col-12"},[_c('select-wrapper',{attrs:{"name":"categories","label":"Categoria","clearable":true,"value":_vm.crmContactsFilters.categories,"multiple":"","options":[
                      { label: 'Principal', value: 'MAIN_CONTACT' },
                      { label: 'Próximo', value: 'CONTACT_NEXT' },
                      { label: 'Esporádico', value: 'SPORADIC_CONTACT' },
                      { label: 'Seguro', value: 'INSURANCE_CONTACT' },
                      { label: 'Contabilidade', value: 'ACCOUNTING_CONTACT' },
                      { label: 'Inativo', value: 'INACTIVE_CONTACT' },
                    ]},on:{"input":function($event){return _vm.inputChange($event, 'categories')}}})],1)]),_c('div',{staticClass:"row mg-t-20"},[_c('div',{staticClass:"col-12"},[_c('select-wrapper',{attrs:{"name":"roles","label":"Perfil","clearable":true,"value":_vm.crmContactsFilters.roles,"multiple":"","options":[
                      { label: 'Cliente (Ext)', value: 'CUSTOMER' },
                      { label: 'Parceiro (Ext)', value: 'PARTNER' },
                    ]},on:{"input":function($event){return _vm.inputChange($event, 'roles')}}})],1)]),_c('div',{staticClass:"row mg-t-20"},[_c('div',{staticClass:"col-12"},[_c('v-switch',{attrs:{"name":"followUP","labels":{
                      checked: 'Follow UP',
                      unchecked: 'Follow UP',
                    },"value":_vm.crmContactsFilters.followUP},on:{"input":function($event){return _vm.inputChange($event, 'followUP')}}})],1),_c('div',{staticClass:"col-12"},[_c('v-switch',{attrs:{"name":"hasCompanies","labels":{
                      checked: 'Possui vínculo',
                      unchecked: 'Possui vínculo',
                    },"value":_vm.crmContactsFilters.hasCompanies},on:{"input":function($event){return _vm.inputChange($event, 'hasCompanies')}}})],1),_c('div',{staticClass:"col-12"},[_c('v-switch',{attrs:{"name":"hasActivities","labels":{
                      checked: 'Possui atividades',
                      unchecked: 'Possui atividades',
                    },"value":_vm.crmContactsFilters.hasActivities},on:{"input":function($event){return _vm.inputChange($event, 'hasActivities')}}})],1)])]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-3"}),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn-oblong btn-small bg-teal wd-100p tx-white",on:{"click":function($event){$event.preventDefault();return _vm.generate.apply(null, arguments)}}},[_vm._v(" CARREGAR ")])]),_c('div',{staticClass:"col-3"})])]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }