<template>
  <transition name="freight-quote-modal">
    <div id="freight-quote-modal">
      <div class="modal-mask ht-100v">
        <div class="modal-wrapper ht-100v">
          <div class="modal-container">
            <div class="modal-header">
              <h5 class="tx-xs-20 tx-inverse mg-b-5">
                PRÉ VISUALIZAÇÃO DE E-MAIL
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Fechar"
                @click="$emit('close')"
              >
                <span aria-hidden="true"> X </span>
              </button>
            </div>

            <div class="modal-body">
              <div class="content scroll" v-html="html"></div>

              <div class="form-layout-footer bd pd-y-15 pd-x-15">
                <div class="row">
                  <div class="col-lg-3" />
                  <div class="col-lg-3">
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click.prevent="$emit('close')"
                      type="button"
                    >
                      <i class="fa fa-reply mg-r-5"></i> VOLTAR
                    </button>
                  </div>

                  <div class="col-lg-3">
                    <button
                      class="btn btn-sm btn-danger btn-block"
                      @click.prevent="$emit('sendEmail')"
                      type="button"
                    >
                      <i class="fa fa-paper-plane mg-r-5"></i> ENVIAR COTAÇÃO
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Axios from "axios";

export default {
  name: "freight-quote-modal",
  props: {
    html: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
@import "../../../../../../../assets/css/modal.css";
@import "./styles.css";
</style>
