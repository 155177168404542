<template>
  <header class="main-header" :class="classModify">
    <div class="flex fgap2 ai-c">
      <img
        src="/icon-prime.svg"
        alt="GSCMX"
        style="max-width: 48px; max-height: 48px"
      />
      <router-link :to="{ name: 'index' }" class="round-button">
        <app-icon :size="24" />
      </router-link>
    </div>

    <nav class="flex fgap1 ai-c">
      <div class="relative nav-item-container flex ai-c">
        <router-link
          :to="{ name: 'crm-home' }"
          class="nav-item flex ai-c fgap1"
        >
          CRM
          <chevron-down :size="10" />
        </router-link>

        <div class="submenu flex fgap2">
          <ul class="submenu-shortcuts flex fd-c fgap1">
            <li>
              <router-link
                :to="{ name: 'CrmAddCustomer' }"
                class="submenu-shortcut flex ai-c fgap1"
              >
                <div class="icon">
                  <add-customer :size="18" />
                </div>
                <div class="description">
                  <span class="text-bold">Adicionar Empresa</span>
                  <p>Adionar uma nova Empresa</p>
                </div>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'CrmAddContact' }"
                class="submenu-shortcut flex ai-c fgap1"
              >
                <div class="icon">
                  <add-contact :size="18" />
                </div>
                <div class="description">
                  <span class="text-bold">Adicionar Contato</span>
                  <p>Adicionar um novo Contato</p>
                </div>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'crm-clientes' }"
                class="submenu-shortcut flex ai-c fgap1"
              >
                <div class="icon">
                  <list-customer :size="18" />
                </div>
                <div class="description">
                  <span class="text-bold">Listar Empresas</span>
                  <p>Listar todas as Empresas</p>
                </div>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'crm-home' }"
                class="submenu-shortcut flex ai-c fgap1"
              >
                <div class="icon">
                  <list-contact :size="18" />
                </div>
                <div class="description">
                  <span class="text-bold">Listar Contatos</span>
                  <p>Listar todos os Contatos</p>
                </div>
              </router-link>
            </li>
          </ul>

          <div class="submenu-divider"></div>

          <div class="flex fgap1 flex1">
            <div class="flex1">
              <h5 style="padding-left: 5px">CADASTROS OPERACIONAIS</h5>

              <ul class="other-links">
                <li>
                  <router-link
                    :to="{
                      name: 'companies-list',
                      params: { type: 'isProvider' },
                    }"
                  >
                    <span>Fornecedores da Empresa</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'companies-list',
                      params: { type: 'isExporter' },
                    }"
                  >
                    <span>Exportadores / Fabricantes</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'companies-list',
                      params: { type: 'isCustomBroker' },
                    }"
                  >
                    <span>Despachantes Aduaneiros</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'companies-list',
                      params: { type: 'isConveyor' },
                    }"
                  >
                    <span>Transportadoras</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'companies-list',
                      params: { type: 'isShipowner' },
                    }"
                  >
                    <span>Armadores</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'companies-list',
                      params: { type: 'isForwarder' },
                    }"
                  >
                    <span>Freight Forwarders</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'companies-list',
                      params: { type: 'isGeneralWarehouse' },
                    }"
                  >
                    <span>Armazéns Gerais</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'cadastros-operacionais',
                      params: { company: 'exportadores' },
                    }"
                  >
                    <span
                      >Exportadores / Fabricantes
                      <span
                        class="btn-danger"
                        style="border-radius: 5px; padding: 0 5px"
                        >New</span
                      ></span
                    >
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'cadastros-operacionais',
                      params: { company: 'despachantes-aduaneiros' },
                    }"
                  >
                    <span
                      >Despachantes Aduaneiros
                      <span
                        class="btn-danger"
                        style="border-radius: 5px; padding: 0 5px"
                        >New</span
                      ></span
                    >
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'cadastros-operacionais',
                      params: { company: 'transportadoras' },
                    }"
                  >
                    <span
                      >Transportadoras
                      <span
                        class="btn-danger"
                        style="border-radius: 5px; padding: 0 5px"
                        >New</span
                      ></span
                    >
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'cadastros-operacionais',
                      params: { company: 'armadores' },
                    }"
                  >
                    <span
                      >Armadores
                      <span
                        class="btn-danger"
                        style="border-radius: 5px; padding: 0 5px"
                        >New</span
                      ></span
                    >
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'cadastros-operacionais',
                      params: { company: 'freight-forwarders' },
                    }"
                  >
                    <span
                      >Freight Forwarders
                      <span
                        class="btn-danger"
                        style="border-radius: 5px; padding: 0 5px"
                        >New</span
                      ></span
                    >
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'cadastros-operacionais',
                      params: { company: 'comissionados' },
                    }"
                  >
                    <span
                      >Comissionados
                      <span
                        class="btn-danger"
                        style="border-radius: 5px; padding: 0 5px"
                        >New</span
                      ></span
                    >
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'cadastros-operacionais',
                      params: { company: 'seguradoras' },
                    }"
                  >
                    <span
                      >Seguradoras
                      <span
                        class="btn-danger"
                        style="border-radius: 5px; padding: 0 5px"
                        >New</span
                      ></span
                    >
                  </router-link>
                </li>
              </ul>
            </div>

            <div class="flex1">
              <h5 style="padding-left: 5px">RELATÓRIOS</h5>

              <ul class="other-links">
                <li>
                  <router-link :to="{ name: 'crm-relatorios' }">
                    <span>Relatórios Gerais do CRM</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'companies-reports-customers' }">
                    <span>Relatório Simplificado de Clientes</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Retrospective' }">
                    <span>Retrospectiva Anual de Clientes</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="relative nav-item-container flex ai-c">
        <router-link
          :to="{ name: 'operational-imp-desk' }"
          class="nav-item flex ai-c fgap1"
        >
          Operacional
          <chevron-down :size="10" />
        </router-link>

        <div class="submenu flex fgap2">
          <ul class="submenu-shortcuts flex fd-c fgap1">
            <li>
              <router-link
                :to="{ name: 'operational-imp-desk' }"
                class="submenu-shortcut flex ai-c fgap1"
              >
                <div class="icon">
                  <table-icon :size="18" />
                </div>
                <div class="description">
                  <span class="text-bold">Telão de Importação</span>
                  <p>Gerenciar processos de importação</p>
                </div>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'operational-partner-integrations' }"
                class="submenu-shortcut flex ai-c fgap1"
              >
                <div class="icon">
                  <refresh-icon :size="18" />
                </div>
                <div class="description">
                  <span class="text-bold">Integrações de Parceiros</span>
                  <p>Painel de integração dos parceiros</p>
                </div>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'operational-reports-inconsistencies' }"
                class="submenu-shortcut flex ai-c fgap1"
              >
                <div class="icon">
                  <document-icon :size="18" />
                </div>
                <div class="description">
                  <span class="text-bold">Relatório de Inconsistências</span>
                  <p>Analisar inconsistências</p>
                </div>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'operational-reports-commissioned' }"
                class="submenu-shortcut flex ai-c fgap1"
              >
                <div class="icon">
                  <document-icon :size="18" />
                </div>
                <div class="description">
                  <span class="text-bold">Relatório de Comissionados</span>
                  <p>Analisar comissionados</p>
                </div>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'operational-financial-calendar' }"
                class="submenu-shortcut flex ai-c fgap1"
              >
                <div class="icon">
                  <calendar-icon :size="18" />
                </div>
                <div class="description">
                  <span class="text-bold">Calendário Financeiro</span>
                  <p>Calendário financeiro dos clientes</p>
                </div>
              </router-link>
            </li>
          </ul>

          <div class="submenu-divider"></div>

          <div class="flex fgap1 flex1">
            <div class="flex1">
              <!-- <h5 style="padding-left: 5px;">PROCEDIMENTOS RFB</h5>

              <ul class="other-links mb-4">
                <li>
                  <router-link :to="{ name: 'operational-imp-declaration-list' }">
                    <span>Declarações de Importação (DIs)</span>
                  </router-link>
                </li>
              </ul> -->

              <h5 style="padding-left: 5px">RELATÓRIOS</h5>

              <ul class="other-links mb-4">
                <li>
                  <router-link
                    :to="{ name: 'operational-reports-imp-general' }"
                  >
                    <span>Relatório Geral de Importações</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{ name: 'operational-reports-imp-closure' }"
                  >
                    <span>Relatório de Encerramentos</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'operational-reports-imp-ipe' }">
                    <span>Indicador de Prazo de Encerramento</span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="{ name: 'operational-reports-imp-channels' }"
                  >
                    <span>Percentual de Canais</span>
                  </router-link>
                </li>

                <li v-if="hasPrivilege('SUPERADMINISTRADOR')">
                  <router-link
                    :to="{ name: 'operational-reports-imp-insurance' }"
                  >
                    Relatório de Seguro
                  </router-link>
                </li>
              </ul>

              <h5 style="padding-left: 5px">DOCUMENTOS ORIGINAIS (Beta)</h5>

              <ul class="other-links mb-4">
                <li>
                  <router-link :to="{ name: 'RecentOriginalDocuments' }">
                    <span>Envio de Documentos Originais</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'IndexInvoices' }">
                    <span>Invoices</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'IndexPos' }">
                    <span>Ordens de Compra</span>
                  </router-link>
                </li>
              </ul>
            </div>

            <div class="flex1">
              <h5>CONFIGURAÇÕES</h5>

              <ul class="other-links">
                <li v-if="hasPrivilege('CONFIGURACOES_EVENTOS_PROCESSOS_CRUD')">
                  <router-link :to="{ name: 'operational-events-list' }">
                    <span>Eventos dos Processos</span>
                  </router-link>
                </li>
                <li v-if="hasPrivilege('CONFIGURACOES_ETAPAS_PROCESSOS_CRUD')">
                  <router-link :to="{ name: 'operational-steps-list' }">
                    <span>Etapas dos Processos</span>
                  </router-link>
                </li>
                <!-- <li v-if="hasPrivilege('CONFIGURACOES_TIPOS_DOCUMENTOS_CRUD')">
                  <router-link
                    :to="{
                      name: 'movimentTerms-list',
                      params: { type: 'categories' },
                    }"
                  >
                    <span>Categorias de Despesas</span>
                  </router-link>
                </li>
                <li v-if="hasPrivilege('CONFIGURACOES_TIPOS_DOCUMENTOS_CRUD')">
                  <router-link
                    :to="{
                      name: 'movimentTerms-list',
                      params: { type: 'documents' },
                    }"
                  >
                    <span>Tipos de Documentos</span>
                  </router-link>
				</li> -->
				<li v-if="hasPrivilege('CONFIGURACOES_TIPOS_DOCUMENTOS_CRUD')">
                  <router-link
                    :to="{
                      name: 'movimentTerms-new-list',
                      params: { type: 'categories' },
                    }"
                  >
                    <span>Categorias de Despesas</span>
                  </router-link>
                </li>
                <li v-if="hasPrivilege('CONFIGURACOES_TIPOS_DOCUMENTOS_CRUD')">
                  <router-link
                    :to="{
                      name: 'movimentTerms-new-list',
                      params: { type: 'documents' },
                    }"
                  >
                    <span>Tipos de Documentos</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="hasPrivilege('UTILITARIOS')"
        class="relative nav-item-container flex ai-c"
      >
        <a href="#" @click.self.prevent class="nav-item flex ai-c fgap1">
          Utilitários
          <chevron-down :size="10" />
        </a>

        <div class="submenu lt">
          <ul class="submenu-shortcuts flex fd-c fgap1">
            <li
              v-if="
                hasPrivilege('UTILITARIOS_BANCO_USUARIOS_CRUD') ||
                hasPrivilege('UTILITARIOS_BANCO_USUARIOS_VISUALIZAR')
              "
            >
              <router-link
                :to="{ name: 'utilities-userbank-list' }"
                class="submenu-shortcut flex ai-c fgap1"
              >
                <div class="icon">
                  <database-icon :size="18" />
                </div>
                <div class="description">
                  <span class="text-bold">Banco de Usuários</span>
                  <p>Visualizar usuários utilitários</p>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="header-search flex1 flex ai-c jc-c gap1">
      <transition name="dropcustom">
        <div class="relative flex ai-c" v-if="!searchOpen">
          <div class="search-icon">
            <search-icon :size="18" />
          </div>

          <input
            v-model="search.value"
            type="search"
            name="search"
            id="search"
            placeholder="Digite o que você deseja pesquisar..."
            @keyup.enter="runSearch(false)"
          />

          <div class="search-button">
            <button type="button" @click.prevent="openSearchFilters">
              <settings-icon :size="16" />
            </button>
          </div>
        </div>
      </transition>
    </div>

    <div class="user-menu flex ai-c fgap2">
      <!--      <a href="#" @click.prevent class="round-button relative">-->
      <!--        <message :size="24"/>-->

      <!--        <div class="notifications-count yellow">-->
      <!--          5-->
      <!--        </div>-->
      <!--      </a>-->

      <!--      <a href="#" @click.prevent class="round-button relative">-->
      <!--        <bell :size="24"/>-->

      <!--        <div class="notifications-count green">-->
      <!--          99-->
      <!--        </div>-->
      <!--      </a>-->

      <div class="header-search-identification flex ai-c">
        <div class="relative flex ai-c">
          <input
            v-model="processIdentification"
            type="number"
            name="processIdentification"
            id="processIdentification"
            placeholder="Digite o Nº"
            class="imput-process-by-identification"
            @keyup.enter="findProcessByIdentificationSearch()"
          />
          <div class="search-button">REF</div>
        </div>
      </div>

      <div class="relative parent-drop">
        <router-link :to="{ name: 'configuration' }" class="round-button">
          <config-icon :size="24" />
        </router-link>

        <!--        <transition name="dropcustom">-->
        <!--          <div v-if="configOpen" class="dropdown-custom">-->
        <!--            <ul>-->
        <!--              <li>-->
        <!--                <h5 style="padding-left: 5px;">CONFIGURAÇÕES DE ACESSO</h5>-->
        <!--              </li>-->
        <!--              <li v-if="hasPrivilege('CONFIGURACOES_USUARIOS_CRUD')">-->
        <!--                <router-link :to="{ name: 'users-list' }">-->
        <!--                  <span>Usuários de Acesso</span>-->
        <!--                </router-link>-->
        <!--              </li>-->
        <!--              <li v-if="hasPrivilege('CONFIGURACOES_GRUPOS_CRUD')" class="mb-4">-->
        <!--                <router-link :to="{ name: 'access-groups' }">-->
        <!--                  <span>Grupos de Acesso</span>-->
        <!--                </router-link>-->
        <!--              </li>-->

        <!--              <li>-->
        <!--                <h5 style="padding-left: 5px;">CONFIGURAÇÕES OPERACIONAIS</h5>-->
        <!--              </li>-->
        <!--              <li v-if="hasPrivilege('CONFIGURACOES_TIMES_CRUD')" class="mb-4">-->
        <!--                <router-link :to="{ name: 'teams-list' }">-->
        <!--                  <span>Time Operacionais</span>-->
        <!--                </router-link>-->
        <!--              </li>-->

        <!--              <li>-->
        <!--                <h5 style="padding-left: 5px;">CONFIGURAÇÕES ADMINISTRATIVAS</h5>-->
        <!--              </li>-->
        <!--              <li v-if="hasPrivilege('CONFIGURACOES_UNIDADES_EMPRESA_CRUD')">-->
        <!--                <router-link :to="{ name: 'accounts-list' }">-->
        <!--                  <span>Unidades da Empresa</span>-->
        <!--                </router-link>-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--          </div>-->
        <!--        </transition>-->
      </div>

      <div class="relative nav-item-container">
        <router-link :to="{ name: 'MyAccount' }">
          <div class="user-dropdown flex ai-c fgap1">
            <div class="profile-circle" :class="classModify">
              <div
                class="profile-image"
                :class="classModify"
                v-if="this.$store.state.credentials.data.person.photo"
                :style="{
                  background: `url(${this.$store.state.credentials.data.person.photo}) center center / cover no-repeat`,
                }"
              ></div>
              <div class="profile-image" :class="classModify" v-else>
                <add-contact :size="24" />
              </div>
            </div>

            <div class="user-info">
              <p class="user-name">
                {{ this.$store.state.credentials.data.person.name }}
              </p>
              <p class="user-email">
                {{ this.$store.state.credentials.data.person.email }}
              </p>
            </div>
          </div>
        </router-link>
        <div class="submenu submenu-user-dropdown" :class="classModify">
          <ul class="submenu-shortcuts flex fd-c fgap1">
            <li>
              <router-link
                :to="{ name: 'MyAccount' }"
                class="submenu-shortcut flex ai-c fgap1"
              >
                <div class="icon">
                  <add-contact :size="18" />
                </div>
                <div class="description">
                  <span class="text-bold">Meus Dados</span>
                  <p>Gerenciar meus dados</p>
                </div>
              </router-link>
            </li>
            <!-- <li>
              <router-link :to="{ name: 'configuration' }" class="submenu-shortcut flex ai-c fgap1" >
                <div class="icon">
                  <config-icon :size="18"/>
                </div>
                <div class="description">
                  <span class="text-bold">Configurações</span>
                  <p>Visualizar configurações de acesso</p>
                </div>
              </router-link>
            </li> -->
            <li>
              <a
                href="#"
                class="submenu-shortcut flex ai-c fgap1"
                @click.prevent="logout()"
              >
                <div class="icon">
                  <logout-icon :size="18" />
                </div>
                <div class="description">
                  <span class="text-bold">Sair</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <transition name="dropcustom">
      <div class="search-filters" v-if="searchOpen">
        <div class="flex ai-c fgap2 jc-sb mb-2">
          <h3>Filtros de Pesquisa</h3>

          <div class="header-search flex1 flex ai-c jc-c">
            <div class="relative flex ai-c">
              <div class="search-icon">
                <search-icon :size="18" />
              </div>

              <input
                v-model="search.value"
                type="search"
                name="search"
                id="search-filter"
                placeholder="Digite o que você deseja pesquisar..."
                @keyup.enter="runSearch(true)"
              />

              <transition name="dropcustom">
                <div class="search-button" v-if="search.value.length > 0">
                  <button
                    @click.prevent="search.value = ''"
                    type="button"
                    class="clear-search"
                  >
                    <x-icon :size="24" />
                  </button>
                </div>
              </transition>
            </div>
          </div>

          <button
            @click="searchOpen = false"
            class="cursor-pointer flex ai-c jc-c close-search"
          >
            <x-icon :size="24" />
          </button>
        </div>

        <hr />

        <div class="filters-grid">
          <div class="span-7 pr-3 bordered-right">
            <p>Filtrar / <b>Período por Tipo de Data</b></p>

            <div class="filters-grid gap1 mb-4">
              <div class="span-4">
                <select-wrapper
                  clearable
                  v-model="search.dateField"
                  :options="dateTypes"
                  label="Tipo de Data"
                />
                <small class="pl-3">Tipo de data a ser filtrada</small>
              </div>

              <div class="span-4">
                <v-input
                  v-model="search.startDate"
                  label="Data Inicial"
                  name="start_date"
                  type="date"
                />
                <small class="pl-3"
                  >Período incial de acordo com o tipo de data</small
                >
              </div>

              <div class="span-4">
                <v-input
                  v-model="search.endDate"
                  label="Data Final"
                  name="end_date"
                  type="date"
                />
                <small class="pl-3"
                  >Período final de acordo com o tipo de data</small
                >
              </div>
            </div>

            <p>Filtrar / <b>Outras Características</b></p>

            <div class="filters-grid gap1">
              <div class="span-4">
                <select-wrapper
                  clearable
                  multiple
                  v-model="search.teams"
                  :options="listOfTeams"
                  label="Equipe / Time Operacional"
                />
              </div>

              <div class="span-4">
                <select-wrapper
                  clearable
                  v-model="search.wayOfTransport"
                  :options="listOfWayOfTransports"
                  label="Via de Transporte"
                />
              </div>

              <div class="span-4">
                <select-wrapper
                  clearable
                  v-model="search.incoterm"
                  :options="listOfIncoterms"
                  label="Incoterm"
                />
              </div>
            </div>
          </div>

          <div class="span-5 pl-3">
            <div class="flex ai-c jc-sb fgap2 mb-3">
              <p>Filtrar / <b>Outras Características</b></p>

              <v-switch
                v-model="allSelected"
                label-position="left"
                :labels="{
                  checked: 'Selecionar Todos',
                  unchecked: 'Selecionar Todos',
                }"
                name="select-all"
              />
            </div>

            <div class="filters-grid gap1 ai-gs">
              <div class="span-6 flex fd-c fgap1">
                <div class="flex ai-c jc-sb fgap2">
                  <div>Processos <b>Concluídos</b></div>
                  <v-switch
                    v-model="search.concluded"
                    label-position="left"
                    :labels="{ checked: '', unchecked: '' }"
                    name="concluded"
                  />
                </div>

                <div class="flex ai-c jc-sb fgap2">
                  <div>
                    <div>Containeres</div>
                    <small>Busca mais lenta</small>
                  </div>
                  <v-switch
                    v-model="search.containers"
                    label-position="left"
                    :labels="{ checked: '', unchecked: '' }"
                    name="conta-ordem"
                  />
                </div>

                <div class="flex ai-c jc-sb fgap2">
                  <div>
                    <div>Histórico do Processo</div>
                    <small>Busca mais lenta</small>
                  </div>
                  <v-switch
                    v-model="search.histories"
                    label-position="left"
                    :labels="{ checked: '', unchecked: '' }"
                    name="histories"
                  />
                </div>
              </div>

              <div class="span-6 flex fd-c fgap1">
                <div class="span-6 flex ai-c jc-sb fgap2">
                  <div>Processos <b>Conta e Ordem</b></div>
                  <v-switch
                    v-model="search.contaOrdem"
                    label-position="left"
                    :labels="{ checked: '', unchecked: '' }"
                    name="pri"
                  />
                </div>

                <div class="span-6 flex ai-c jc-sb fgap2">
                  <div>Processos <b>Assessoria</b></div>
                  <v-switch
                    v-model="search.acessoria"
                    label-position="left"
                    :labels="{ checked: '', unchecked: '' }"
                    name="Assessoria"
                  />
                </div>

                <div class="span-6 flex ai-c jc-sb fgap2">
                  <div>Processos <b>Encomenda</b></div>
                  <v-switch
                    v-model="search.encomenda"
                    label-position="left"
                    :labels="{ checked: '', unchecked: '' }"
                    name="Encomenda"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="flex ai-c jc-c fgap2">
          <button class="crm-button red" @click.prevent="closeSearchFilters">
            <div class="flex ai-c fgap1">
              <x-icon :size="16" />
              <span>Limpar</span>
            </div>
          </button>
          <button class="crm-button" @click.prevent="runSearch(true)">
            <div class="flex ai-c fgap1">
              <search-icon :size="16" />
              <span>Pesquisar</span>
            </div>
          </button>
        </div>
      </div>
    </transition>
  </header>
</template>

<script>
import AppIcon from "@/components/MainHeader/icons/AppIcon.vue";
import ChevronDown from "@/components/MainHeader/icons/ChevronDown.vue";
import AddCustomer from "@/components/MainHeader/icons/AddCustomer.vue";
import AddContact from "@/components/MainHeader/icons/AddContact.vue";
import ListCustomer from "@/components/MainHeader/icons/ListCustomer.vue";
import ListContact from "@/components/MainHeader/icons/ListContact.vue";
import TableIcon from "@/components/MainHeader/icons/TableIcon.vue";
import RefreshIcon from "@/components/MainHeader/icons/RefreshIcon.vue";
import DocumentIcon from "@/components/MainHeader/icons/DocumentIcon.vue";
import DatabaseIcon from "@/components/MainHeader/icons/DatabaseIcon.vue";
import SearchIcon from "@/components/MainHeader/icons/Search.vue";
import SettingsIcon from "@/components/MainHeader/icons/Settings.vue";
import ConfigIcon from "@/components/MainHeader/icons/ConfigIcon.vue";
import LogoutIcon from "@/components/MainHeader/icons/LogoutIcon.vue";
import XIcon from "@/components/MainHeader/icons/XIcon.vue";
import Axios from "axios";
import config from "@/config";
import CredentialService from "@/services/CredentialService.js";
import VInput from "@/components/VInput.vue";
import SelectWrapper from "@/components/SelectWrapper.vue";
import VSwitch from "@/components/VSwitch.vue";
import { dateLabels } from "@/labels/labels.processes";
import * as constants from "@/consts.js";
import Swal from "sweetalert2";
import CalendarIcon from "@/components/MainHeader/icons/CalendarIcon.vue";
import {mapActions, mapState} from "vuex";

export default {
  name: "MainHeader",
  components: {
    CalendarIcon,
    VSwitch,
    SelectWrapper,
    VInput,
    XIcon,
    LogoutIcon,
    ConfigIcon,
    SettingsIcon,
    SearchIcon,
    DatabaseIcon,
    DocumentIcon,
    RefreshIcon,
    TableIcon,
    ListContact,
    ListCustomer,
    AddContact,
    AddCustomer,
    ChevronDown,
    AppIcon,
  },
  props: {
    classModify: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      configOpen: false,
      searchOpen: false,
      allSelected: false,
      mainTitle: "Telão Operacional",
      modelTitle: "Telão Operacional",
      teams: [],
      processIdentification: "",
      search: {
        value: "",
        dateField: null,
        startDate: null,
        endDate: null,
        teams: [],
        wayOfTransport: null,
        incoterm: null,
        concluded: false,
        containers: false,
        histories: false,
        contaOrdem: false,
        acessoria: false,
        encomenda: false,
      },
    };
  },
  methods: {
	...mapActions("ProcessStore", [
      "connectWs",
    ]),
    async runSearch(useParams = false) {
      if (this.search.value.length < 3) {
        await Swal.fire(
          "Pesquisa geral",
          "O termo de busca deve conter pelo menos 3 caracteres",
          "error"
        );

        return;
      }

      this.$store.commit("setLoading", {
        show: true,
        label: "Pesquisa Geral",
        description: "Executando pesquisa...",
      });

      try {
        let typesOfProc = "";

        const reqParams = {
          search: this.search.value,
          dateField: this.search.dateField,
          startDate: this.search.startDate
            ? `${this.search.startDate}T00:00:00`
            : null,
          endDate: this.search.endDate
            ? `${this.search.endDate}T23:59:59`
            : null,
          teams:
            this.search.teams.length > 0 ? this.search.teams.join(",") : null,
          wayOfTransport: this.search.wayOfTransport,
          incoterm: this.search.incoterm,
          concluded: this.search.concluded,
          containers: this.search.containers,
          histories: this.search.histories,
          typesOfProcess: "",
        };

        if (this.search.contaOrdem) {
          typesOfProc += "'CONTA_E_ORDEM'";
        }

        if (this.search.acessoria) {
          if (typesOfProc.length > 0) {
            typesOfProc += ", 'ASSESSORIA'";
          } else {
            typesOfProc += "'ASSESSORIA'";
          }
        }

        if (this.search.encomenda) {
          if (typesOfProc.length > 0) {
            typesOfProc += ", 'ENCOMENDA'";
          } else {
            typesOfProc += "'ENCOMENDA'";
          }
        }

        reqParams.typesOfProcess = typesOfProc;

        let response;

        if (useParams) {
          response = await Axios.get(
            `${config.env.API_DEFAULT.host}/newImportProcess/general-search`,
            { params: reqParams }
          );
        } else {
          response = await Axios.get(
            `${config.env.API_DEFAULT.host}/newImportProcess/general-search`,
            {
              params: {
                search: this.search.value,
              },
            }
          );
        }

        if (response.data.data.length > 1) {
          this.$store.commit("ProcessStore/SET_PROCESSES", response.data.data);

          let params = "";

          for (const param of Object.keys(reqParams)) {
            if (
              reqParams[param] !== null &&
              reqParams[param] !== "" &&
              reqParams[param] !== false
            ) {
              params += `&${param}=${reqParams[param]}`;
            }
          }

          this.searchOpen = false;
          this.$store.commit("ProcessStore/SET_STATE_STEPS", []);

          Swal.fire(
            "Pequisa Geral",
            `Foram encontrados ${response.data.data.length} processos nesta pesquisa.`,
            "success"
          );
          await this.$router.push(
            `/operational/imp/desk?showProcess=false${params}`
          );
        } else if (response.data.data.length === 1) {
			this.searchOpen = false;

			if (this.$store.state.ProcessStore.activeProcess) {
				this.$store.state.ProcessStore.socket.send(
					JSON.stringify({
						action: config.env.WEBSOCKET.actions.CLOSE_PROC,
						data: {
							[this.$store.state.ProcessStore.activeProcess.id]:
							this.$store.state.credentials.data.person.name,
						},
					})
				);
			}

			await this.$store.commit("ProcessStore/HIDE_PROCESS");
			await this.$store.commit("ProcessStore/SET_STATE_STEPS", []);
			await this.$store.commit("ProcessStore/SET_ACTIVE", response.data.data[0]);

			if (
				this.$store.state.ProcessStore.openedProcesses[response.data.data[0].id] &&
				this.$store.state.ProcessStore.openedProcesses[response.data.data[0].id].user != this.$store.state.credentials.data.person.name
			) {
				this.$store.commit("ProcessStore/SET_NOCLICK", true);
			} else {
				
				if (!this.$store.state.ProcessStore?.socket?.send) {
					await this.connectWs();
				}

				this.$store.state.ProcessStore.socket.send(
					JSON.stringify({
						action: config.env.WEBSOCKET.actions.OPEN_PROC,
						data: {
							[response.data.data[0].id]: this.$store.state.credentials.data.person.name,
						},
					})
				);
			}

          if (this.$route.name !== "operational-imp-desk") {
            await this.$router.push("/operational/imp/desk?showProcess=true");
          }
        } else {
          Swal.fire(
            "Pesquisa Geral",
            `Nenhum resultado foi encontrado com o termo <strong>${this.search.value}</strong>.`,
            "error"
          );
        }
      } catch (e) {
		console.log(e);
        Swal.fire(
          "Pesquisa geral",
          "Houve um erro inesperado ao fazer a pesquisa!",
          "error"
        );
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    async openSearchFilters() {
      this.$store.commit("setLoading", {
        show: true,
        label: "Times Operacionais",
        description: "Buscando times...",
      });

      try {
        Axios.get(`${config.env.API_DEFAULT.host}/teams`).then((response) => {
          this.teams = response.data._embedded.teams;
        });

        this.searchOpen = true;

        this.$nextTick(() => {
          document.querySelector("#search-filter").focus();
        });
      } catch (e) {
        Swal.fire(
          "Consulta de Times Operacionais",
          "Houve um erro inesperado ao buscar os times!",
          "error"
        );
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
    closeSearchFilters() {
      this.allSelected = false;
      this.search = {
        value: "",
        dateField: null,
        startDate: null,
        endDate: null,
        teams: [],
        wayOfTransport: null,
        incoterm: null,
        concluded: false,
        containers: false,
        histories: false,
        contaOrdem: false,
        acessoria: false,
        encomenda: false,
      };
      this.searchOpen = false;
    },
    hasPrivilege(privilege) {
      return CredentialService.hasPrivilege(privilege);
    },
    logout() {
      const self = this;
      Axios.get(
        `${config.env.API_DEFAULT.host}/credential/logout?access_token=${self.$store.state.credentials.accessToken}`
      )
        .then((response) => {
          window.localStorage.clear();
          self.$store.commit("defaultLayoutDisabled");
          self.$store.commit("includeCredentials", {
            userAuthenticated: false,
            accessToken: null,
            scope: null,
            expiresIn: null,
            data: null,
          });
          self.$router.push("/login");
        })
        .catch((error) => {});
    },

    checkClickOutside(e) {
      if (!e.target.closest(".parent-drop")) {
        this.configOpen = false;
      }
    },
    checkEscapeKey(e) {
      if (e.keyCode === 27) {
        this.configOpen = false;
        this.searchOpen = false;
      }
    },
    async findProcessByIdentificationSearch() {
      try {
        this.$store.commit("setLoading", {
          show: true,
          label: "Pesquisando referência",
          description: "Executando pesquisa...",
        });

        if (this.processIdentification == "") {
          Swal.fire(this.mainTitle, "A referência não pode ser vazia", "error");
          return;
        }

        await this.$router.push(`/operational/imp/desk`);

        if (this.$store.state.ProcessStore.activeProcess) {
          this.$store.state.ProcessStore.socket.send(
            JSON.stringify({
              action: config.env.WEBSOCKET.actions.CLOSE_PROC,
              data: {
                [this.$store.state.ProcessStore.activeProcess.id]:
                  this.$store.state.credentials.data.person.name,
              },
            })
          );
        }

        this.$store.commit("ProcessStore/HIDE_PROCESS");

        const response = await this.$store.dispatch(
          "ProcessStore/findProcessByIdentification",
          this.processIdentification
        );

        if (!response.success) {
          Swal.fire(
            this.mainTitle,
            response.error.response.data.message,
            "error"
          );
          return;
        }

        if (!this.$store.state.ProcessStore.openedProcesses[response.data.id]) {
          this.$store.state.ProcessStore.socket.send(
            JSON.stringify({
              action: config.env.WEBSOCKET.actions.OPEN_PROC,
              data: {
                [response.data.id]:
                  this.$store.state.credentials.data.person.name,
              },
            })
          );
        }
      } catch (e) {
        Swal.fire(
          "Pesquisa pela referência",
          "Houve um erro inesperado ao fazer a pesquisa",
          "error"
        );

        console.log('e: ', e);
        
      } finally {
        this.$store.commit("setLoading", {
          show: false,
        });
      }
    },
  },
  computed: {
    dateTypes() {
      const labels = dateLabels();

      return labels.map((l) => ({
        label: l.label,
        value: l.attribute,
      }));
    },
    listOfTeams() {
      return this.teams.map((t) => ({
        label: t.name,
        value: t.id,
      }));
    },
    listOfWayOfTransports() {
      return constants.OPERATIONAL_WAY_OF_TRANSPORTE.map((w) => ({
        label: w.value,
        value: w.key,
      }));
    },
    listOfIncoterms() {
      return constants.OPERATIONAL_INCOTERMS.map((c) => ({
        label: c.value,
        value: c.key,
      }));
    },
  },
  watch: {
    allSelected(newValue) {
      if (newValue === true) {
        this.search.concluded = true;
        this.search.containers = true;
        this.search.histories = true;
        this.search.contaOrdem = true;
        this.search.acessoria = true;
        this.search.encomenda = true;
      } else {
        this.search.concluded = false;
        this.search.containers = false;
        this.search.histories = false;
        this.search.contaOrdem = false;
        this.search.acessoria = false;
        this.search.encomenda = false;
      }
    },
  },
  mounted() {
	if (!this.$store.state.ProcessStore?.socket?.send) {
		this.connectWs();
	}
    window.addEventListener("click", this.checkClickOutside);
    window.addEventListener("keyup", this.checkEscapeKey);

    this.search.value = this.$route.query.search || "";
    this.search.dateField = this.$route.query.dateField || "";

    if (this.$route.query.startDate) {
      this.search.startDate = this.$route.query.startDate.split("T")[0];
    }

    if (this.$route.query.endDate) {
      this.search.endDate = this.$route.query.endDate.split("T")[0];
    }

    this.search.incoterm = this.$route.query.incoterm || "";
    this.search.concluded = this.$route.query.concluded || false;
    this.search.containers = this.$route.query.containers || false;
    this.search.histories = this.$route.query.histories || false;

    if (this.$route.query.typesOfProcess) {
      const types = this.$route.query.typesOfProcess
        .split(",")
        .map((item) => item.trim().replace(/'/g, ""));
      types.forEach((type) => {
        if (type === "CONTA_E_ORDEM") {
          this.search.contaOrdem = true;
        } else if (type === "ASSESSORIA") {
          this.search.acessoria = true;
        } else if (type === "ENCOMENDA") {
          this.search.encomenda = true;
        }
      });
    }

    if (this.$route.query.teams) {
      this.search.teams = this.$route.query.teams
        .split(",")
        .map((item) => parseInt(item));
    }
  },
  beforeDestroy() {
    window.removeEventListener("click", this.checkClickOutside);
    window.removeEventListener("keyup", this.checkEscapeKey);
  },
};
</script>

<style scoped>
@import "./styles.css";
@import "../../assets/css/grid.css";

.imput-process-by-identification {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Para Chrome e Safari */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .imput-process-by-identification::-webkit-inner-spin-button,
  .imput-process-by-identification::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* Para Firefox */
@-moz-document url-prefix() {
  .imput-process-by-identification::-moz-inner-spin-button,
  .imput-process-by-identification::-moz-outer-spin-button {
    -moz-appearance: none;
    margin: 0;
  }
}
</style>
